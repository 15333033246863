import { Flex } from "@chakra-ui/react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { formatGMT } from "utils/date"

import InlineDateTimePicker from "components/_base/InlineDateTimePicker"
import { RootState } from "core/redux/reducer"

import balanceSlice from "../slice"

const snapshot = +process.env.REACT_APP_BALANCE_SNAPSHOT
export const getTimestampFromDateBalance = (d: Date): number => {
    const min = d.getMinutes()
    const formatMin = Math.floor(min / snapshot) * snapshot
    d.setMinutes(formatMin)
    d.setSeconds(0)
    d.setMilliseconds(0)
    return Math.floor(d.getTime() / 1000)
}

const dateFormatter = (d: Date): string => {
    return formatGMT(d, "yyyy-MM-dd HH:mm")
}

const DateSelect: React.FC = () => {
    const dispatch = useDispatch()
    const timestamp1 = useSelector((state: RootState) => state.balance.balanceTimestamp)
    const timestamp2 = useSelector((state: RootState) => state.balance.rateTimestamp)

    const first = new Date(timestamp1 * 1000)
    const second = new Date(timestamp2 * 1000)

    const handleChangeFirst = (d: Date) => {
        dispatch(balanceSlice.actions.setBalanceTimestamp(getTimestampFromDateBalance(d)))
    }

    const handleChangeSecond = (d: Date) => {
        dispatch(balanceSlice.actions.setRateTimestamp(getTimestampFromDateBalance(d)))
    }

    useEffect(() => {
        dispatch(balanceSlice.actions.setRateTimestamp(timestamp1))
    }, [dispatch, timestamp1])

    return (
        <Flex columnGap="8px">
            <InlineDateTimePicker
                showSeconds={false}
                dateFormatter={dateFormatter}
                title="Balance Date"
                date={first}
                onChangeDate={handleChangeFirst}
            />
            <InlineDateTimePicker
                showSeconds={false}
                dateFormatter={dateFormatter}
                title="Rate Date"
                date={second}
                onChangeDate={handleChangeSecond}
            />
        </Flex>
    )
}

export default DateSelect
