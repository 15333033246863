import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { WalletParams, WalletResponse } from "core/apis/wallet"
import { Wallet, WalletPostPayload } from "core/types/wallet"

interface IState {
    prevParams: WalletParams | undefined
    walletResponse: WalletResponse
    allPageSize: number
    isLoading: boolean
    isSubmitting: boolean
    searchText: string
    isDrawerOpen: boolean
    selectedWalletInfo?: Wallet
}

const initialState: IState = {
    prevParams: undefined,
    walletResponse: { wallets: [], pagination: { totalItems: 0 } },
    allPageSize: 0,
    isLoading: false,
    isSubmitting: false,
    searchText: "",
    isDrawerOpen: false,
}

const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        getWalletsRequest: (state, action: PayloadAction<WalletParams>) => {
            state.isLoading = true
            state.prevParams = action.payload
        },
        getWalletsSuccess: (state, action: PayloadAction<WalletResponse>) => {
            state.walletResponse = action.payload
            state.isLoading = false
            if (!state.allPageSize) state.allPageSize = action?.payload?.pagination?.totalItems
        },
        getWalletsFailure: (state) => {
            state.isLoading = false
        },

        postWalletRequest: (state, _action: PayloadAction<WalletPostPayload>) => {
            state.isSubmitting = true
        },
        postWalletSuccess: (state) => {
            state.isSubmitting = false
        },
        postWalletFailure: (state) => {
            state.isSubmitting = false
        },

        setSearchText: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload
        },
        setSelectedWalletInfo: (state, action: PayloadAction<Wallet | undefined>) => {
            state.selectedWalletInfo = action.payload
        },
    },
})

export default walletSlice
