import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { Asset } from "core/types/asset"
import { ExchangeAccount } from "core/types/cex"
import { Group } from "core/types/group"
import { VirtualRecord, VirtualRecordPostPayload } from "core/types/virtual"
import { Wallet } from "core/types/wallet"

interface IState {
    assets: Asset[]
    wallets: Wallet[]
    groups: Group[]
    exchangeAccounts: ExchangeAccount[]
    records: VirtualRecord[]
    isLoading: boolean

    viewVirtualID?: number

    filter: {
        walletIDs: number[]
        cexAccountIDs: string[]
        groupIDs: number[]
        fromTimestamp: number
        toTimestamp: number
    }
}

const getDefaultFromTimestamp = (): number => {
    const time = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime()
    return Math.floor(time / 1000)
}

const getDefaultToTimestamp = (): number => {
    const time = new Date().getTime()
    return Math.floor(time / 1000)
}

const initialState: IState = {
    assets: [],
    wallets: [],
    exchangeAccounts: [],
    groups: [],
    records: [],
    isLoading: false,

    filter: {
        walletIDs: [],
        cexAccountIDs: [],
        groupIDs: [],
        fromTimestamp: getDefaultFromTimestamp(),
        toTimestamp: getDefaultToTimestamp(),
    },
}

const virtualSlice = createSlice({
    name: "virtual",
    initialState,
    reducers: {
        getVirtualRecordsRequest: (state) => {
            state.isLoading = true
        },
        getVirtualRecordsSuccess: (state, action: PayloadAction<VirtualRecord[]>) => {
            state.isLoading = false
            state.records = action.payload
        },
        getVirtualRecordsFailure: (state) => {
            state.isLoading = false
        },

        viewVirtualRecord: (state, action: PayloadAction<number | undefined>) => {
            state.viewVirtualID = action.payload
        },

        postVirtualRecordRequest: (state, _action: PayloadAction<VirtualRecordPostPayload>) => {
            state.isLoading = true
        },
        postVirtualRecordSuccess: (state, action: PayloadAction<VirtualRecord>) => {
            state.isLoading = false
            state.records.push(action.payload)
        },
        postVirtualRecordFailure: (state) => {
            state.isLoading = false
        },

        deleteVirtualRecordRequest: (state, _action: PayloadAction<number>) => {
            state.isLoading = true
        },
        deleteVirtualRecordSuccess: (state) => {
            state.isLoading = false
        },
        deleteVirtualRecordFailure: (state) => {
            state.isLoading = false
        },

        setFromTimestamp: (state, action: PayloadAction<number>) => {
            state.filter.fromTimestamp = action.payload
        },
        setToTimestamp: (state, action: PayloadAction<number>) => {
            state.filter.toTimestamp = action.payload
        },
        setSource: (
            state,
            action: PayloadAction<{ walletIDs: number[]; cexAccountIDs: string[]; groupIDs: number[] }>
        ) => {
            state.filter.walletIDs = action.payload.walletIDs
            state.filter.cexAccountIDs = action.payload.cexAccountIDs
            state.filter.groupIDs = action.payload.groupIDs
        },

        _initializeVirtualSliceRequest: (state) => {
            state.isLoading = true
        },
        _initializeVirtualSliceSuccess: (
            state,
            action: PayloadAction<{
                wallets: Wallet[]
                exchangeAccounts: ExchangeAccount[]
                assets: Asset[]
                groups: Group[]
            }>
        ) => {
            state.isLoading = false
            state.wallets = action.payload.wallets
            state.exchangeAccounts = action.payload.exchangeAccounts
            state.assets = action.payload.assets
            state.groups = action.payload.groups
        },
        _initializeVirtualSliceFailure: (state) => {
            state.isLoading = false
        },
    },
})

export default virtualSlice
