import http from "core/http"
import { AccountType, BalanceResponseV2 } from "core/types/balance"

export type BalanceParams = {
    wallet_id: number[]
    cex_account: string[]
    group_id: number[]
    chain_id: string[]
    balance_timestamp: number
    rate_timestamp: number
    descending: boolean
    token_min_amount_usd?: number
    regular_balance?: boolean
    pool_balance?: boolean
    farm_balance_deposited?: boolean
    farm_balance_joined?: boolean
}

const getBalancesV2 = async (params: BalanceParams) => {
    return http.call({ url: `/v1/balances`, params })
}
const exportCsv = async (params: BalanceParams) => {
    return http.exportCsv({ url: `/v1/balances/export-csv`, params })
}

const getBalanceByAccount = async ({
    id,
    type,
    ...params
}: {
    id: number | string
    type: AccountType
} & BalanceParams): Promise<BalanceResponseV2> => {
    return http.call({ url: `/v1/balances/${type === AccountType.WALLET ? "wallet" : "cex_account"}/${id}`, params })
}

const balanceAPI = {
    getBalancesV2,
    getBalanceByAccount,
    exportCsv,
}

export default balanceAPI
