import { Badge } from "@chakra-ui/react"

import { NullCell } from "components/Table/components/NullCell"
import { WalletType } from "core/types/wallet"

const TypeCell: React.FC<{ value: string }> = ({ value }) => {
    switch (value) {
        case WalletType.EVM: {
            return <Badge colorScheme="purple">EVM</Badge>
        }

        case WalletType.BTC: {
            return <Badge colorScheme="yellow">BTC</Badge>
        }

        case null:
        case undefined:
        case "": {
            return <NullCell />
        }

        default: {
            return <Badge>{value}</Badge>
        }
    }
}

export default TypeCell
