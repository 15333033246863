import { InfoIcon } from "@chakra-ui/icons"
import { Box, Flex, Text } from "@chakra-ui/react"
import React from "react"

import withPageLayout from "components/withPageLayout"

import BalanceTable from "./BalanceTable"
import UtilityBar from "./UtilityBar"

const BalancePage: React.FC = () => {
    return (
        <Flex direction="column" height="100%" width="100%" padding="24px 160px">
            <Flex flex="0 0" width="100%" justifyContent="space-between" alignItems="center">
                <Flex flexDirection="column">
                    <Box as="h1" fontSize="3xl">
                        Balance
                    </Box>
                    <Box as="h3" fontSize="sm">
                        Balance management
                    </Box>
                </Flex>

                <Flex width="450px" bgColor="blackAlpha.100" padding={2} borderRadius="4px" columnGap="4px">
                    <Flex flex="0 0 24px" height="24px" justifyContent="center" alignItems="center">
                        <InfoIcon color="gray.500" />
                    </Flex>
                    <Flex flex="1 1" fontSize="sm" color="gray.700" direction="column">
                        <Text as="span" whiteSpace="nowrap">
                            <b>Virtual balances</b> are not available at the moment
                            <br />
                            <b>Regular balance</b> on Optimism is not supported
                        </Text>
                    </Flex>
                </Flex>
            </Flex>

            <Box flex="0 0" width="100%" mt="24px" mb="24px">
                <UtilityBar />
            </Box>

            <Box overflow="hidden" width="100%" flex="1 1 100%">
                <BalanceTable />
            </Box>
        </Flex>
    )
}

export default withPageLayout(BalancePage)
