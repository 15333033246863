import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { BalanceParams } from "core/apis/balance"
import { chains } from "core/globalConstants"
import { BalanceResponseV2 } from "core/types/balance"
import { getTimestampFromDateBalance } from "pages/Balance/UtilityBar/DateSelect"

import { BalanceFilter } from "./model"

interface IState {
    isLoading: boolean
    balanceTimestamp: number
    rateTimestamp: number
    filter: BalanceFilter
    chainIDs: string[]

    blockNumber?: number
    blockTimestamp?: number
    cexTimestamp?: number

    prevParams: BalanceParams | undefined
    balanceResponse: BalanceResponseV2

    settings: {
        includeRegularBalances: boolean
        includeVirtual: boolean
        includeLPBalances: boolean
        includeFarmBalancesDeposited: boolean
        includeFarmBalancesJoined: boolean
        sortAssetsByUSDValue: boolean
        hiddenAssetIDs: number[]
        visibleAssetIDs: number[]
        token_min_amount_usd: number
    }
}

const initialState: IState = {
    isLoading: false,
    balanceTimestamp: getTimestampFromDateBalance(new Date()),
    rateTimestamp: getTimestampFromDateBalance(new Date()),
    filter: {
        walletIDs: [],
        groupIDs: [],
        cexAccountIDs: [],
    },
    chainIDs: chains.map((chain) => chain.id),

    prevParams: undefined,
    balanceResponse: {
        rate_cex_timestamp: 0,
        balance_cex_timestamp: 0,
        total_amount_usd: 0,
        balances: [],
    },

    settings: {
        includeVirtual: false,
        includeRegularBalances: true,
        includeLPBalances: false,
        includeFarmBalancesDeposited: false,
        includeFarmBalancesJoined: false,
        sortAssetsByUSDValue: true, // increase
        hiddenAssetIDs: [],
        visibleAssetIDs: [],
        token_min_amount_usd: 0,
    },
}

const balanceSlice = createSlice({
    name: "balance",
    initialState,
    reducers: {
        // get balances
        setPrevParams: (state, action: PayloadAction<BalanceParams>) => {
            state.prevParams = action.payload
        },
        getBalancesAndRatesRequest: (state) => {
            state.isLoading = true
        },
        getBalancesAndRatesSuccess: (
            state,
            action: PayloadAction<{
                balanceResponse: BalanceResponseV2
            }>
        ) => {
            state.isLoading = false
            state.balanceResponse = action.payload.balanceResponse
        },
        getBalancesAndRatesFailure: (state) => {
            state.isLoading = false
        },

        setBalanceTimestamp: (state, action: PayloadAction<number>) => {
            state.balanceTimestamp = action.payload
        },
        setRateTimestamp: (state, action: PayloadAction<number>) => {
            state.rateTimestamp = action.payload
        },

        setFilter: (state, action: PayloadAction<Partial<BalanceFilter>>) => {
            state.filter = { ...state.filter, ...action.payload }
        },

        setSortAssets: (state, action: PayloadAction<boolean>) => {
            state.settings.sortAssetsByUSDValue = action.payload
        },
        setMinTokenAmountUsd: (state, action: PayloadAction<number>) => {
            state.settings.token_min_amount_usd = action.payload
        },
        setHiddenAssetIDs: (state, action: PayloadAction<number[]>) => {
            state.settings.hiddenAssetIDs = action.payload
        },
        setVisibleAssetIDs: (state, action: PayloadAction<number[]>) => {
            state.settings.visibleAssetIDs = action.payload
        },
        setIncludeRegularBalances: (state, action: PayloadAction<boolean>) => {
            state.settings.includeRegularBalances = action.payload
        },
        setIncludeLPBalances: (state, action: PayloadAction<boolean>) => {
            state.settings.includeLPBalances = action.payload
        },
        setIncludeFarmBalancesDeposited: (state, action: PayloadAction<boolean>) => {
            state.settings.includeFarmBalancesDeposited = action.payload
        },
        setIncludeFarmBalancesJoined: (state, action: PayloadAction<boolean>) => {
            state.settings.includeFarmBalancesJoined = action.payload
        },

        toggleSelectChainID: (state, action: PayloadAction<string>) => {
            const chainID = action.payload
            const index = state.chainIDs.findIndex((id) => id === chainID)
            if (index !== -1) {
                state.chainIDs.splice(index, 1)
            } else {
                state.chainIDs.push(chainID)
            }
        },
        selectChainIDs: (state, action: PayloadAction<string[]>) => {
            const chainIDs = action.payload
            state.chainIDs = chainIDs
        },

        toggleIncludeVirtual: (state) => {
            state.settings.includeVirtual = !state.settings.includeVirtual
        },
    },
})

export default balanceSlice
