import { Box, Flex, Image, TableContainer, Text, theme } from "@chakra-ui/react"
import styled from "styled-components"

import { AssetBalance } from "core/types/balance"
import { formatFloat, formatFloatUsd } from "pages/Balance/utils"
import { color } from "theme"

const borderColor = theme.colors.gray[200]
const ItemWrapper = styled.div`
    display: grid;
    grid-template-columns: 0.4fr 1fr 1fr 1fr;
    padding: 10px 10px;
`

const AssetItem = ({
    asset: { symbol, name, logo_url, amount, amount_usd, price_usd, no_price },
}: {
    asset: AssetBalance
}) => {
    return (
        <ItemWrapper style={{ border: `0.5px solid ${borderColor}` }}>
            <Flex gap="4px" align="center">
                {logo_url ? (
                    <Image boxSize="24px" src={logo_url} alt={name} />
                ) : (
                    <Box height="24px" flex="0 0 24px" borderRadius="100%" bgColor="blackAlpha.200" />
                )}
                <Text as="span" fontWeight={"500"} color={"teal"}>
                    {symbol}
                </Text>
            </Flex>
            <Text as="span" fontWeight={"400"} style={{ textAlign: "right" }}>
                {formatFloat(amount)}
            </Text>
            <Text as="span" fontWeight={"400"} style={{ textAlign: "right", color: no_price ? "orange" : undefined }}>
                {no_price ? "No price" : formatFloatUsd(price_usd)}
            </Text>
            <Text as="span" fontWeight={"400"} style={{ textAlign: "right" }}>
                {formatFloatUsd(amount_usd)}
            </Text>
        </ItemWrapper>
    )
}

export default function Balance({ total_amount_usd, assets }: { total_amount_usd: number; assets: AssetBalance[] }) {
    return (
        <TableContainer>
            <ItemWrapper
                style={{
                    border: `1px solid ${borderColor}`,
                    borderBottom: "none",
                    background: color.backgroundDark,
                    fontSize: "14px",
                }}
            >
                <Text style={{ textAlign: "left", fontWeight: "500" }}>Token </Text>
                <Text style={{ fontWeight: "500", textAlign: "right", paddingRight: "10px" }}>Amount</Text>
                <Text style={{ fontWeight: "500", textAlign: "right", paddingRight: "0px" }}>Usd Price</Text>
                <Text style={{ textAlign: "right", fontWeight: "500" }} paddingRight={0}>
                    Usd Amount
                </Text>
            </ItemWrapper>
            <div
                style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "70vh",
                    overflowY: "auto",
                    border: `1px solid ${borderColor}`,
                }}
            >
                {assets.map((asset) => {
                    return <AssetItem key={asset.symbol} asset={asset} />
                })}
            </div>
            <ItemWrapper style={{ border: `1px solid ${borderColor}`, borderTop: "none" }}>
                <b>Total USD Amount:</b>
                <div />
                <div />
                <b style={{ textAlign: "right" }}>{formatFloatUsd(total_amount_usd)}</b>
            </ItemWrapper>
        </TableContainer>
    )
}
