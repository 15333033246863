import { combineReducers } from "redux"
// defaults to localStorage for web
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import assetSlice from "pages/Asset/slice"
import assetMovementSlice from "pages/AssetMovement/slice"
import balanceSlice from "pages/Balance/slice"
import cexMappingSlice from "pages/CEXAssetMapping/slice"
import balanceComparisonSlice from "pages/Comparison/slice"
import debuggerSlice from "pages/Debugger/slice"
import groupSlice from "pages/Group/slice"
import tokenSlice from "pages/Token/slice"
import virtualSlice from "pages/Virtual/virtual.slice"
import walletSlice from "pages/Wallet/slice"

import commonSlice from "./common.slice"
import toastSlice from "./toast.slice"

const rootReducer = combineReducers({
    [tokenSlice.name]: tokenSlice.reducer,
    [toastSlice.name]: toastSlice.reducer,
    [debuggerSlice.name]: persistReducer(
        {
            key: debuggerSlice.name,
            storage,
            blacklist: ["resp", "accessToken"],
        },
        debuggerSlice.reducer
    ),
    [virtualSlice.name]: persistReducer(
        {
            key: virtualSlice.name,
            storage,
            blacklist: ["records"],
        },
        virtualSlice.reducer
    ),
    [walletSlice.name]: walletSlice.reducer,
    [commonSlice.name]: commonSlice.reducer,
    [balanceSlice.name]: balanceSlice.reducer,
    [balanceSlice.name]: persistReducer(
        {
            key: balanceSlice.name,
            storage,
            whitelist: ["balanceTimestamp", "rateTimestamp", "filter", "chainIDs", "doesIncludeVirtual", "settings"],
        },
        balanceSlice.reducer
    ),
    [groupSlice.name]: groupSlice.reducer,
    [assetSlice.name]: assetSlice.reducer,
    [cexMappingSlice.name]: cexMappingSlice.reducer,
    [assetMovementSlice.name]: persistReducer(
        {
            key: assetMovementSlice.name,
            storage,
            blacklist: ["tokenTransfers"],
        },
        assetMovementSlice.reducer
    ),
    [balanceComparisonSlice.name]: persistReducer(
        {
            key: balanceComparisonSlice.name,
            storage,
            blacklist: ["balanceResponses", "assetsWithNonZeroAmount"],
        },
        balanceComparisonSlice.reducer
    ),
})

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
