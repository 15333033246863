import { Flex, FormControl, FormErrorMessage, FormLabel, Input } from "@chakra-ui/react"
import { useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useDispatch } from "react-redux"
import Select from "react-select"

import useSearchData from "core/hooks/useSugguestionData"
import commonSlice from "core/redux/common.slice"
import { SelectOption } from "core/types/select"

export type CreateOrEditGroupFormValues = {
    id?: number
    name: string
    note: string
    group_ids?: SelectOption[]
    wallet_ids: SelectOption[]
    cex_accounts: SelectOption[]
}

const CreateOrEditGroupForm: React.FC = () => {
    const { exchangeAccounts: cexAccounts, wallets, groups } = useSearchData()

    const walletOptions: SelectOption[] = useMemo(() => {
        return wallets.map((wallet) => ({
            label: `${wallet.name} (${wallet.id})`,
            value: wallet.id,
        }))
    }, [wallets])

    const groupOptions: SelectOption[] = useMemo(() => {
        return groups.map((group) => ({
            label: group.name,
            value: group.id,
        }))
    }, [groups])

    const cexAccountOptions: SelectOption[] = useMemo(() => {
        return cexAccounts.map((account) => ({
            label: account.id,
            value: account.id,
        }))
    }, [cexAccounts])

    const { control, watch } = useFormContext<CreateOrEditGroupFormValues>()
    const groupID = watch("id")

    const dispatch = useDispatch()
    const handleSearchSelect = (name: string, value: string) => {
        dispatch(commonSlice.actions.setSearchQuery({ [name]: value }))
    }

    return (
        <Flex direction="column" width="100%" height="100%" rowGap="24px">
            <Controller
                control={control}
                name="id"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => {
                    // only render this if ID exists, which means in Editing mode, not Creating
                    if (value) {
                        return (
                            <FormControl isInvalid={invalid}>
                                <FormLabel fontSize="sm">Group ID</FormLabel>
                                <Input
                                    ref={ref}
                                    onBlur={onBlur}
                                    name={name}
                                    placeholder="Group ID"
                                    onChange={onChange}
                                    autoComplete="off"
                                    value={value || ""}
                                    readOnly
                                    disabled
                                />
                            </FormControl>
                        )
                    }
                    return null
                }}
            />

            <Controller
                control={control}
                name="name"
                rules={{
                    required: "Group name is required",
                }}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
                    <FormControl isInvalid={invalid} isRequired>
                        <FormLabel fontSize="sm">Name</FormLabel>
                        <Input
                            ref={ref}
                            onBlur={onBlur}
                            name={name}
                            placeholder="Group name"
                            onChange={onChange}
                            autoComplete="off"
                            value={value || ""}
                        />
                        {invalid ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="note"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error: _error } }) => (
                    <FormControl isInvalid={invalid}>
                        <FormLabel fontSize="sm">Note</FormLabel>
                        <Input
                            ref={ref}
                            onBlur={onBlur}
                            name={name}
                            placeholder="Group Note"
                            onChange={onChange}
                            autoComplete="off"
                            value={value || ""}
                        />
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="group_ids"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => {
                    // only render this if ID DOES NOT exist, which means in Creating mode
                    if (groupID || !value) {
                        return null
                    }

                    return (
                        <FormControl isInvalid={invalid}>
                            <FormLabel fontSize="sm">Other groups to be included</FormLabel>
                            <Select
                                value={value}
                                onChange={onChange}
                                isClearable
                                isSearchable
                                isMulti
                                name={name}
                                options={groupOptions}
                                onBlur={onBlur}
                                ref={ref}
                                onInputChange={(value) => handleSearchSelect("groups", value)}
                                placeholder="Groups"
                            />
                        </FormControl>
                    )
                }}
            />

            <Controller
                control={control}
                name="wallet_ids"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => {
                    return (
                        <FormControl isInvalid={invalid}>
                            <FormLabel fontSize="sm">Wallets</FormLabel>
                            <Select
                                value={value}
                                onChange={onChange}
                                isClearable
                                isSearchable
                                isMulti
                                name={name}
                                options={walletOptions}
                                onBlur={onBlur}
                                ref={ref}
                                onInputChange={(value) => handleSearchSelect("wallets", value)}
                                placeholder="Wallets"
                            />
                        </FormControl>
                    )
                }}
            />

            <Controller
                control={control}
                name="cex_accounts"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => {
                    return (
                        <FormControl isInvalid={invalid}>
                            <FormLabel fontSize="sm">CEX Accounts</FormLabel>
                            <Select
                                value={value}
                                onChange={onChange}
                                isClearable
                                isSearchable
                                isMulti
                                name={name}
                                options={cexAccountOptions}
                                onBlur={onBlur}
                                ref={ref}
                                onInputChange={(value) => handleSearchSelect("exchangeAccounts", value)}
                                placeholder="CEX Accounts"
                            />
                        </FormControl>
                    )
                }}
            />
        </Flex>
    )
}

export default CreateOrEditGroupForm
