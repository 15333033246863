import { EditIcon } from "@chakra-ui/icons"
import { IconButton } from "@chakra-ui/react"
import { useDispatch } from "react-redux"

import { Wallet } from "core/types/wallet"

import walletSlice from "./slice"

interface Props {
    value: Wallet
}

const ActionCell: React.FC<Props> = ({ value }) => {
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(walletSlice.actions.setSelectedWalletInfo(value))
    }

    return (
        <IconButton
            minWidth="32px"
            width="32px"
            height="32px"
            _hover={{
                bgColor: "gray.200",
            }}
            variant="ghost"
            onClick={handleClick}
            aria-label="Edit wallet"
            icon={<EditIcon />}
        />
    )
}

export default ActionCell
