import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
} from "@chakra-ui/react"
import { useEffect } from "react"
import { FormProvider } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"
import { AssetMapping, AssetPostPayload } from "core/types/asset"

import CreateOrEditGroupForm from "./CreateOrEditAssetForm"
import useEditGroupForm from "./CreateOrEditAssetForm/useEditAssetForm"
import assetSlice from "./slice"

const EditAssetDrawer: React.FC = () => {
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const selectedAssetID = useSelector((state: RootState) => state.asset.selectedAssetID)
    const isSubmitting = useSelector((state: RootState) => state.asset.isSubmitting)
    const methods = useEditGroupForm()

    const shouldOpen = !!selectedAssetID

    const handleClose = () => {
        dispatch(assetSlice.actions.setSelectedAssetID())
    }

    const handleClickSubmit = () => {
        const values = methods.getValues()
        const mappings = Object.entries(values.cex_asset_mapping)
            .map(([exchange_id, symbol]) => {
                if (!symbol) {
                    return undefined
                }

                return {
                    // parseInt here is fine as exchange_id must be an integer string
                    exchange_id: parseInt(exchange_id, 10),
                    symbol,
                }
            })
            .filter(Boolean) as AssetMapping[]

        const payload: AssetPostPayload = {
            id: values.id,

            name: values.name,
            symbol: values.symbol,
            logo_url: values.logo_url,
            is_active: values.is_active,

            cmc_id: values.cmc_id,
            cmc_slug: values.cmc_slug,
            cex_asset_mapping: mappings,
        }

        dispatch(assetSlice.actions.postAssetRequest(payload))
    }

    useEffect(() => {
        if (shouldOpen) {
            onOpen()
        } else {
            onClose()
        }
    }, [shouldOpen, onOpen, onClose])

    return (
        <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader position="sticky" top="0">
                    Edit asset
                </DrawerHeader>

                <DrawerBody paddingTop="16px" paddingBottom="32px">
                    <FormProvider {...methods}>
                        <CreateOrEditGroupForm />
                    </FormProvider>
                </DrawerBody>

                <DrawerFooter position="sticky" bottom="0" borderTop="1px" borderColor="gray.200">
                    <Button variant="outline" mr={3} onClick={handleClose} disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!methods.formState.isDirty}
                        isLoading={isSubmitting}
                        loadingText="Submitting"
                        colorScheme="teal"
                        onClick={methods.handleSubmit(handleClickSubmit)}
                    >
                        Submit
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default EditAssetDrawer
