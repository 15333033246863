import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons"
import { Box, Button } from "@chakra-ui/react"
import { nanoid } from "@reduxjs/toolkit"
import React, { useContext, useEffect, useMemo, useState } from "react"

import { clamp } from "./consts"
import PaginationContext from "./context"

interface IProps {
    disabled?: boolean
}

const MAX_LENGTH = 5
const PAGE_OFFSET = Math.round(MAX_LENGTH / 2)

const generatePages = (from: number, to: number) => [...Array(to - from + 1)].map((_, i) => from + i)

const skipPages = (from: number, to: number): number[] => {
    if (from > to) {
        if (from <= MAX_LENGTH) return [from - 1, from]
        return [to, to + 1]
    }

    return [from - 1, from === to ? from : -1, to + 1]
}

const PageSelector: React.FC<IProps> = ({ disabled }) => {
    const { total, pageIndex, pageSize, onPageIndexChange, ...context } = useContext(PaginationContext)
    const [pageCount, setPageCount] = useState(0)

    const isDisabled = disabled || context.disabled
    const canGoPreviousPage = !isDisabled && pageIndex > 0
    const canGoNextPage = !isDisabled && pageIndex < pageCount - 1

    const onPreviousPage = () => {
        if (!canGoPreviousPage || !onPageIndexChange) return
        onPageIndexChange(pageIndex - 1)
    }

    const onNextPage = () => {
        if (!canGoNextPage || !onPageIndexChange) return
        onPageIndexChange(pageIndex + 1)
    }

    const handlePageChange = (index: number) => () => {
        if (!onPageIndexChange) {
            return
        }

        onPageIndexChange(index)
    }

    const pageArray = useMemo(() => {
        if (pageCount <= MAX_LENGTH) return generatePages(0, pageCount - 1)

        const leftBound = clamp(0, pageIndex - PAGE_OFFSET, pageCount - MAX_LENGTH - 1)
        const rightBound = clamp(MAX_LENGTH, pageIndex + PAGE_OFFSET, pageCount - 1)
        const fromPage = leftBound + 2
        const toPage = rightBound - 2

        const array = [
            ...skipPages(1, leftBound),
            ...generatePages(fromPage, toPage),
            ...skipPages(rightBound, pageCount - 2),
        ]

        return array
    }, [pageCount, pageIndex])

    useEffect(() => {
        setPageCount(Math.ceil(total / pageSize))
    }, [total, pageSize])

    return (
        <Box d="flex" alignItems="center" columnGap="4px">
            <Button
                fontWeight="normal"
                border="none"
                variant="outline"
                size="sm"
                disabled={!canGoPreviousPage}
                onClick={onPreviousPage}
                leftIcon={<ArrowBackIcon />}
            >
                Previous
            </Button>

            <Box d="flex" alignItems="center" columnGap="2px">
                {pageArray.map((num) => {
                    const isActive = pageIndex === num

                    if (num === -1) {
                        return (
                            <Box key={nanoid(8)} d="flex" alignItems="center" justifyContent="center" minW="24px">
                                ...
                            </Box>
                        )
                    }

                    return (
                        // Using random keys to avoid flickering when hitting Next buttons for a few consecutive times
                        <Button
                            key={nanoid(8)}
                            fontWeight="normal"
                            size="sm"
                            height="7"
                            colorScheme={isActive ? "teal" : undefined}
                            variant={isActive ? "solid" : "ghost"}
                            onClick={handlePageChange(num)}
                            disabled={isDisabled}
                        >
                            {num + 1}
                        </Button>
                    )
                })}
            </Box>

            <Button
                fontWeight="normal"
                border="none"
                variant="outline"
                size="sm"
                disabled={!canGoNextPage}
                onClick={onNextPage}
                rightIcon={<ArrowForwardIcon />}
            >
                Next
            </Button>
        </Box>
    )
}

export default PageSelector
