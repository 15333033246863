import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { TokenResponse } from "core/apis/token"
import { allChains } from "core/globalConstants"
import { PutTokenPayload, Token } from "core/types"
import { TokenParams } from "core/types/token"

import { TokenFilterValues } from "./model"

interface IState {
    searchText: string
    tokenResponse: TokenResponse
    tokenParams: TokenParams | undefined
    isLoading: boolean
    isSubmitting: boolean
    tokenFilter: TokenFilterValues
    selectedTokenID?: number
}

const initialState: IState = {
    searchText: "",
    tokenResponse: { tokens: [], pagination: { totalItems: 0 } },
    tokenParams: undefined,
    isLoading: false,
    isSubmitting: false,
    tokenFilter: {
        is_native: [true, false],
        chain_id: allChains.map((chain) => chain.id),
    },
}

const tokenSlice = createSlice({
    name: "tokenSlice",
    initialState,
    reducers: {
        getTokensRequest: (state, action: PayloadAction<TokenParams>) => {
            state.isLoading = true
            state.tokenParams = action.payload
        },
        getTokensSuccess: (state, action: PayloadAction<TokenResponse>) => {
            state.tokenResponse = action.payload
            state.isLoading = false
        },
        getTokensFailure: (state) => {
            state.isLoading = false
        },

        setTokenFilter: (state, action: PayloadAction<TokenFilterValues>) => {
            state.tokenFilter = action.payload
        },

        setSearchText: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload
        },

        setSelectedTokenID: (state, action: PayloadAction<number | undefined>) => {
            state.selectedTokenID = action.payload
        },

        putTokenRequest: (state, _action: PayloadAction<PutTokenPayload>) => {
            state.isSubmitting = true
        },
        putTokenSuccess: (state, _: PayloadAction<Token>) => {
            state.isSubmitting = false
        },
        putTokenFailure: (state) => {
            state.isSubmitting = false
        },
    },
})

export default tokenSlice
