import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { Asset } from "core/types/asset"
import { ExchangeAccount } from "core/types/balance"
import { Group } from "core/types/group"
import { Wallet } from "core/types/wallet"

type SearchResult = {
    wallets: Wallet[]
    groups: Group[]
    assets: Asset[]
    exchangeAccounts: ExchangeAccount[]
}

export const SearchResultDefault = {
    assets: [],
    wallets: [],
    groups: [],
    exchangeAccounts: [],
}

export type SearchQuery = { wallets: string; groups: string; exchangeAccounts: string; assets: string }

type UserInfo = { email: string; picture: string; first_name: string; last_name: string } | undefined
interface IState {
    accessKey: string
    secretKey: string
    searchSuggestions: SearchResult
    searchResults: SearchResult
    searchQuery: SearchQuery
    searching: boolean
    userInfo: UserInfo
}

const initialState: IState = {
    accessKey: "",
    secretKey: "",
    searchSuggestions: SearchResultDefault,
    searchResults: SearchResultDefault,
    searchQuery: { wallets: "", groups: "", exchangeAccounts: "", assets: "" },
    searching: false,
    userInfo: undefined,
}

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setUserInfo: (state, action: PayloadAction<UserInfo>) => {
            state.userInfo = action.payload
        },
        setAccessKey: (state, action: PayloadAction<string>) => {
            state.accessKey = action.payload
        },
        setSecretKey: (state, action: PayloadAction<string>) => {
            state.secretKey = action.payload
        },
        setSearchQuery: (state, action: PayloadAction<Partial<SearchQuery>>) => {
            state.searchQuery = { ...state.searchQuery, ...action.payload }
            const searchResult = { ...state.searchResults }
            let hasChange = false
            Object.keys(state.searchQuery).forEach((key) => {
                if (!state.searchQuery[key] && state.searchSuggestions[key]) {
                    hasChange = true
                    searchResult[key] = state.searchSuggestions[key]
                }
            })
            if (hasChange) state.searchResults = searchResult
        },
        searchRequest: (state) => {
            state.searching = true
        },
        searchRequestSuccess: (state, action: PayloadAction<Partial<SearchResult>>) => {
            const { groups, assets, exchangeAccounts, wallets } = action.payload
            const searchResult = { ...state.searchResults }

            if (groups) searchResult.groups = groups
            if (assets) searchResult.assets = assets
            if (exchangeAccounts) searchResult.exchangeAccounts = exchangeAccounts
            if (wallets) searchResult.wallets = wallets

            state.searchResults = searchResult
            state.searching = false
        },
        searchRequestFailed: (state) => {
            state.searching = true
        },
        _initializeRequest: (state) => {
            state.searching = true
        },
        _initializeSuccess: (state, action: PayloadAction<SearchResult>) => {
            state.searchSuggestions = action.payload
            if (!state.searchResults) state.searchResults = { ...initialState.searchResults }
            state.searchResults = action.payload
            state.searching = false
        },
    },
})

export default commonSlice
