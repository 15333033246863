import { configureStore } from "@reduxjs/toolkit"
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import createSagaMiddleware from "redux-saga"

import commonSlice from "./common.slice"
// defaults to localStorage for web
import rootReducer from "./reducer"
import rootSaga from "./rootSaga"

const persistConfig = {
    key: "asset_overview_frontend",
    storage,
    whitelist: [commonSlice.name],
}

const sagaMiddleware = createSagaMiddleware()
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    preloadedState: {},
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(sagaMiddleware),
    devTools: true,
})

const persistor = persistStore(store)
sagaMiddleware.run(rootSaga)

export { store, persistor }
