import { AllEffect, ForkEffect, all, fork } from "redux-saga/effects"

import commonSaga from "core/redux/common.saga"
import assetSaga from "pages/Asset/saga"
import assetMovementSaga from "pages/AssetMovement/saga"
import balanceSaga from "pages/Balance/saga"
import cexMappingSaga from "pages/CEXAssetMapping/saga"
import balanceComparisonSaga from "pages/Comparison/saga"
import groupSaga from "pages/Group/saga"
import tokenSaga from "pages/Token/saga"
import virtualSaga from "pages/Virtual/saga"
import walletSaga from "pages/Wallet/saga"

export default function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
    yield all([
        fork(commonSaga),
        fork(tokenSaga),
        fork(virtualSaga),
        fork(balanceSaga),
        fork(walletSaga),
        fork(groupSaga),
        fork(assetSaga),
        fork(cexMappingSaga),
        fork(assetMovementSaga),
        fork(balanceComparisonSaga),
    ])
}
