import { ExternalLinkIcon } from "@chakra-ui/icons"
import { Badge, Box, Flex, IconButton, Image, Text } from "@chakra-ui/react"
import { CSSProperties } from "styled-components"
import getTokenExplorerURLByChainID from "utils/getTokenExplorerURLByChainID"

import { Token } from "core/types"
import { formatFloat } from "pages/Balance/utils"
import { CopyAddressIcon } from "pages/Token/AddressCell"
import { getChainById } from "pages/Token/ChainCell"

const SimpleTokenLink = ({ token, style, amount }: { token: Token; style?: CSSProperties; amount?: number }) => {
    const { address, chain_id, is_native } = token
    const chain = getChainById(chain_id)
    return (
        <Text
            as="li"
            style={{ display: "flex", alignItems: "center", gap: "4px", ...style }}
            fontSize="sm"
            key={token.id}
        >
            <Flex alignItems={"center"} gap={"4px"} width={"100%"}>
                {chain?.logoURL ? (
                    <Image boxSize="20px" borderRadius="100%" src={chain?.logoURL} alt={chain?.name} />
                ) : (
                    <Box height="20px" flex="0 0 20px" borderRadius="100%" bgColor="blackAlpha.200" />
                )}

                <Text whiteSpace={"nowrap"}>
                    {amount !== undefined && formatFloat(amount)} {token.symbol} {token.name ? `(${token.name})` : null}
                </Text>
            </Flex>
            <Flex alignItems={"center"}>
                {is_native ? (
                    <Badge colorScheme="teal" variant="outline" fontSize={10}>
                        Native
                    </Badge>
                ) : (
                    <>
                        <CopyAddressIcon address={address} />
                        {address && (
                            <IconButton
                                onClick={() => window.open(getTokenExplorerURLByChainID(address, chain_id))}
                                height={"fit-content"}
                                minWidth="24px"
                                color="gray.600"
                                bgColor="transparent"
                                _hover={{
                                    color: "gray.900",
                                }}
                                _focus={{
                                    outline: "none",
                                }}
                                variant="ghost"
                                aria-label="Open explorer url"
                                icon={<ExternalLinkIcon />}
                            />
                        )}
                    </>
                )}
            </Flex>
        </Text>
    )
}
export default SimpleTokenLink
