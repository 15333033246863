import { KyberOauth2Api } from "@kybernetwork/oauth2"
import axios, { AxiosRequestConfig } from "axios"
import { API_V2_URL } from "constants/index"

class API {
    static get(path: string, params: AxiosRequestConfig["params"]) {
        return KyberOauth2Api.get(`${API_V2_URL}${path}`, params).then((data: any) => data?.data?.data)
    }
    static call(config: AxiosRequestConfig) {
        return KyberOauth2Api.call({ ...config, url: `${API_V2_URL}${config.url}` } as any).then(
            (data: any) => data?.data?.data
        )
    }
    static callExternal(url: string) {
        return axios.get(url).then((data: any) => data?.data?.data)
    }
    static exportCsv(config: AxiosRequestConfig) {
        config.responseType = "blob"
        config.url = `${API_V2_URL}${config.url}`
        return KyberOauth2Api.call(config as any).then((data: any) => data?.data) // todo
    }
}

export default API
