import { ForkEffect, call, put, select, takeLatest } from "redux-saga/effects"

import balanceAPI, { BalanceParams } from "core/apis/balance"
import { RootState } from "core/redux/reducer"
import { toast } from "core/redux/toast.slice"
import { BalanceResponseV2 } from "core/types/balance"

import balanceSlice from "./slice"

export function* getFilterBalance() {
    const state: RootState = yield select((state) => state)
    const {
        filter: { groupIDs, walletIDs, cexAccountIDs },
    } = state.balance
    const { groups = [] } = state?.common?.searchResults
    let uniqueWalletIDs: number[] = [...walletIDs]
    groupIDs.forEach((id) => {
        const group = groups.find((group) => group.id === id)
        if (group) {
            uniqueWalletIDs.push(...group.wallets.map((wallet) => wallet.id).filter(Boolean))
        }
    })
    uniqueWalletIDs = [...new Set(uniqueWalletIDs)]

    let uniqueCEXAccounts: string[] = [...cexAccountIDs]
    groupIDs.forEach((id) => {
        const group = groups.find((group) => group.id === id)
        if (group) {
            uniqueCEXAccounts.push(...group.cex_accounts)
        }
    })
    uniqueCEXAccounts = [...new Set(uniqueCEXAccounts)]

    return {
        wallet_id: uniqueWalletIDs,
        cex_account: uniqueCEXAccounts,
        group_id: groupIDs,
    }
}

function* getBalancesAndRatesSaga() {
    const state: RootState = yield select((state) => state)
    const {
        filter,
        chainIDs,
        balanceTimestamp,
        rateTimestamp,
        settings: {
            token_min_amount_usd = 0,
            sortAssetsByUSDValue,
            includeRegularBalances,
            includeLPBalances,
            includeFarmBalancesDeposited,
            includeFarmBalancesJoined,
        },
    } = state.balance
    let balanceResponse: BalanceResponseV2 = {
        balance_cex_timestamp: 0,
        balances: [],
        rate_cex_timestamp: 0,
        total_amount_usd: 0,
    }

    try {
        if (!chainIDs.length) {
            throw new Error("Please select chains")
        }

        const { wallet_id, group_id, cex_account } = yield getFilterBalance()
        const params: BalanceParams = {
            wallet_id,
            cex_account,
            group_id,
            chain_id: chainIDs.map((e) => parseInt(e) + ""),
            balance_timestamp: balanceTimestamp,
            rate_timestamp: rateTimestamp,
            token_min_amount_usd,
            descending: sortAssetsByUSDValue,
        }

        if (includeRegularBalances) params.regular_balance = true
        if (includeLPBalances) params.pool_balance = true
        if (includeFarmBalancesDeposited) params.farm_balance_deposited = true
        if (includeFarmBalancesJoined) params.farm_balance_joined = true

        Object.keys(filter).forEach((key) => {
            if (filter[key] && key.startsWith("all_")) {
                // filter all
                params[key] = filter[key]
            }
        })
        const response: BalanceResponseV2 = yield call(balanceAPI.getBalancesV2, params)
        yield put(balanceSlice.actions.setPrevParams(params))
        balanceResponse = response
    } catch (error) {
        yield put(balanceSlice.actions.getBalancesAndRatesFailure())
        yield put(
            toast({
                description: `Get Balance failed: ${error?.toString()}`,
                status: "error",
            })
        )
    }

    yield put(
        balanceSlice.actions.getBalancesAndRatesSuccess({
            balanceResponse,
        })
    )
}

export default function* balanceSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(balanceSlice.actions.getBalancesAndRatesRequest, getBalancesAndRatesSaga)
}
