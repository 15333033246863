import AssetPage from "pages/Asset"
// import AssetMovementPage from "pages/AssetMovement"
import BalancePage from "pages/Balance"
import BalanceComparisonPage from "pages/Comparison"
// import DebuggerPage from "pages/Debugger"
// import BalanceComparisonPage from "pages/Comparison"
import DebuggerPage from "pages/Debugger"
// import DebuggerV1Page from "pages/DebuggerV1"
import GroupPage from "pages/Group"
// import TestGetCurveBalancesPage from "pages/TestGetCurveBalances"
// import TestGetLPBalancesPage from "pages/TestGetLPBalances"
import TokenPage from "pages/Token"
// import VirtualPage from "pages/Virtual"
import WalletPage from "pages/Wallet"

export const isInDevelopment = process.env.REACT_APP_ENV === "development" || process.env.REACT_APP_ENV === "staging"

export const APP_PATHS = {
    ASSETS: "/asset",
    BALANCE: "/balance",
}

const routes: { path: string; desc: string; title: string; component: any }[] = [
    // { path: "/virtual", component: VirtualPage ,desc:'Virtual balances'},
    { path: APP_PATHS.BALANCE, component: BalancePage, desc: "Balance of sources", title: "Balance" },
    {
        path: "/compare",
        component: BalanceComparisonPage,
        desc: "Compare balance at 2 different time points",
        title: "Compare",
    },
    { path: APP_PATHS.ASSETS, component: AssetPage, desc: "Manage assets to track", title: "Asset" },
    { path: "/token", component: TokenPage, desc: "All tokens that were found", title: "Token" },
    { path: "/wallet", component: WalletPage, desc: "All wallets that are being tracked", title: "Wallet" },
    { path: "/group", component: GroupPage, desc: "Source grouping", title: "Group" },
    // { path: "/movement", component: AssetMovementPage, desc: "Asset movement", title: "Movement" },
    isInDevelopment ? { path: "/debugger", component: DebuggerPage, desc: "Debugger", title: "Debugger" } : null,
    // { path: "/test-get-lp-balances", component: TestGetLPBalancesPage, desc: "Test Get LP Balances"  },
    // { path: "/test-get-curve-balances", component: TestGetCurveBalancesPage, desc: "Test Get Curve Balances"  },
].filter(Boolean)

export default routes
