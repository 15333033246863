import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { GroupParams, GroupsResponse } from "core/apis/group"
import { GroupPostPayload } from "core/types/group"

interface IState {
    prevParams: GroupParams | undefined
    groupsResponse: GroupsResponse
    allPageSize: number
    isLoading: boolean
    isSubmitting: boolean
    searchText: string
    isDrawerOpen: boolean
    selectedGroupID?: number
}

const initialState: IState = {
    prevParams: undefined,
    groupsResponse: { groups: [], pagination: { totalItems: 0 } },
    allPageSize: 0,
    isLoading: false,
    isSubmitting: false,
    searchText: "",
    isDrawerOpen: false,
}

const groupSlice = createSlice({
    name: "group",
    initialState,
    reducers: {
        // get all groups to populate the table
        getGroupsRequest: (state, action: PayloadAction<GroupParams>) => {
            state.isLoading = true
            state.prevParams = action.payload
        },
        getGroupsSuccess: (state, action: PayloadAction<GroupsResponse>) => {
            state.groupsResponse = action.payload
            if (!state.allPageSize) state.allPageSize = action?.payload?.pagination?.totalItems
            state.isLoading = false
        },
        getGroupsFailure: (state) => {
            state.isLoading = false
        },

        // delete group
        deleteGroupRequest: (state, _action: PayloadAction<number>) => {
            state.isSubmitting = true
        },
        deleteGroupSuccess: (state, _: PayloadAction<number>) => {
            state.isSubmitting = false
        },
        deleteGroupFailure: (state) => {
            state.isSubmitting = false
        },

        // create/update group
        postGroupRequest: (state, _action: PayloadAction<GroupPostPayload>) => {
            state.isSubmitting = true
        },
        postGroupRequestSuccess: (state, _action: PayloadAction<GroupPostPayload>) => {
            state.isSubmitting = false
            state.selectedGroupID = undefined
        },
        postGroupRequestFailed: (state, _action: PayloadAction<GroupPostPayload>) => {
            state.isSubmitting = false
        },

        setSearchText: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload
        },

        setSelectedGroupID: (state, action: PayloadAction<number | undefined>) => {
            state.selectedGroupID = action.payload
        },
    },
})

export default groupSlice
