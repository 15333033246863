import { SettingsIcon } from "@chakra-ui/icons"
import {
    Box,
    Button,
    Flex,
    IconButton,
    Image,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverFooter,
    PopoverHeader,
    PopoverTrigger,
    Select,
    Text,
    useDisclosure,
} from "@chakra-ui/react"
import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

// import AssetSelect from "components/AssetSelect"
import useSearchData from "core/hooks/useSugguestionData"
import { RootState } from "core/redux/reducer"
import { Asset } from "core/types/asset"
import { SelectOption } from "core/types/select"

import balanceSlice from "../slice"

const AssetOption: React.FC<{ asset: Asset }> = ({ asset }) => {
    return (
        <Box d="flex" alignItems="center" whiteSpace="nowrap" columnGap="4px">
            <Box height="100%" flex="0 0 24px" d="flex" alignItems="center" justifyContent="center">
                {asset.logo_url ? (
                    <Image boxSize="24px" src={asset.logo_url} alt={asset.name} />
                ) : (
                    <Box height="24px" flex="0 0 24px" borderRadius="100%" bgColor="blackAlpha.200" />
                )}
            </Box>

            <Text as="span" fontSize="sm">
                {asset.symbol}
            </Text>
        </Box>
    )
}

const SettingsButton: React.FC<{ className?: string }> = ({ className }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()
    const token_min_amount_usd = useSelector((state: RootState) => state.balance.settings.token_min_amount_usd)
    const hiddenAssetIDs = useSelector((state: RootState) => state.balance.settings.hiddenAssetIDs)
    const visibleAssetIDs = useSelector((state: RootState) => state.balance.settings.visibleAssetIDs)
    const sortAssetsByUSDValue = useSelector((state: RootState) => state.balance.settings.sortAssetsByUSDValue)

    const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([])
    const [selectedVisibleAssetsOptions, setSelectedVisibleAssetsOptions] = useState<SelectOption[]>([])

    const { assets } = useSearchData()
    const assetOptions: SelectOption[] = useMemo(() => {
        return assets.map((asset) => ({
            label: <AssetOption asset={asset} />,
            value: asset.id,
        }))
    }, [assets])

    const format = (val) => `$` + val
    const parse = (val) => val.replace(/^\$/, "")
    const handleChangeAmount = (str: any) => {
        if (str.includes("-")) return
        dispatch(balanceSlice.actions.setMinTokenAmountUsd(parse(str)))
    }

    const handleClickSortAssets = (e: React.FormEvent<HTMLSelectElement>) => {
        dispatch(balanceSlice.actions.setSortAssets(Boolean(+e.currentTarget.value)))
    }

    const handleChangeHiddenAssets = (options: SelectOption[]) => {
        setSelectedOptions(options)
    }

    const handleChangeVisibleAssets = (options: SelectOption[]) => {
        setSelectedVisibleAssetsOptions(options)
    }

    const handleClickApply = () => {
        dispatch(balanceSlice.actions.setHiddenAssetIDs(selectedOptions.map((option) => option.value)))
        dispatch(balanceSlice.actions.setVisibleAssetIDs(selectedVisibleAssetsOptions.map((option) => option.value)))
        onClose()
    }

    useEffect(() => {
        setSelectedOptions(
            hiddenAssetIDs.map((id) => assetOptions.find((option) => option.value === id)).filter(Boolean)
        )
        setSelectedVisibleAssetsOptions(
            visibleAssetIDs.map((id) => assetOptions.find((option) => option.value === id)).filter(Boolean)
        )
    }, [assetOptions, hiddenAssetIDs, visibleAssetIDs])

    return (
        <Popover placement="bottom" isOpen={isOpen} onOpen={onOpen} onClose={onClose} isLazy>
            <PopoverTrigger>
                <IconButton
                    minWidth="32px"
                    width="32px"
                    height="32px"
                    variant="outline"
                    aria-label="Edit group"
                    icon={<SettingsIcon />}
                />
            </PopoverTrigger>
            <PopoverContent
                width="480px"
                overflow="auto"
                boxShadow="md"
                _focus={{
                    outline: "none",
                }}
                className={className}
            >
                <PopoverHeader fontSize="sm">Settings</PopoverHeader>
                <PopoverCloseButton />

                <PopoverBody padding="16px" display="flex" flexDirection="column" rowGap="10px">
                    <Flex alignItems={"center"} gap={"4px"} justifyContent={"space-between"}>
                        <Text fontSize="sm">Sort account/wallet by the USD values</Text>
                        <Select
                            value={Number(sortAssetsByUSDValue)}
                            onChange={handleClickSortAssets}
                            width={"140px"}
                            size={"sm"}
                        >
                            <option value={"1"}>Descending</option>
                            <option value="0">Ascending</option>
                        </Select>
                    </Flex>

                    <Flex fontSize="sm" align={"center"} gap={"4px"} justifyContent={"space-between"}>
                        Hide all assets with amount less than
                        <NumberInput
                            width={"140px"}
                            value={format(token_min_amount_usd || "")}
                            onChange={handleChangeAmount}
                            min={0}
                        >
                            <NumberInputField height={"32px"} />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                    </Flex>

                    {/* <AssetSelect
                        label="Show only these assets"
                        name="visible-assets"
                        selectedOptions={selectedVisibleAssetsOptions}
                        setSelectedOptions={handleChangeVisibleAssets}
                    />

                    <AssetSelect
                        label="Hide these assets"
                        name="hidden-assets"
                        selectedOptions={selectedOptions}
                        setSelectedOptions={handleChangeHiddenAssets}
                    /> */}
                </PopoverBody>

                <PopoverFooter
                    d="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    p="3"
                    position="sticky"
                    bottom="0"
                    zIndex="1"
                    bgColor="white"
                >
                    <Button size="sm" colorScheme="teal" variant="solid" onClick={handleClickApply}>
                        Apply
                    </Button>
                </PopoverFooter>
            </PopoverContent>
        </Popover>
    )
}

export default styled(SettingsButton)`
    .selector {
        &__menu {
            z-index: 2;
        }
    }
`
