import { ForkEffect, all, call, delay, put, select, takeLatest } from "redux-saga/effects"

import assetAPI from "core/apis/asset"
import cexAPI from "core/apis/cex"
import groupAPI from "core/apis/group"
import walletAPI from "core/apis/wallet"
import commonSlice, { SearchQuery } from "core/redux/common.slice"
import { RootState } from "core/redux/reducer"
import { toast } from "core/redux/toast.slice"

function* searchFilterSaga() {
    try {
        yield delay(300)
        const query: SearchQuery = yield select((state: RootState) => state.common.searchQuery)
        const pageSize = 20

        const promises = [
            query?.groups ? call(groupAPI.getGroupsV2, { pageSize, query: query.groups }) : Promise.resolve(null),
            query?.wallets ? call(walletAPI.getWalletsV2, { pageSize, query: query.wallets }) : Promise.resolve(null),
            query?.assets ? call(assetAPI.getAssetsV2, { pageSize, query: query.assets }) : Promise.resolve(null),
            query?.exchangeAccounts ? call(cexAPI.getAccounts) : Promise.resolve(null),
        ]

        const [groups, wallets, assets, cex_accounts] = yield all(promises)
        yield put(
            commonSlice.actions.searchRequestSuccess(
                promises.length
                    ? {
                          groups: groups?.groups,
                          wallets: wallets?.wallets,
                          assets: assets?.assets,
                          exchangeAccounts: cex_accounts,
                      }
                    : {}
            )
        )
    } catch (error) {
        yield put(commonSlice.actions.searchRequestFailed())
        yield put(
            toast({
                description: `Error search: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

function* initializeBalancesSaga() {
    try {
        const params = { pageSize: 20 }
        const [groups, wallets, assets, cex_accounts] = yield all([
            call(groupAPI.getGroupsV2, params),
            call(walletAPI.getWalletsV2, params),
            call(assetAPI.getAssetsV2, params),
            call(cexAPI.getAccounts),
        ])

        yield put(
            commonSlice.actions._initializeSuccess({
                groups: groups?.groups ?? [],
                wallets: wallets?.wallets ?? [],
                assets: assets?.assets ?? [],
                exchangeAccounts: cex_accounts || [],
            })
        )
    } catch (error) {
        yield put(commonSlice.actions.searchRequestFailed())
        yield put(
            toast({
                description: `Error Balance: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

export default function* balanceSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(commonSlice.actions.searchRequest, searchFilterSaga)
    yield takeLatest(commonSlice.actions._initializeRequest, initializeBalancesSaga)
}
