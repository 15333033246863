import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"

import AuthPanel from "./AuthPanel"
import BodyPanel from "./BodyPanel"
import ParamsPanel from "./ParamsPanel"

const EmptyObject = {}

const ComponentTabs = () => {
    return (
        <Tabs>
            <TabList>
                <Tab _focus={EmptyObject} fontSize="sm">
                    Auth
                </Tab>
                <Tab _focus={EmptyObject} fontSize="sm">
                    Params
                </Tab>
                {/* <Tab _focus={EmptyObject} fontSize="sm">
                    Headers
                </Tab> */}
                <Tab _focus={EmptyObject} fontSize="sm">
                    Body
                </Tab>
            </TabList>

            <TabPanels>
                <TabPanel height="240px" overflow="auto">
                    <AuthPanel />
                </TabPanel>

                <TabPanel height="240px" overflow="auto">
                    <ParamsPanel />
                </TabPanel>
                {/* <TabPanel height="240px" overflow="auto">
                    <HeadersPanel />
                </TabPanel> */}
                <TabPanel height="240px" overflow="auto">
                    <BodyPanel />
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

export default ComponentTabs
