import { Search2Icon, SmallCloseIcon } from "@chakra-ui/icons"
import { Flex, Input, InputGroup, InputLeftElement, InputRightElement } from "@chakra-ui/react"
import { useEffect } from "react"
import { useDispatch } from "react-redux"

import useDebouncedSearch from "core/hooks/useDebouncedSearch"

import walletSlice from "../slice"

const SearchBar: React.FC = () => {
    const dispatch = useDispatch()

    const { searchText, setSearchText, debouncedText } = useDebouncedSearch("", 500)

    useEffect(() => {
        dispatch(walletSlice.actions.setSearchText(debouncedText))
    }, [debouncedText, dispatch])

    return (
        <InputGroup>
            <InputLeftElement height="32px" pointerEvents="none">
                <Search2Icon color="gray.300" width="14px" />
            </InputLeftElement>
            <Input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                borderRadius="4px"
                size="sm"
                type="tel"
                placeholder="Search for wallets..."
            />
            {searchText ? (
                <InputRightElement height="32px" display="flex" justifyContent="center" alignItems="center">
                    <Flex
                        cursor="pointer"
                        justifyContent="center"
                        alignItems="center"
                        color="gray.300"
                        _hover={{
                            bgColor: "gray.100",
                            color: "gray.500",
                        }}
                        height="24px"
                        width="24px"
                        padding="0px"
                        borderRadius="50%"
                        variant="ghost"
                        onClick={() => setSearchText("")}
                        transitionProperty="background, color"
                        transitionTimingFunction="ease"
                        transitionDuration="150ms"
                    >
                        <SmallCloseIcon color="inherit" width="24px" />
                    </Flex>
                </InputRightElement>
            ) : null}
        </InputGroup>
    )
}

export default SearchBar
