import { Flex } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import styled from "styled-components"

interface IProps {
    isActive?: boolean
    title: string
    href: string
    className?: string
}

const NavItem: React.FC<IProps> = ({ isActive, title, href, className }) => {
    return (
        <Flex
            flexFlow="row nowrap"
            cursor="pointer"
            fontSize="md"
            width="fit-content"
            fontWeight="500"
            transitionProperty="color"
            transitionDuration="150ms"
            transitionTimingFunction="ease-in-out"
            className={className}
            data-active={isActive}
            color="gray.500"
            _hover={{
                color: "gray.700",
            }}
        >
            <Link to={href}>{title}</Link>
        </Flex>
    )
}

export default styled(NavItem)`
    &[data-active="true"] {
        color: teal;
        position: relative;
    }

    a {
        padding: 8px 12px;
        text-decoration: none;
        color: inherit;
        &:hover {
            text-decoration: none;
        }
    }
`
