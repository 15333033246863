import axios, { AxiosResponse } from "axios"

import { APIMasterData } from "core/http/type"
import { AssetMapping } from "core/types/asset"
import { Exchange } from "core/types/cex"

type Query = {
    start?: number
    limit?: number
}

type GetMarketPairsResponse = {
    data: {
        id: number
        marketPairs: {
            baseSymbol: string
            quoteSymbol: string
            exchangeId: number
        }[]
        numMarketPairs: number
        symbol: string
    }
}

const defaultQuery: Query = {
    start: 1,
    limit: 200,
}

const getExchangeMappings = async (
    slug: string,
    exchanges: Exchange[],
    query?: Query
): Promise<APIMasterData<AssetMapping[]>> => {
    const mergedQuery: Query = {
        ...defaultQuery,
        ...query,
    }

    const response: AxiosResponse<GetMarketPairsResponse> = await axios.get(
        `https://api.coinmarketcap.com/data-api/v3/cryptocurrency/market-pairs/latest`,
        {
            params: {
                category: "spot",
                sort: "cmc_rank_advanced",
                slug,
                start: mergedQuery.start,
                limit: mergedQuery.limit,
            },
        }
    )

    if (!response.data) {
        return {
            error: "Failed to fetch market pairs",
        }
    }

    const exchangeByCmcID: Record<string, Exchange> = exchanges.reduce((acc, cex) => {
        acc[cex.cmc_id] = cex
        return acc
    }, {})
    let exchangeCmcIDs = Object.keys(exchangeByCmcID)

    const mappings: AssetMapping[] = []
    response.data.data.marketPairs.forEach((pair) => {
        if (exchangeCmcIDs.includes(String(pair.exchangeId)) && pair.quoteSymbol.includes("USD")) {
            mappings.push({
                exchange_id: exchangeByCmcID[pair.exchangeId].id,
                symbol: pair.baseSymbol,
            })

            // avoid duplication
            exchangeCmcIDs = exchangeCmcIDs.filter((cmcID) => cmcID !== String(pair.exchangeId))
        }
    })

    return {
        data: mappings,
    }
}

export default getExchangeMappings
