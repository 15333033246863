import { Flex, FormControl, FormLabel, Textarea } from "@chakra-ui/react"
import KyberOauth2 from "@kybernetwork/oauth2"
import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"
import debuggerSlice from "pages/Debugger/slice"

const AuthPanel = () => {
    const accessToken = useSelector((state: RootState) => state.debugger.accessToken)
    const dispatch = useDispatch()
    const setToken = useCallback((token: string) => dispatch(debuggerSlice.actions.setToken(token)), [dispatch])

    const handleChangeAccessKey = (e: any) => {
        setToken(e.target.value)
    }

    useEffect(() => {
        setToken(KyberOauth2.getAccessToken())
    }, [setToken])

    return (
        <Flex flexDir="column" gap="12px">
            <Flex gap={4}>
                <FormControl>
                    <FormLabel>Access Token</FormLabel>
                    <Textarea style={{ width: "100%" }} value={accessToken} onChange={handleChangeAccessKey} rows={6} />
                </FormControl>
            </Flex>
        </Flex>
    )
}

export default AuthPanel
