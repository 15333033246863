const urlGeneratorByChainID: Record<string, (addr: string) => string> = {
    1: (addr: string) => {
        return `https://etherscan.io/token/${addr}`
    },
    56: (addr: string) => {
        return `https://bscscan.com/token/${addr}`
    },
    137: (addr: string) => {
        return `https://polygonscan.com/token/${addr}`
    },
    43114: (addr: string) => {
        return `https://snowtrace.io/token/${addr}`
    },
    42161: (addr: string) => {
        return `https://arbiscan.io/token/${addr}`
    },
    250: (addr: string) => {
        return `https://ftmscan.com/token/${addr}`
    },
    10: (addr: string) => {
        return `https://optimistic.etherscan.io/token/${addr}`
    },
}

const getTokenExplorerURLByChainID = (tokenAddress: string, chainID = "1"): string => {
    if (!urlGeneratorByChainID[chainID]) {
        console.error(`No such chainID (${chainID}) is implemented`)
        return ""
    }

    return urlGeneratorByChainID[chainID](tokenAddress)
}

export default getTokenExplorerURLByChainID
