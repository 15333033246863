import { SettingsIcon } from "@chakra-ui/icons"
import {
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
} from "@chakra-ui/react"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"

import { allChains } from "core/globalConstants"
import { RootState } from "core/redux/reducer"
import { SelectOption } from "core/types/select"

import ChainCell from "../ChainCell"
import { TokenFilterOptions, TokenFilterValues } from "../model"
import tokenSlice from "../slice"

const nativeFilterOptions: SelectOption[] = [
    {
        label: "true",
        value: true,
    },
    {
        label: "false",
        value: false,
    },
]

const chainIDFilterOptions: SelectOption[] = allChains.map((chain) => ({
    label: <ChainCell value={chain.id} />,
    value: chain.id,
}))

const AllOptions: TokenFilterOptions = {
    is_native: nativeFilterOptions,
    chain_id: chainIDFilterOptions,
}

const convertTokenFilterOptionsToValues = (data: TokenFilterOptions): TokenFilterValues => {
    const filterValues: TokenFilterValues = {}

    Object.entries(data).forEach(([key, options]) => {
        filterValues[key] = options.map((option) => option.value)
    })

    return filterValues
}

const convertTokenFilterValuesToOptions = (
    data: TokenFilterValues,
    allOptions: TokenFilterOptions
): TokenFilterOptions => {
    const selectedOptions: TokenFilterOptions = {}

    Object.entries(data).forEach(([key, values]) => {
        const options = allOptions[key]

        selectedOptions[key] = values
            .map((value) => {
                return options.find((option) => option.value === value)
            })
            .filter(Boolean)
    })

    return selectedOptions
}

const FilterButton: React.FC = () => {
    const dispatch = useDispatch()
    const tokenFilter = useSelector((state: RootState) => state.tokenSlice.tokenFilter)
    const isLoading = useSelector((state: RootState) => state.tokenSlice.isLoading)

    const { control, reset, getValues, handleSubmit } = useForm<TokenFilterOptions>({
        defaultValues: convertTokenFilterValuesToOptions(tokenFilter, AllOptions),
        mode: "all",
    })

    const handleClickApply = () => {
        const values = getValues()
        const filter = convertTokenFilterOptionsToValues(values)
        dispatch(tokenSlice.actions.setTokenFilter(filter))
    }

    useEffect(() => {
        reset(convertTokenFilterValuesToOptions(tokenFilter, AllOptions))
    }, [reset, tokenFilter])

    return (
        <>
            <Popover placement="bottom-start" isLazy>
                <PopoverTrigger>
                    <Button
                        borderRadius="4px"
                        size="sm"
                        padding="0 16px"
                        colorScheme="teal"
                        leftIcon={<SettingsIcon />}
                    >
                        Filter
                    </Button>
                </PopoverTrigger>
                <PopoverContent
                    width="320px"
                    boxShadow="lg"
                    _focus={{
                        outline: "none",
                    }}
                >
                    <PopoverHeader fontSize="md">Filter</PopoverHeader>
                    <PopoverCloseButton />

                    <PopoverBody>
                        {Object.keys(AllOptions).map((key: keyof TokenFilterOptions) => {
                            const options = AllOptions[key]
                            return (
                                <Controller
                                    key={key}
                                    control={control}
                                    name={key}
                                    render={({
                                        field: { onChange, onBlur, value, name, ref },
                                        fieldState: { invalid, error },
                                    }) => {
                                        return (
                                            <FormControl isInvalid={invalid}>
                                                <FormLabel fontSize="sm">{key}</FormLabel>
                                                <Select
                                                    value={value}
                                                    onChange={onChange}
                                                    isClearable
                                                    isSearchable
                                                    isMulti
                                                    name={name}
                                                    options={options}
                                                    onBlur={onBlur}
                                                    ref={ref}
                                                    placeholder={key}
                                                />

                                                {invalid ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                                            </FormControl>
                                        )
                                    }}
                                />
                            )
                        })}
                        <Flex w="100%" justifyContent="flex-end">
                            <Button
                                mt="8px"
                                borderRadius="4px"
                                size="sm"
                                padding="0 16px"
                                colorScheme="teal"
                                isLoading={isLoading}
                                onClick={handleSubmit(handleClickApply)}
                            >
                                Apply
                            </Button>
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </>
    )
}

export default FilterButton
