import { ForkEffect, call, put, select, takeLatest } from "redux-saga/effects"

import assetAPI, { AssetParams, AssetResponse } from "core/apis/asset"
import cexAPI from "core/apis/cex"
import { APIMasterData } from "core/http/type"
import { toast } from "core/redux/toast.slice"
import { Asset, AssetPostPayload } from "core/types/asset"

import assetSlice from "./slice"

function* getAssetsSaga({ payload }: { payload: AssetParams }) {
    try {
        const response: AssetResponse = yield call(assetAPI.getAssetsV2, payload)
        yield put(assetSlice.actions.getAssetsSuccess(response))
    } catch (error) {
        yield put(assetSlice.actions.getAssetsFailure())
        yield put(
            toast({
                description: `Error: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

function* postAssetSaga({ payload }: { payload: AssetPostPayload }) {
    try {
        const response: APIMasterData<Asset> = yield call(assetAPI.postAsset, payload)
        yield put(assetSlice.actions.postAssetSuccess(response.data))
        yield put(
            toast({
                description: payload.id ? "Updated asset successfully" : "Added asset successfully",
                status: "success",
            })
        )
        const prevParams = yield select((state) => state.asset.prevParams)
        yield getAssetsSaga({ payload: prevParams })
    } catch (error) {
        yield put(assetSlice.actions.postAssetFailure())
        yield put(
            toast({
                description: `Error: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

function* getExchangesSaga() {
    try {
        const response = yield call(cexAPI.getExchanges)
        yield put(assetSlice.actions.getExchangesSuccess(response?.exchanges ?? []))
    } catch (error) {
        yield put(assetSlice.actions.getExchangesFailure())
        yield put(
            toast({
                description: `Error: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

export default function* assetSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(assetSlice.actions.getAssetsRequest, getAssetsSaga)
    yield takeLatest(assetSlice.actions.postAssetRequest, postAssetSaga)
    yield takeLatest(assetSlice.actions.getExchangesRequest, getExchangesSaga)
}
