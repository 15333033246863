import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { AssetParams, AssetResponse } from "core/apis/asset"
import { Asset, AssetPostPayload } from "core/types/asset"
import { Exchange } from "core/types/cex"

interface IState {
    assets: AssetResponse
    allPageSize: number
    prevParams: AssetParams | undefined
    exchanges: Exchange[]
    selectedAssetID?: number
    isLoading: boolean
    isSubmitting: boolean
    searchText: string
}

const assetDefault = { assets: [], pagination: { totalItems: 0 } }
const initialState: IState = {
    assets: assetDefault,
    allPageSize: 0,
    prevParams: undefined,
    isLoading: false,
    isSubmitting: false,
    exchanges: [],
    searchText: "",
}

const assetSlice = createSlice({
    name: "asset",
    initialState,
    reducers: {
        getAssetsRequest: (state, action: PayloadAction<AssetParams>) => {
            state.isLoading = true
            state.prevParams = action.payload
        },
        getAssetsSuccess: (state, action: PayloadAction<AssetResponse>) => {
            state.assets = action.payload || assetDefault
            if (!state.allPageSize) state.allPageSize = action?.payload?.pagination?.totalItems
            state.isLoading = false
        },
        getAssetsFailure: (state) => {
            state.isLoading = false
        },

        postAssetRequest: (state, _action: PayloadAction<AssetPostPayload>) => {
            state.isSubmitting = true
        },
        postAssetSuccess: (state, _: PayloadAction<Asset>) => {
            state.isSubmitting = false
        },
        postAssetFailure: (state) => {
            state.isSubmitting = false
        },

        setSelectedAssetID: (state, action: PayloadAction<number | undefined>) => {
            state.selectedAssetID = action.payload
        },

        getExchangesRequest: (state) => {
            state.isLoading = true
        },
        getExchangesSuccess: (state, action: PayloadAction<Exchange[]>) => {
            state.exchanges = action.payload
            state.isLoading = false
        },
        getExchangesFailure: (state) => {
            state.isLoading = false
        },

        setSearchText: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload
        },
    },
})

export default assetSlice
