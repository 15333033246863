import { ForkEffect, call, put, select, takeLatest } from "redux-saga/effects"

import walletAPI, { WalletParams } from "core/apis/wallet"
import { toast } from "core/redux/toast.slice"
import { WalletPostPayload } from "core/types/wallet"

import walletSlice from "./slice"

function* getWalletsSaga({ payload }: { payload: WalletParams }) {
    try {
        const response = yield call(walletAPI.getWalletsV2, payload)
        yield put(walletSlice.actions.getWalletsSuccess(response))
    } catch (error) {
        yield put(walletSlice.actions.getWalletsFailure())
        yield put(
            toast({
                description: `Error: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

function* postWalletSaga({ payload }: { payload: WalletPostPayload }) {
    try {
        const resp = yield call(walletAPI.putWallet, payload)
        yield put(walletSlice.actions.postWalletSuccess())
        yield put(
            toast({
                description: !payload.id ? "Added wallet successfully" : "Updated wallet successfully",
                status: "success",
            })
        )
        const prevParams = yield select((state) => state.wallet.prevParams)
        yield put(walletSlice.actions.setSelectedWalletInfo(resp))
        yield getWalletsSaga({ payload: prevParams })
    } catch (error) {
        yield put(walletSlice.actions.postWalletFailure())
        yield put(
            toast({
                description: `Error: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

export default function* walletSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(walletSlice.actions.getWalletsRequest, getWalletsSaga)
    yield takeLatest(walletSlice.actions.postWalletRequest, postWalletSaga)
}
