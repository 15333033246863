import { AddIcon } from "@chakra-ui/icons"
import { Box, Button, Flex } from "@chakra-ui/react"
import React, { useCallback, useState } from "react"

import withPageLayout from "components/withPageLayout"

import AssetTable from "./AssetTable"
import CreateAsset from "./CreateAsset"
import EditAssetDrawer from "./EditAssetDrawer"
import SearchBar from "./SearchBar"

const AssetPage: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false)
    const onDismiss = useCallback(() => setIsOpen(false), [])

    return (
        <Flex direction="column" height="100%" width="100%" padding="24px 160px">
            <Box flex="0 0" width="100%" d="flex" justifyContent="space-between" alignItems="center">
                <Box d="flex" flexDir="column">
                    <Box as="h1" fontSize="3xl">
                        Assets
                    </Box>
                    <Box as="h3" fontSize="sm">
                        Asset management
                    </Box>
                </Box>

                <CreateAsset isOpen={isOpen} onDismiss={onDismiss}>
                    <Button
                        borderRadius="4px"
                        size="sm"
                        colorScheme="teal"
                        leftIcon={<AddIcon />}
                        onClick={() => setIsOpen(true)}
                    >
                        New asset
                    </Button>
                </CreateAsset>
            </Box>

            <Box flex="0 0" width="100%" mt="24px" mb="24px">
                <SearchBar />
            </Box>

            <Box overflow="hidden" width="100%" flex="1 1 100%">
                <AssetTable />
            </Box>

            <EditAssetDrawer />
        </Flex>
    )
}

export default withPageLayout(AssetPage)
