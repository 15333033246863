import { ChakraProvider, Flex, Spinner } from "@chakra-ui/react"
import KyberOauth2, { DevelopmentMode, KyberOauth2ErrorCode, KyberOauth2Event } from "@kybernetwork/oauth2"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import BigNumber from "bignumber.js"
import { WHITELIST_EMAILS } from "constants/index"
import routes from "constants/routes"
import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import { Provider, useDispatch } from "react-redux"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import { PersistGate } from "redux-persist/integration/react"
import checkShouldClearLocalStorageAndReload from "utils/checkShouldClearLocalStorageAndReload"

import OurErrorBoundary from "components/ErrorBoundary"
import ToastContainer from "components/ToastContainer"
import Updater from "components/Updater"
import useShowToast from "core/hooks/useShowToast"
import commonSlice from "core/redux/common.slice"
import { persistor, store } from "core/redux/store"
import HomePage from "pages/Home"
import theme from "theme"
import GlobalStyle from "theme/globalStyle"

const isDev = process.env.NODE_ENV === "development"
const ErrorBoundary = isDev ? React.Fragment : OurErrorBoundary

KyberOauth2.initialize({
    redirectUri: `${window.location.protocol}//${window.location.host}`,
    clientId: process.env.REACT_APP_OAUTH2_CLIENT_ID,
    mode: process.env.REACT_APP_ENV || DevelopmentMode.PRODUCTION,
})

const format = {
    decimalSeparator: ".",
    groupSeparator: ",",
    groupSize: 3,
    secondaryGroupSize: 0,
    fractionGroupSeparator: " ",
    fractionGroupSize: 0,
}
BigNumber.config({ FORMAT: format })

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
        },
    },
})

enum LoginStatus {
    PENDING,
    LOGIN,
    NOT_LOGIN,
}
const App = () => {
    const [loginStatus, setLoginStatus] = useState(LoginStatus.PENDING)
    const { showToastError } = useShowToast()
    const dispatch = useDispatch()
    useEffect(() => {
        const run = async () => {
            try {
                const data = await KyberOauth2.getSession()
                const email: string = data.userInfo?.email ?? ""
                if (WHITELIST_EMAILS.includes(email?.toLowerCase())) {
                    setLoginStatus(LoginStatus.LOGIN)
                    dispatch(commonSlice.actions.setUserInfo(data.userInfo))
                    return
                }
                setTimeout(() => {
                    KyberOauth2.logout()
                }, 3000)
                throw new Error(`${email} is not whitelist`)
            } catch (e) {
                console.log("getSession", e)
                showToastError(
                    e?.code === KyberOauth2ErrorCode.NOT_FOUND_SESSION ? "Please sign-in to continue" : e?.toString()
                )
                setLoginStatus(LoginStatus.NOT_LOGIN)
                dispatch(commonSlice.actions.setUserInfo())
            }
        }
        run()
    }, [showToastError, dispatch])

    useEffect(() => {
        const func = () => {
            if (loginStatus !== LoginStatus.LOGIN) return
            confirm("Your session was expired, please sign-in to continue.")
            window.location.reload()
        }
        KyberOauth2.on(KyberOauth2Event.SESSION_EXPIRED, func)
        return () => KyberOauth2.off(KyberOauth2Event.SESSION_EXPIRED, func)
    }, [loginStatus])

    const isLogin = loginStatus === LoginStatus.LOGIN

    if (loginStatus === LoginStatus.PENDING)
        return (
            <Flex height={"100vh"} alignItems={"center"} justifyContent={"center"}>
                <Spinner />
                &nbsp; Checking session...
            </Flex>
        )
    return (
        <>
            {isLogin && <Updater />}
            <BrowserRouter>
                <Switch>
                    {routes.map((route) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            component={isLogin ? route.component : () => <Redirect to="/" />}
                        />
                    ))}

                    <Route path="/" component={HomePage} />
                    <Redirect to="/" />
                </Switch>
            </BrowserRouter>
        </>
    )
}

const { shouldReload, markReleaseTime } = checkShouldClearLocalStorageAndReload()
if (shouldReload) {
    persistor.pause()
    markReleaseTime()
    window.location.reload()
} else {
    ReactDOM.render(
        <ChakraProvider theme={theme}>
            <GlobalStyle />
            <ErrorBoundary>
                <QueryClientProvider client={queryClient}>
                    <Provider store={store}>
                        <PersistGate persistor={persistor}>
                            <ToastContainer>
                                <App />
                            </ToastContainer>
                        </PersistGate>
                    </Provider>
                </QueryClientProvider>
            </ErrorBoundary>
        </ChakraProvider>,
        document.getElementById("root")
    )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
