import { DeleteIcon, EditIcon } from "@chakra-ui/icons"
import {
    Box,
    Button,
    Flex,
    IconButton,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react"
import { useDispatch } from "react-redux"

import { Group } from "core/types/group"

import groupSlice from "./slice"

interface Props {
    value: number
    row: {
        original: Group
    }
}

const ActionCell: React.FC<Props> = ({ value, row: { original } }) => {
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()

    const handleClickEditButton = () => {
        dispatch(groupSlice.actions.setSelectedGroupID(value))
    }

    const handleClickDeleteButton = () => {
        dispatch(groupSlice.actions.deleteGroupRequest(value))
        onClose()
    }

    return (
        <Flex>
            <IconButton
                minWidth="32px"
                width="32px"
                height="32px"
                _hover={{
                    bgColor: "gray.200",
                }}
                variant="ghost"
                onClick={handleClickEditButton}
                aria-label="Edit group"
                icon={<EditIcon />}
            />

            <IconButton
                minWidth="32px"
                width="32px"
                height="32px"
                _hover={{
                    bgColor: "gray.200",
                }}
                variant="ghost"
                onClick={onOpen}
                aria-label="Edit group"
                icon={<DeleteIcon color="red.500" />}
            />

            <Modal isOpen={isOpen} onClose={onClose} size="sm">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Confirm</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody mb="16px">
                        <Box>Are you sure to delete this group ({original.name}) ?</Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="teal" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant="ghost" onClick={handleClickDeleteButton}>
                            Delete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    )
}

export default ActionCell
