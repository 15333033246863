import { ForkEffect, call, put, select, takeLatest } from "redux-saga/effects"

import assetAPI from "core/apis/asset"
import groupAPI from "core/apis/group"
import tokenTransferAPI from "core/apis/tokenTransfer"
import walletAPI from "core/apis/wallet"
import { APIMasterData } from "core/http/type"
import { RootState } from "core/redux/reducer"
import { toast } from "core/redux/toast.slice"
import { Asset } from "core/types/asset"
import { Group } from "core/types/group"
import { TokenTransfer } from "core/types/transfer"
import { Wallet } from "core/types/wallet"

import assetMovementSlice from "./slice"

function* getGroupsSaga() {
    const response: APIMasterData<Group[]> = yield call(groupAPI.getGroups)

    if (response.data) {
        yield put(assetMovementSlice.actions.getGroupsSuccess(response.data))
    }

    if (response.error) {
        yield put(assetMovementSlice.actions.getGroupsFailure())
        yield put(
            toast({
                description: `Error: ${response.error}`,
                status: "error",
            })
        )
    }
}

function* getWalletsSaga() {
    const response: APIMasterData<Wallet[]> = yield call(walletAPI.getWallets)

    if (response.data) {
        yield put(assetMovementSlice.actions.getWalletsSuccess(response.data))
    }

    if (response.error) {
        yield put(assetMovementSlice.actions.getWalletsFailure())
        yield put(
            toast({
                description: `Error: ${response.error}`,
                status: "error",
            })
        )
    }
}

function* getTokenTransfersSaga() {
    const state: RootState = yield select((state) => state)
    const {
        filter: { groupIDs, walletIDs },
        chainID,
        groups,
        fromTimestamp,
        toTimestamp,
    } = state.assetMovement

    let uniqueWalletIDs: number[] = [...walletIDs]
    groupIDs.forEach((id) => {
        const group = groups.find((group) => group.id === id)
        if (group) {
            uniqueWalletIDs.push(...group.wallets.map((wallet) => wallet.id).filter(Boolean))
        }
    })
    uniqueWalletIDs = [...new Set(uniqueWalletIDs)]

    const response: APIMasterData<TokenTransfer[]> = yield call(
        tokenTransferAPI.getTokenTransfer,
        chainID,
        uniqueWalletIDs,
        fromTimestamp,
        toTimestamp
    )

    if (response.error) {
        yield put(assetMovementSlice.actions.getTokenTransfersFailure())
        yield put(
            toast({
                description: `${response.error}`,
                status: "error",
            })
        )
    }

    if (response.data) {
        yield put(assetMovementSlice.actions.getTokenTransfersSuccess(response.data))
    }
}

function* initializeAssetMovementSaga() {
    let groups: Group[] = []
    let wallets: Wallet[] = []
    let assets: Asset[] = []

    // fetch groups
    {
        const response: APIMasterData<Group[]> = yield call(groupAPI.getGroups)

        if (response.data) {
            groups = response.data
        }

        if (response.error) {
            yield put(assetMovementSlice.actions._initializeAssetMovementFailure())
            yield put(
                toast({
                    description: `Error: ${response.error}`,
                    status: "error",
                })
            )
            return
        }
    }

    // fetch wallets
    {
        const response: APIMasterData<Wallet[]> = yield call(walletAPI.getWallets)

        if (response.data) {
            wallets = response.data
        }

        if (response.error) {
            yield put(assetMovementSlice.actions._initializeAssetMovementFailure())
            yield put(
                toast({
                    description: `Error: ${response.error}`,
                    status: "error",
                })
            )
            return
        }
    }

    // fetch assets
    {
        const response: APIMasterData<Asset[]> = yield call(assetAPI.getAssets)
        if (response.data) {
            assets = response.data
        }
        if (response.error) {
            yield put(assetMovementSlice.actions._initializeAssetMovementFailure())
            yield put(
                toast({
                    description: `Error: ${response.error}`,
                    status: "error",
                })
            )
            return
        }
    }

    yield put(assetMovementSlice.actions._initializeAssetMovementSuccess({ groups, wallets, assets }))
    yield getTokenTransfersSaga()
}

export default function* assetMovementSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(assetMovementSlice.actions.getTokenTransfersRequest, getTokenTransfersSaga)
    yield takeLatest(assetMovementSlice.actions.getWalletsRequest, getWalletsSaga)
    yield takeLatest(assetMovementSlice.actions.getGroupsRequest, getGroupsSaga)
    yield takeLatest(assetMovementSlice.actions._initializeAssetMovementRequest, initializeAssetMovementSaga)
}
