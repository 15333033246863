import http from "core/http"
import { APIMasterData } from "core/http/type"
import { Asset, AssetPostPayload, AssetV2 } from "core/types/asset"

const getAssets = (): Promise<APIMasterData<Asset[]>> => {
    return //http.getWithAuth("/api/assets")
}
export type AssetParams = {
    page?: number
    pageSize?: number
    id?: number
    cmc_id?: string
    query?: string
}

export type AssetResponse = {
    assets: AssetV2[]
    pagination: { totalItems: number }
}

const getAssetsV2 = (params: AssetParams): Promise<AssetResponse> => {
    return http.call({ url: "/v1/assets", params })
}

const postAsset = (payload: AssetPostPayload): Promise<APIMasterData<Asset>> => {
    ;(payload as any).cex_asset_mappings = payload.cex_asset_mapping // todo
    delete payload.cex_asset_mapping
    return http.call({ url: "/v1/assets", data: payload, method: "POST" })
}

const assetAPI = {
    getAssets,
    postAsset,
    getAssetsV2,
}

export default assetAPI
