import { Flex } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"
import { formatGMT } from "utils/date"

import InlineDateTimePicker from "components/_base/InlineDateTimePicker"
import { RootState } from "core/redux/reducer"
import { getTimestampFromDateBalance } from "pages/Balance/UtilityBar/DateSelect"

import balanceComparisonSlice from "../slice"

const dateFormatter = (d: Date): string => {
    return formatGMT(d, "yyyy-MM-dd HH:mm")
}

const DateSelect: React.FC = () => {
    const dispatch = useDispatch()
    const timestamp1 = useSelector((state: RootState) => state.balanceComparison.timestamp1)
    const timestamp2 = useSelector((state: RootState) => state.balanceComparison.timestamp2)

    const first = new Date(timestamp1 * 1000)
    const second = new Date(timestamp2 * 1000)

    const handleChangeFirst = (d: Date) => {
        dispatch(balanceComparisonSlice.actions.setTimestamp1(getTimestampFromDateBalance(d)))
    }

    const handleChangeSecond = (d: Date) => {
        dispatch(balanceComparisonSlice.actions.setTimestamp2(getTimestampFromDateBalance(d)))
    }

    return (
        <Flex columnGap="8px">
            <InlineDateTimePicker
                showSeconds={false}
                dateFormatter={dateFormatter}
                title="Date 1"
                date={first}
                onChangeDate={handleChangeFirst}
            />
            <InlineDateTimePicker
                showSeconds={false}
                dateFormatter={dateFormatter}
                title="Date 2"
                date={second}
                onChangeDate={handleChangeSecond}
            />
        </Flex>
    )
}

export default DateSelect
