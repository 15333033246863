import debounce from "lodash/debounce"
import { useEffect, useRef, useState } from "react"

const useDebouncedSearch = (initialSearchText: string, delay = 500) => {
    const [searchText, setSearchText] = useState(initialSearchText)
    const [debouncedText, setDebouncedText] = useState(searchText)

    const setSearchTextDebounced = useRef(debounce(setDebouncedText, delay))

    useEffect(() => {
        setSearchTextDebounced.current(searchText)
    }, [searchText])

    return { searchText, setSearchText, debouncedText }
}

export default useDebouncedSearch
