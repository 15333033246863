import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
} from "@chakra-ui/react"
import { useEffect } from "react"
import { FormProvider } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"
import { GroupPostPayload } from "core/types/group"

import CreateOrEditGroupForm from "./CreateOrEditGroupForm"
import useEditGroupForm from "./CreateOrEditGroupForm/useEditGroupForm"
import groupSlice from "./slice"

const EditGroupDrawer: React.FC = () => {
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const selectedGroupID = useSelector((state: RootState) => state.group.selectedGroupID)
    const isSubmitting = useSelector((state: RootState) => state.group.isSubmitting)
    const methods = useEditGroupForm()

    const shouldOpen = !!selectedGroupID

    const handleClose = () => {
        dispatch(groupSlice.actions.setSelectedGroupID())
    }

    const handleClickSubmit = () => {
        const values = methods.getValues()
        const payload: GroupPostPayload = {
            id: values.id,
            name: values.name,
            note: values.note,
            wallet_ids: values.wallet_ids.map((option) => option.value as number),
            cex_accounts: values.cex_accounts.map((option) => option.value as string),
        }

        dispatch(groupSlice.actions.postGroupRequest(payload))
    }

    useEffect(() => {
        if (shouldOpen) {
            onOpen()
        } else {
            onClose()
        }
    }, [shouldOpen, onOpen, onClose])

    return (
        <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Edit group</DrawerHeader>

                <DrawerBody paddingTop="16px">
                    <FormProvider {...methods}>
                        <CreateOrEditGroupForm />
                    </FormProvider>
                </DrawerBody>

                <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={handleClose} disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!methods.formState.isDirty}
                        isLoading={isSubmitting}
                        loadingText="Submitting"
                        colorScheme="teal"
                        onClick={methods.handleSubmit(handleClickSubmit)}
                    >
                        Submit
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default EditGroupDrawer
