import http from "core/http"

import getExchangeMappings from "./getExchangeMappings"

const getAssetBySlug = (cmc_slug: string) => {
    return http.get(`/v1/cmc`, { cmc_slug })
}

const cmcAPI = {
    getAssetBySlug,
    getExchangeMappings,
}

export default cmcAPI
