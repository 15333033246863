import { TimeIcon } from "@chakra-ui/icons"
import { Flex } from "@chakra-ui/react"
import React, { forwardRef } from "react"
import styled from "styled-components"

const CustomTimeInput: React.FC<React.ComponentPropsWithRef<"input">> = forwardRef(({ className, ...others }, ref) => {
    return (
        <Flex
            bgColor="inherit"
            width="100%"
            height="32px"
            padding="0 8px"
            alignItems="center"
            columnGap="8px"
            borderRadius="4px"
            className={className}
            transitionProperty="border-color"
            transitionDuration="150ms"
            transitionTimingFunction="ease-in-out"
        >
            <TimeIcon />
            <input {...others} ref={ref} />
        </Flex>
    )
})

export default styled(CustomTimeInput)`
    border: 1px solid var(--chakra-colors-whiteAlpha-300);

    input {
        width: 100%;
        height: 100%;
        background-color: inherit;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
    }
`
