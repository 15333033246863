import { createContext } from "react"

export interface PaginationState {
    total: number
    pageSize: number
    pageIndex: number
    allowDisplayAllRows?: boolean
    disabled?: boolean
    onPageSizeChange: (size: number) => void
    onPageIndexChange: (index: number) => void
}

export default createContext<PaginationState>({
    total: 1,
    pageSize: 1,
    pageIndex: 1,
    allowDisplayAllRows: false,
    disabled: false,
    onPageIndexChange: () => {},
    onPageSizeChange: () => {},
})
