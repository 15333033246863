import { getAddress } from "@ethersproject/address"

export const maskAddress = (addr: string, maxMaskLength = 5, displayChar = "."): string => {
    if (!addr) return ""
    const lengthEachEnd = 5

    if (addr.startsWith("0x")) {
        return (
            addr.slice(0, 2 + lengthEachEnd) +
            displayChar.repeat(maxMaskLength) +
            addr.slice(addr.length - lengthEachEnd)
        )
    } else {
        return (
            addr.slice(0, lengthEachEnd) + displayChar.repeat(maxMaskLength) + addr.slice(addr.length - lengthEachEnd)
        )
    }
}

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: string): string | false {
    try {
        return getAddress(value)
    } catch {
        return false
    }
}
