import { PayloadAction, createSlice, nanoid } from "@reduxjs/toolkit"

import { HttpMethod, HttpMode, RequestAttribute } from "./types"

const constructNewURL = (prevURL: string, params: Array<RequestAttribute>) => {
    let nextURL = prevURL
    const search = new URL(prevURL).search
    if (search) {
        nextURL = nextURL.replace(search, "")
    }

    const query = new URLSearchParams()
    params.forEach((param) => {
        query.append(param.key, param.value)
    })

    const queryString = query.toString()
    if (queryString) {
        return `${nextURL}?${queryString}`
    }
    return nextURL
}

interface IState {
    mode: HttpMode
    method: HttpMethod
    url: string
    params: Array<RequestAttribute>
    headers: Array<RequestAttribute>
    body: string

    withAuth: boolean

    resp: object

    isLoading: boolean
    accessToken: string
}

const initialState: IState = {
    isLoading: false,
    mode: HttpMode.WITH_AUTH,
    method: HttpMethod.GET,
    url: `${process.env.REACT_APP_API_V2_URL}/v1/groups`,
    params: [],
    headers: [],
    body: "",
    withAuth: true,
    resp: {},
    accessToken: "",
}

const debuggerSlice = createSlice({
    name: "debugger",
    initialState,
    reducers: {
        setURL: (state, action: PayloadAction<string>) => {
            const url = action.payload
            state.url = url
            state.params = []

            try {
                const params = [...new URL(url).searchParams]
                params.forEach(([key, value]) => {
                    state.params.push({
                        id: nanoid(),
                        key,
                        value: String(value ?? ""),
                    })
                })
            } catch (e) {
                // empty
            }
        },
        setToken: (state, action: PayloadAction<string>) => {
            state.accessToken = action.payload
        },
        addParam: (state) => {
            if (state.params.length > 0) {
                const lastParam = state.params[state.params.length - 1]
                if (!lastParam.key && !lastParam.value) {
                    return
                }
            }

            const id = nanoid()
            state.params.push({
                id,
                key: "",
                value: "",
            })
        },
        removeParam: (state, action: PayloadAction<string>) => {
            const index = state.params.findIndex((param) => param.id === action.payload)
            if (index === -1) {
                return
            }
            state.params.splice(index, 1)
            state.url = constructNewURL(state.url, state.params)
        },
        updateParamKey: (state, action: PayloadAction<{ id: string; key: string }>) => {
            const updated = action.payload
            const index = state.params.findIndex((param) => param.id === updated.id)
            if (index === -1) {
                return
            }

            const param = state.params[index]
            param.key = updated.key

            state.url = constructNewURL(state.url, state.params)
        },
        updateParamValue: (state, action: PayloadAction<{ id: string; value: string }>) => {
            const updated = action.payload
            const index = state.params.findIndex((param) => param.id === updated.id)
            if (index === -1) {
                return
            }

            const param = state.params[index]
            param.value = updated.value

            state.url = constructNewURL(state.url, state.params)
        },

        addHeader: (state) => {
            if (state.headers.length > 0) {
                const lastHeader = state.headers[state.headers.length - 1]
                if (!lastHeader.key && !lastHeader.value) {
                    return
                }
            }

            const id = nanoid()
            state.headers.push({
                id,
                key: "",
                value: "",
            })
        },
        removeHeader: (state, action: PayloadAction<string>) => {
            const index = state.headers.findIndex((header) => header.id === action.payload)
            if (index === -1) {
                return
            }
            state.headers.splice(index, 1)
            state.url = constructNewURL(state.url, state.headers)
        },
        updateHeaderKey: (state, action: PayloadAction<{ id: string; key: string }>) => {
            const updated = action.payload
            const index = state.headers.findIndex((header) => header.id === updated.id)
            if (index === -1) {
                return
            }

            const header = state.headers[index]
            header.key = updated.key
        },
        updateHeaderValue: (state, action: PayloadAction<{ id: string; value: string }>) => {
            const updated = action.payload
            const index = state.headers.findIndex((header) => header.id === updated.id)
            if (index === -1) {
                return
            }

            const header = state.headers[index]
            header.value = updated.value
        },

        changeMode: (state, action: PayloadAction<HttpMode>) => {
            state.mode = action.payload
        },

        changeMethod: (state, action: PayloadAction<HttpMethod>) => {
            state.method = action.payload
        },

        makeRequest: (state) => {
            state.isLoading = true
            state.resp = initialState.resp
        },
        makeRequestDone: (state, action: PayloadAction<object>) => {
            state.resp = action.payload
            state.isLoading = false
        },
        clearResponse: (state) => {
            state.resp = initialState.resp
        },
    },
})

export default debuggerSlice
