import { ForkEffect, call, put, select, takeLatest } from "redux-saga/effects"

import tokenApi from "core/apis/token"
import { toast } from "core/redux/toast.slice"
import { TokenParams } from "core/types/token"

import tokenSlice from "./slice"

function* getTokensSaga({ payload }: { payload: TokenParams }) {
    try {
        const response = yield call(tokenApi.getTokens, payload)
        if (!response) throw response
        yield put(tokenSlice.actions.getTokensSuccess(response))
    } catch (error) {
        yield put(tokenSlice.actions.getTokensFailure())
        yield put(
            toast({
                description: `Error: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

function* putTokenSaga({ payload }) {
    try {
        yield call(tokenApi.putToken, payload)
        yield put(tokenSlice.actions.putTokenSuccess())
        yield put(
            toast({
                description: "Updated token successfully",
                status: "success",
            })
        )
        const prevParams = yield select((state) => state.tokenSlice.tokenParams)
        yield getTokensSaga({ payload: prevParams })
    } catch (error) {
        yield put(tokenSlice.actions.putTokenFailure())
        yield put(
            toast({
                description: `Error: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

export default function* tokenSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(tokenSlice.actions.getTokensRequest, getTokensSaga)
    yield takeLatest(tokenSlice.actions.putTokenRequest, putTokenSaga)
}
