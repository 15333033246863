import { Flex, Input } from "@chakra-ui/react"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import withPageLayout from "components/withPageLayout"
import { RootState } from "core/redux/reducer"
import debuggerSlice from "pages/Debugger/slice"

import ComponentTabs from "./ComponentTabs"
import MethodSelector from "./MethodSelector"
import ModeSelector from "./ModeSelector"
import ResponsePanel from "./ResponsePanel"
import SendButton from "./SendButton"

const DebuggerPage: React.FC = () => {
    const { url } = useSelector((state: RootState) => state.debugger)
    const dispatch = useDispatch()

    const setURL = (url: string) => {
        dispatch(debuggerSlice.actions.setURL(url))
    }
    return (
        <Flex height="100%" flexDirection="column" gap="16px" padding="32px" width="100%">
            <Flex alignItems="center" width="100%" gap="16px">
                <Flex alignItems="center" flex="1 1 100%" gap="1px">
                    <ModeSelector />
                    <MethodSelector />
                    <Input value={url} onChange={(e) => setURL(e.target.value)} />
                </Flex>

                <SendButton />
            </Flex>
            <ComponentTabs />
            <ResponsePanel />
        </Flex>
    )
}

export default withPageLayout(DebuggerPage)
