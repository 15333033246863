import { Checkbox } from "@chakra-ui/react"
import React from "react"
import { Cell, TableInstance } from "react-table"

import { TableColumn } from "../model"

const ID = "selection"

interface CheckboxOptions {
    hidden?: boolean
    disabled?: boolean
}

const CheckboxHeader: React.FC<TableInstance> = ({ toggleRowSelected, selectedFlatRows, rows }) => {
    const validRows = rows.filter(({ original }) => {
        const { hidden, disabled }: CheckboxOptions = original
        return !(hidden || disabled)
    })

    const checked = validRows.length && selectedFlatRows.length === validRows.length
    const indeterminate = selectedFlatRows.length > 0 && selectedFlatRows.length < validRows.length

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked
        const validRows = rows.filter(({ original }) => {
            const { hidden, disabled }: CheckboxOptions = original
            return !(hidden || disabled)
        })

        validRows.forEach(({ id }) => {
            toggleRowSelected(id, isChecked)
        })
    }

    return (
        <Checkbox
            colorScheme="teal"
            checked={checked}
            onChange={handleChange}
            isIndeterminate={indeterminate}
            disabled={!validRows.length}
        />
    )
}

const CheckboxCell: React.FC<Cell> = ({ row }) => {
    const { original, getToggleRowSelectedProps } = row
    const { onChange, checked } = getToggleRowSelectedProps()
    const { hidden, disabled }: CheckboxOptions = original

    return !hidden ? <Checkbox colorScheme="teal" onChange={onChange} checked={checked} disabled={disabled} /> : null
}

const CheckboxColumn: TableColumn<{}> = {
    accessor: ID,
    Header: CheckboxHeader,
    Cell: CheckboxCell,
    align: "center",
    sticky: "left",
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    disableResizing: true,
}

export default CheckboxColumn
