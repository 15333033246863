import { EditIcon, ExternalLinkIcon } from "@chakra-ui/icons"
import { Flex, IconButton, Link } from "@chakra-ui/react"
import { useDispatch } from "react-redux"

import { Asset } from "core/types/asset"

import assetSlice from "./slice"

interface Props {
    value: number
    row: {
        original: Asset
    }
}

const ActionCell: React.FC<Props> = ({ value, row: { original } }) => {
    const dispatch = useDispatch()

    const handleClickEditButton = () => {
        dispatch(assetSlice.actions.setSelectedAssetID(value))
    }

    return (
        <Flex columnGap="4px">
            <IconButton
                minWidth="32px"
                width="32px"
                height="32px"
                _hover={{
                    bgColor: "gray.200",
                }}
                _focus={{
                    outline: "none",
                }}
                variant="ghost"
                onClick={handleClickEditButton}
                aria-label="Edit group"
                icon={<EditIcon />}
            />

            {!!original.cmc_slug && (
                <Link isExternal href={"https://coinmarketcap.com/currencies/" + original.cmc_slug}>
                    <IconButton
                        minWidth="32px"
                        width="32px"
                        height="32px"
                        _hover={{
                            bgColor: "gray.200",
                        }}
                        _focus={{
                            outline: "none",
                        }}
                        variant="ghost"
                        aria-label="Edit group"
                        icon={<ExternalLinkIcon />}
                    />
                </Link>
            )}
        </Flex>
    )
}

export default ActionCell
