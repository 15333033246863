import { Button, Flex, Text } from "@chakra-ui/react"

import { ReactComponent as DataErrorSVG } from "assets/im_192empty_listing.svg"
import { clearCache } from "components/UserDrawer"

const ErrorPage: React.FC = () => {
    const handleClickClear = () => {
        clearCache()
    }

    const handleClickRefresh = () => {
        window.location.reload()
    }

    return (
        <Flex width="100vw" height="100vh" justifyContent="center" alignItems="center" direction="column">
            <DataErrorSVG />
            <Text as="span">Something went wrong. Try clearing your localStorage and refresh</Text>
            <Flex mt="8px" columnGap="8px">
                <Button size="sm" width="120px" colorScheme="teal" variant="outline" onClick={handleClickClear}>
                    Clear
                </Button>
                <Button size="sm" width="120px" colorScheme="teal" onClick={handleClickRefresh}>
                    Refresh
                </Button>
            </Flex>
        </Flex>
    )
}

export default ErrorPage
