import { Box, Button, Flex } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"

import balanceSlice from "../slice"
import BalanceSelect from "./BalanceSelect"
import ChainSelect from "./ChainSelect"
import DateSelect from "./DateSelect"
import ExportButton, { ExportType } from "./ExportButton"
import SettingsButton from "./SettingsButton"
import WalletSelect from "./WalletSelect"

const UtilityBar: React.FC = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector((state: RootState) => state.balance.isLoading)
    const fetchBalances = () => {
        dispatch(balanceSlice.actions.getBalancesAndRatesRequest())
    }

    return (
        <Box width="100%" d="flex" justifyContent="space-between">
            <Flex columnGap="8px">
                <WalletSelect />
                <BalanceSelect />
                <ChainSelect />
                <DateSelect />
            </Flex>

            <Flex ml="8px" columnGap="16px">
                <SettingsButton />
                <ExportButton type={ExportType.BALANCE} />
                <Button
                    width="100px"
                    isLoading={isLoading}
                    borderRadius="4px"
                    size="sm"
                    colorScheme="teal"
                    onClick={fetchBalances}
                >
                    Apply
                </Button>
            </Flex>
        </Box>
    )
}

export default UtilityBar
