import { useSelector } from "react-redux"

import { SearchResultDefault } from "core/redux/common.slice"
import { RootState } from "core/redux/reducer"

const useSearchData = () => {
    const results = useSelector((state: RootState) => state?.common?.searchResults)
    return results || SearchResultDefault
}
export const useIsSearchingData = () => {
    return useSelector((state: RootState) => state?.common?.searching)
}

export default useSearchData
