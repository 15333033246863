import { Box } from "@chakra-ui/react"

import FilterButton from "./FilterButton"
import SearchBar from "./SearchBar"

const UtilityBar = () => {
    return (
        <Box width="100%" d="flex" columnGap="16px">
            <SearchBar />
            <FilterButton />
        </Box>
    )
}

export default UtilityBar
