import { InfoIcon } from "@chakra-ui/icons"
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Link,
    Text,
} from "@chakra-ui/react"
import { ReactNode, useCallback, useEffect } from "react"
import { FormProvider } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useDebounce, usePrevious } from "react-use"

import cmcAPI from "core/apis/cmc"
import { RootState } from "core/redux/reducer"
import { AssetMapping, AssetPostPayload, RawAsset } from "core/types/asset"

import CreateOrEditAssetForm from "./CreateOrEditAssetForm"
import useCreateAssetForm from "./CreateOrEditAssetForm/useCreateAssetForm"
import assetSlice from "./slice"

const CreateAsset = ({
    children,
    onDismiss,
    isOpen,
    requiredAssetMapping,
    onSaveSuccess,
}: {
    children?: ReactNode
    onDismiss?: () => void
    onSaveSuccess?: () => void
    isOpen?: boolean
    requiredAssetMapping?: boolean
}) => {
    const dispatch = useDispatch()

    const methods = useCreateAssetForm()
    const { reset, watch, clearErrors, getDefaultFormValues, setError } = methods
    const handleSubmitSuccess = useCallback(() => {
        onDismiss?.()
        onSaveSuccess?.()
    }, [onDismiss, onSaveSuccess])

    const isSubmitting = useSelector((state: RootState) => state.asset.isSubmitting)
    const prevIsSubmitSuccess = usePrevious(isSubmitting)
    const isSubmitSuccess = prevIsSubmitSuccess && !isSubmitting

    useEffect(() => {
        if (isSubmitSuccess) handleSubmitSuccess()
    }, [isSubmitSuccess, handleSubmitSuccess])

    //@ts-ignore
    const cmcSlug = watch("cmc_slug")

    // find asset 1sec after user's done typing
    // first get basic information such as name, symbol, ...
    // then get CEX mappings
    useDebounce(
        async () => {
            if (!isOpen) {
                return
            }
            if (!cmcSlug) {
                clearErrors("cmc_slug")
                return
            }

            let rawAsset: RawAsset = null
            const assetMappings: AssetMapping[] = []
            try {
                const resp = await cmcAPI.getAssetBySlug(cmcSlug)
                rawAsset = resp?.assets?.[0]
            } catch (error) {
                setError("cmc_slug", { type: "custom", message: "Not found this asset" })
            }

            reset({
                name: rawAsset.name,
                symbol: rawAsset.symbol,
                logo_url: rawAsset.logo_url,
                cmc_id: rawAsset.cmc_id,
                cmc_slug: rawAsset.cmc_slug,
                cex_asset_mapping: assetMappings.reduce((acc, m) => {
                    acc[m.exchange_id] = m.symbol
                    return acc
                }, {}),
            })
        },
        1000,
        [cmcSlug]
    )

    const handleClickSubmit = () => {
        const values = methods.getValues()
        const cex_asset_mapping = Object.entries(values.cex_asset_mapping)
            .map(([rawID, symbol]) => ({
                exchange_id: parseInt(rawID, 10),
                symbol,
            }))
            .filter((mapping) => mapping.symbol)

        if (requiredAssetMapping && !cex_asset_mapping.length) {
            setError("cex_asset_mapping", { type: "custom", message: "Cex asset mapping is required" })
            return
        }
        const payload: AssetPostPayload = {
            name: values.name,
            symbol: values.symbol,
            logo_url: values.logo_url,
            is_active: values.is_active,
            cmc_id: values.cmc_id,
            cmc_slug: values.cmc_slug,
            cex_asset_mapping,
        }

        dispatch(assetSlice.actions.postAssetRequest(payload))
    }

    useEffect(() => {
        if (!isOpen) {
            // can't just do reset()
            reset(getDefaultFormValues())
        }
    }, [getDefaultFormValues, isOpen, reset])

    return (
        <>
            {children}

            <Drawer size="md" isOpen={isOpen} placement="right" onClose={onDismiss}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader position="sticky" top="0">
                        Create new asset
                    </DrawerHeader>

                    <DrawerBody paddingBottom="16px">
                        <Flex
                            bgColor="blackAlpha.100"
                            padding="12px"
                            borderRadius="4px"
                            columnGap="4px"
                            marginBottom="16px"
                        >
                            <Flex flex="0 0 24px" height="24px" justifyContent="center" alignItems="center">
                                <InfoIcon color="gray.500" />
                            </Flex>
                            <Flex flex="1 1" fontSize="sm" color="gray.700" direction="column">
                                <Text as="span">Find the asset on CoinMarketCap first, then get its slug.</Text>
                                <Text as="span">
                                    <Text as="span">E.g. slug is</Text>
                                    <Text as="span" fontWeight="bold" ml="4px">
                                        bitcoin
                                    </Text>
                                    <Text as="span">, if the url is:</Text>
                                </Text>
                                <Link href="https://coinmarketcap.com/currencies/bitcoin/" isExternal>
                                    <Text as="span" decoration="underline">
                                        https://coinmarketcap.com/currencies/bitcoin/
                                    </Text>
                                </Link>
                                <Text as="span">
                                    Enter the slug and wait for a few seconds, if it is valid, all fields will then be
                                    filled automatically.
                                </Text>
                            </Flex>
                        </Flex>

                        <FormProvider {...methods}>
                            <CreateOrEditAssetForm isCreate requiredAssetMapping={requiredAssetMapping} />
                        </FormProvider>
                    </DrawerBody>

                    <DrawerFooter position="sticky" bottom="0" borderTop="1px" borderColor="gray.200">
                        <Button variant="outline" mr={3} onClick={onDismiss} disabled={isSubmitting}>
                            Cancel
                        </Button>
                        <Button
                            disabled={!methods.formState.isDirty}
                            isLoading={isSubmitting}
                            loadingText="Submitting"
                            colorScheme="teal"
                            onClick={methods.handleSubmit(handleClickSubmit)}
                        >
                            Submit
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default CreateAsset
