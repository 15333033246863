import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons"
import { Box, IconButton, Link, Text } from "@chakra-ui/react"
import { CheckCircle } from "react-feather"
import { Row } from "react-table"
import { maskAddress } from "utils/address"
import getTokenExplorerURLByChainID from "utils/getTokenExplorerURLByChainID"

import useCopyClipboard from "core/hooks/useCopyClipboard"
import { Token } from "core/types"

interface IProps {
    value?: string
    row: Row<Token>
}

export const CopyAddressIcon = ({ address }: { address: string }) => {
    const [copied, copy] = useCopyClipboard()
    const handleClickCopyButton = () => {
        copy(address)
    }
    if (!address) return null
    return (
        <IconButton
            minWidth="24px"
            width="24px"
            height="24px"
            color="gray.600"
            bgColor="transparent"
            _hover={{
                color: "gray.900",
            }}
            _focus={{
                outline: "none",
            }}
            variant="ghost"
            aria-label="Copy address"
            icon={copied ? <CheckCircle size={16} /> : <CopyIcon />}
            onClick={copied ? undefined : handleClickCopyButton}
        />
    )
}

const AddressCell: React.FC<IProps> = (props) => {
    const { address, chain_id } = props.row.original

    const tokenURL = getTokenExplorerURLByChainID(address, chain_id)

    return (
        <Box
            height="100%"
            d="flex"
            alignItems="center"
            overflow="hidden"
            whiteSpace="nowrap"
            _hover={{
                ">.buttons": {
                    display: "block",
                },
            }}
        >
            <Box flex="1 1" isTruncated>
                <Text as="span" fontSize="sm">
                    {maskAddress(props.value)}
                </Text>
            </Box>

            <Box ml="4px" display="none" flex="0 0 48px" className="buttons">
                <CopyAddressIcon address={address} />

                <Link isExternal href={tokenURL}>
                    <IconButton
                        minWidth="24px"
                        width="24px"
                        height="24px"
                        color="gray.600"
                        bgColor="transparent"
                        _hover={{
                            color: "gray.900",
                        }}
                        _focus={{
                            outline: "none",
                        }}
                        variant="ghost"
                        aria-label="Open explorer url"
                        icon={<ExternalLinkIcon />}
                    />
                </Link>
            </Box>
        </Box>
    )
}

export default AddressCell
