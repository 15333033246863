import {
    Box,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    TableContainer,
    Text,
    theme,
} from "@chakra-ui/react"
import { useMemo } from "react"
import styled from "styled-components"

import { AssetBalance, BalanceResponseV2, CexAccountBalance, DefiWalletBalance } from "core/types/balance"
import { formatFloatUsd } from "pages/Balance/utils"
import { getDiffBalanceAmount } from "pages/Comparison/BalanceTable"
import { color } from "theme"

const ItemWrapper = styled.div`
    display: grid;
    grid-template-columns: 0.3fr 1fr 1fr 1fr;
    padding: 10px 10px;
`

const formatNodata = (value: null | string | number) => {
    return value === null ? <span style={{ color: "orange" }}>No data</span> : formatFloatUsd(value)
}

const getDiffColor = (totalDiff) =>
    totalDiff === "$0" ? "unset" : totalDiff?.includes?.("-") ? theme.colors.red[500] : "teal"

const AssetItem = ({
    asset: { symbol, name, logo_url, amount_usd2, amount_usd1 },
}: {
    asset: { symbol: string; name: string; logo_url: string; amount_usd2: number; amount_usd1: number }
}) => {
    const totalDiff = formatNodata(
        amount_usd1 === null || amount_usd2 === null ? null : getDiffBalanceAmount(amount_usd2, amount_usd1)
    )
    return (
        <ItemWrapper style={{ border: `0.5px solid ${borderColor}` }}>
            <Flex gap="4px" align="center">
                {logo_url ? (
                    <Image boxSize="24px" src={logo_url} alt={name} />
                ) : (
                    <Box height="24px" flex="0 0 24px" borderRadius="100%" bgColor="blackAlpha.200" />
                )}
                <Text as="span" fontWeight={"500"} color={"teal"}>
                    {symbol}
                </Text>
            </Flex>
            <Text as="span" fontWeight={"400"} style={{ textAlign: "right" }}>
                {formatNodata(amount_usd1)}
            </Text>
            <Text as="span" fontWeight={"400"} style={{ textAlign: "right" }}>
                {formatNodata(amount_usd2)}
            </Text>
            <Text as="span" fontWeight={"400"} style={{ textAlign: "right", color: getDiffColor(totalDiff) }}>
                {totalDiff}
            </Text>
        </ItemWrapper>
    )
}
const borderColor = theme.colors.gray[200]
export default function DetailAssetModal({
    isOpen,
    onClose,
    account,
}: {
    onClose: () => void
    isOpen: boolean
    account: BalanceResponseV2[]
}) {
    const balance1 = account?.[0]?.balances?.[0]
    const balance2 = account?.[1]?.balances?.[0]

    const data = useMemo(() => {
        if (!balance1 || !balance2) return []
        const data = []

        const getRowData = (asset1: AssetBalance, asset2: AssetBalance) => ({
            ...asset1,
            ...asset2,
            amount_usd1: asset1?.amount_usd ?? null,
            amount_usd2: asset2?.amount_usd ?? null,
        })
        balance1.assets.forEach((asset1) => {
            const asset2 = balance2.assets.find((el) => el.id === asset1.id)
            if (!asset2) console.error("Not found asset 2", asset1)
            data.push(getRowData(asset1, asset2))
        })
        balance2.assets.forEach((asset2) => {
            if (data.some((el) => el.id === asset2.id)) {
                return
            }
            const asset1 = balance1.assets.find((el) => el.id === asset2.id)
            if (!asset1) console.error("Not found asset 1", asset2)
            data.push(getRowData(asset1, asset2))
        })
        return data
    }, [balance1, balance2])

    if (!account?.length || !balance1 || !balance2) return null

    if (balance1.assets.length !== balance2.assets.length)
        console.error("Dimension is mismatch", balance1.assets, balance2.assets)

    const { wallet_name, total_amount_usd: total_amount_usd1 } = balance1 as DefiWalletBalance
    const { account_name, account_id } = balance1 as CexAccountBalance

    const { total_amount_usd: total_amount_usd2 } = balance2 as DefiWalletBalance
    const totalDiff = formatFloatUsd(getDiffBalanceAmount(total_amount_usd2, total_amount_usd1))
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"4xl"} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader paddingBottom={0}>
                    {account_id ? `Cex` : `Wallet`} Account: {wallet_name || account_name}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <TableContainer>
                        <ItemWrapper
                            style={{
                                border: `1px solid ${borderColor}`,
                                borderBottom: "none",
                                background: color.backgroundDark,
                                fontSize: "14px",
                            }}
                        >
                            <Text style={{ textAlign: "left", fontWeight: "500" }} paddingLeft={0}>
                                Token{" "}
                            </Text>
                            <Text style={{ fontWeight: "500", textAlign: "right" }}>USD Amount 1</Text>
                            <Text style={{ fontWeight: "500", textAlign: "right" }}>USD Amount 2</Text>
                            <Text style={{ fontWeight: "500", textAlign: "right" }}>Diff</Text>
                        </ItemWrapper>
                        <div
                            style={{
                                width: "100%",
                                height: "auto",
                                maxHeight: "70vh",
                                overflowY: "auto",
                                border: `1px solid ${borderColor}`,
                            }}
                        >
                            {data.map((asset1) => {
                                return <AssetItem key={asset1.id} asset={asset1} />
                            })}
                        </div>
                        <ItemWrapper
                            style={{
                                border: `1px solid ${borderColor}`,
                                borderTop: "none",
                            }}
                        >
                            <b>Total USD:</b>
                            <b style={{ textAlign: "right" }}>{formatFloatUsd(total_amount_usd1)}</b>
                            <b style={{ textAlign: "right" }}>{formatFloatUsd(total_amount_usd2)}</b>
                            <b
                                style={{
                                    textAlign: "right",
                                    color: getDiffColor(totalDiff),
                                }}
                            >
                                {totalDiff}
                            </b>
                        </ItemWrapper>
                    </TableContainer>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
