import { CompactGroup } from "./group"

export enum WalletType {
    EVM = "evm-based",
    BTC = "btc",
}

export type Wallet = {
    id: number
    name: string
    address: string
    description: string
    type: WalletType
    groups: CompactGroup[]
}

export type CompactWallet = {
    id: number
    address: string
    name: string
}

export type WalletPostPayload = {
    id?: number
    address?: string
    name: string
    type: string
    description: string
    group_ids: number[]
}
