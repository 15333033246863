import { Select } from "@chakra-ui/react"
import React, { ChangeEvent } from "react"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"

import debuggerSlice from "./slice"
import { HttpMode } from "./types"

const ModeSelector: React.FC = () => {
    const dispatch = useDispatch()
    const mode = useSelector((state: RootState) => state.debugger.mode)

    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        dispatch(debuggerSlice.actions.changeMode(e.target.value as HttpMode))
    }
    return (
        <Select size="sm" width="140px" value={mode} onChange={handleChange}>
            <option value={HttpMode.WITH_AUTH}>{HttpMode.WITH_AUTH}</option>
            <option value={HttpMode.NO_AUTH}>{HttpMode.NO_AUTH}</option>
        </Select>
    )
}

export default ModeSelector
