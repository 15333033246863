import {
    Badge,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react"
import styled from "styled-components"

import { Token } from "core/types"
import { AccountType, BalanceResponseV2, CexAccountBalance, DefiWalletBalance } from "core/types/balance"
import Balance from "pages/Balance/BalanceTable/DetailAsset/Balance"
import TokenWithoutAsset from "pages/Balance/BalanceTable/DetailAsset/TokenWithoutAsset"

const StyledTab = styled(Tab)`
    &:focus {
        box-shadow: none !important;
    }
`

export const buttonLabelAddAsset = "Add asset"
export const buttonLabelConnect = "Connect"

export enum TabTypes {
    BALANCE,
    TOKENS,
}
export default function DetailAssetModal({
    isOpen,
    onClose,
    account,
    defaultTab,
    refreshBalance,
    loading,
}: {
    onClose: () => void
    isOpen: boolean
    account: BalanceResponseV2
    refreshBalance: () => void
    defaultTab: TabTypes
    loading: boolean
}) {
    const [selectedToken, setSelectedToken] = useState<Token>()

    const [tab, setTab] = useState(TabTypes.BALANCE)

    useEffect(() => {
        setTab(defaultTab)
    }, [defaultTab])

    const balance = account?.balances?.[0]
    const isCexAccount = balance?.type === AccountType.CEX_ACCOUNT

    const onSelectToken = useCallback((token: Token) => {
        setSelectedToken(token)
    }, [])

    const onDismissEditToken = useCallback(() => {
        onSelectToken(undefined)
    }, [onSelectToken])

    if (!account) return null
    const { wallet_name, total_amount_usd, assets = [], tokens_without_asset = [] } = balance as DefiWalletBalance
    const { account_name, account_id } = balance as CexAccountBalance

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"4xl"} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader paddingBottom={0}>
                    {account_id ? `Cex` : `Wallet`} Account: {wallet_name || account_name}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Tabs variant="enclosed" index={tab} onChange={setTab}>
                        <TabList>
                            <StyledTab>Balance {assets.length > 0 && <Badge>{assets.length}</Badge>}</StyledTab>
                            <StyledTab>
                                Token without assets
                                <Badge>{tokens_without_asset.length}</Badge>
                            </StyledTab>
                        </TabList>

                        <TabPanels>
                            <TabPanel padding={0}>
                                <Balance {...{ total_amount_usd, assets }} />
                            </TabPanel>
                            <TabPanel paddingLeft={0}>
                                <TokenWithoutAsset
                                    {...{
                                        loading,
                                        isCexAccount,
                                        onSelectToken,
                                        selectedToken,
                                        onDismissEditToken,
                                        tokens_without_asset,
                                        onSaveSuccess: refreshBalance,
                                    }}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
