import { Badge, Stack } from "@chakra-ui/react"

import { NullCell } from "components/Table/components/NullCell"

interface IProps {
    value?: string[]
}

const ChildCEXAccountsCell: React.FC<IProps> = ({ value }) => {
    if (!value || value.length === 0) {
        return <NullCell />
    }

    return (
        <Stack direction="row" isTruncated>
            {value.map((account) => (
                <Badge variant="outline" key={account}>
                    {account}
                </Badge>
            ))}
        </Stack>
    )
}

export default ChildCEXAccountsCell
