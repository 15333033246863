import { ExternalLinkIcon, InfoIcon, WarningTwoIcon } from "@chakra-ui/icons"
import {
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    IconButton,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    Link,
    Switch,
    Text,
    Tooltip,
} from "@chakra-ui/react"
import { Controller } from "react-hook-form"
import { useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"
import { Token } from "core/types"
import SimpleTokenLink from "pages/Asset/SimpleTokenLink"

export type CreateOrEditAssetFormValues = {
    id?: number
    name: string
    symbol: string
    logo_url?: string
    cmc_id?: string
    cmc_slug?: string
    is_active: boolean
    cex_asset_mapping: Record<string, string>
    tokens?: Token[]
}

type Props = {
    isCreate?: boolean
    requiredAssetMapping?: boolean
}
const CreateOrEditAssetForm: React.FC<Props> = ({ isCreate, requiredAssetMapping }) => {
    const exchanges = useSelector((state: RootState) => state.asset.exchanges)

    return (
        <Flex direction="column" width="100%" rowGap="24px">
            <Controller
                name="id"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => {
                    // only render this if ID exists, which means in Editing mode, not Creating
                    if (value) {
                        return (
                            <FormControl isInvalid={invalid}>
                                <FormLabel>Asset ID</FormLabel>
                                <Input
                                    ref={ref}
                                    onBlur={onBlur}
                                    name={name}
                                    placeholder="Asset ID"
                                    onChange={onChange}
                                    autoComplete="off"
                                    value={value || ""}
                                    readOnly
                                    disabled
                                />
                            </FormControl>
                        )
                    }
                    return null
                }}
            />

            <Controller
                name="cmc_slug"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
                    <>
                        <FormControl isInvalid={invalid}>
                            <FormLabel>
                                CoinMarketCap Slug <WarningTwoIcon width="12px" color="yellow.500" />
                            </FormLabel>
                            <InputGroup size="sm">
                                <Input
                                    size="sm"
                                    ref={ref}
                                    onBlur={onBlur}
                                    name={name}
                                    placeholder="e.g. kyber-network-crystal-v2"
                                    onChange={onChange}
                                    autoComplete="off"
                                    value={value || ""}
                                />
                                <InputRightElement width="32px">
                                    <Link
                                        title="go to CoinMarketCap currency"
                                        isExternal
                                        href={"https://coinmarketcap.com/currencies/" + value}
                                    >
                                        <IconButton
                                            minWidth="32px"
                                            width="32px"
                                            height="32px"
                                            color="gray.400"
                                            _hover={{
                                                bgColor: "transparent",
                                                color: "gray.600",
                                            }}
                                            _active={{
                                                bgColor: "transparent",
                                                color: "gray.800",
                                            }}
                                            _focus={{
                                                outline: "none",
                                            }}
                                            variant="ghost"
                                            aria-label="go to CoinMarketCap currency"
                                            icon={<ExternalLinkIcon />}
                                        />
                                    </Link>
                                </InputRightElement>
                            </InputGroup>

                            {invalid ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                        </FormControl>
                    </>
                )}
            />

            <Controller
                name="cmc_id"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
                    <FormControl isInvalid={invalid}>
                        <FormLabel>CoinMarketCap ID </FormLabel>
                        <Input
                            size="sm"
                            ref={ref}
                            onBlur={onBlur}
                            name={name}
                            placeholder="e.g. 9444"
                            onChange={onChange}
                            autoComplete="off"
                            value={value || ""}
                        />
                        {invalid ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                    </FormControl>
                )}
            />

            <Flex style={{ gap: "6px" }}>
                <Controller
                    name="name"
                    rules={{
                        required: "Asset name is required",
                    }}
                    render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
                        <FormControl isInvalid={invalid} isRequired>
                            <FormLabel>Name</FormLabel>
                            <Input
                                ref={ref}
                                onBlur={onBlur}
                                name={name}
                                placeholder="e.g. Kyber Network Crystal v2"
                                onChange={onChange}
                                autoComplete="off"
                                value={value || ""}
                            />
                            {invalid ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                        </FormControl>
                    )}
                />

                <Controller
                    name="symbol"
                    rules={{
                        required: "Asset symbol is required",
                    }}
                    render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
                        <FormControl isInvalid={invalid} isRequired>
                            <FormLabel>Symbol</FormLabel>
                            <Input
                                ref={ref}
                                onBlur={onBlur}
                                name={name}
                                placeholder="e.g. KNC"
                                onChange={onChange}
                                autoComplete="off"
                                value={value || ""}
                            />
                            {invalid ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                        </FormControl>
                    )}
                />
            </Flex>

            <Controller
                name="logo_url"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
                    <FormControl isInvalid={invalid}>
                        <FormLabel>Logo URL</FormLabel>
                        <Input
                            size="sm"
                            ref={ref}
                            onBlur={onBlur}
                            name={name}
                            placeholder="e.g. https://s2.coinmarketcap.com/static/img/coins/64x64/9444.png"
                            onChange={onChange}
                            autoComplete="off"
                            value={value || ""}
                        />
                        {invalid ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                    </FormControl>
                )}
            />

            <Controller
                name="is_active"
                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                    <FormControl>
                        <FormLabel>Active</FormLabel>
                        <Switch
                            colorScheme="teal"
                            size="md"
                            name={name}
                            ref={ref}
                            onBlur={onBlur}
                            isChecked={value}
                            onChange={onChange}
                        />
                    </FormControl>
                )}
            />

            <FormControl>
                <FormLabel alignItems={"center"} display={"flex"} gap={"4px"}>
                    Mappings
                    <Tooltip
                        placement="top"
                        label="Map this asset to a symbol in an exchange. Please manually enter symbol for Perpetual markets"
                    >
                        <InfoIcon color="blue.500" width="12px" />
                    </Tooltip>
                    {requiredAssetMapping && <Text color={"red"}>*</Text>}
                </FormLabel>
                <Flex direction="column" rowGap="8px">
                    <Controller
                        name="cex_asset_mapping"
                        render={({ fieldState: { invalid, error } }) => {
                            return (
                                <Text fontSize={"14px"} color={"red"}>
                                    {invalid ? <div>{error.message}</div> : null}
                                </Text>
                            )
                        }}
                    />
                    {exchanges.map((cex) => {
                        return (
                            <Controller
                                key={cex.id}
                                name={`cex_asset_mapping.${cex.id}`}
                                render={({ field: { onChange, onBlur, value, name, ref } }) => (
                                    <InputGroup width="100%">
                                        <InputLeftElement
                                            height="32px"
                                            width="90px"
                                            paddingLeft="8px"
                                            pointerEvents="none"
                                            justifyContent="flex-start"
                                            borderRight="1px"
                                            borderColor="gray.300"
                                            fontSize="sm"
                                        >
                                            {cex.name?.split(" ")?.[0]}
                                        </InputLeftElement>

                                        <Input
                                            borderRadius="4px"
                                            width="100%"
                                            paddingLeft="98px"
                                            colorScheme="teal"
                                            size="sm"
                                            name={name}
                                            ref={ref}
                                            onBlur={onBlur}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    </InputGroup>
                                )}
                            />
                        )
                    })}
                </Flex>
            </FormControl>

            {!isCreate && (
                <FormControl>
                    <FormLabel>Connected Tokens</FormLabel>
                    <Flex as="ul" direction="column" rowGap="8px">
                        <Controller
                            name={`tokens`}
                            render={({ field: { value } }) => {
                                return (
                                    <Flex direction="column" rowGap="8px">
                                        {value?.map((token: Token) => {
                                            return <SimpleTokenLink token={token} key={token.id} />
                                        })}
                                    </Flex>
                                )
                            }}
                        />
                    </Flex>
                </FormControl>
            )}
        </Flex>
    )
}

export default CreateOrEditAssetForm
