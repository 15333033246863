import { Box } from "@chakra-ui/react"

import { HEIGHT } from "components/PageHeader"

const PageBody: React.FC = ({ children }) => {
    const height = `calc(100vh - ${HEIGHT}px)`
    return (
        <Box as="main" minHeight={height} height={height}>
            {children}
        </Box>
    )
}

export default PageBody
