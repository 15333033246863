import {
    Badge,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Text,
    useDisclosure,
} from "@chakra-ui/react"
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import styled from "styled-components"

import { EmptyArray } from "core/globalConstants"
import useSearchData from "core/hooks/useSugguestionData"
import commonSlice from "core/redux/common.slice"
import { RootState } from "core/redux/reducer"
import { SelectOption } from "core/types/select"
import { BalanceFilter, FilterAllDefault, FilterAllWallet } from "pages/Balance/model"

import balanceSlice from "../slice"

const ButtonSelectAll = ({
    onClick,
    children,
    active,
}: {
    onClick: () => void
    children: ReactNode
    active: boolean
}) => {
    return (
        <Button
            borderRadius="4px"
            size="sm"
            padding="0 8px"
            colorScheme={active ? "teal" : "black"}
            variant="outline"
            onClick={onClick}
        >
            {children}
        </Button>
    )
}

// TODO: Reuse base SourceSelect

const WalletSelect: React.FC<{ className?: string }> = ({ className }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()
    const { exchangeAccounts: cexAccounts, wallets, groups } = useSearchData()

    const filter = useSelector((state: RootState) => state.balance.filter)

    const [selectedWalletOptions, setSelectedWalletOptions] = useState<SelectOption[]>([])
    const [selectedGroupOptions, setSelectedGroupOptions] = useState<SelectOption[]>([])
    const [selectedCEXAccountOptions, setSelectedCEXAccountOptions] = useState<SelectOption[]>([])
    const [filterAll, setFilterAll] = useState<FilterAllWallet>(filter as FilterAllWallet)

    useEffect(() => {
        if (!isOpen) {
            setFilterAll(filter)
        }
    }, [isOpen, filter])

    const walletOptions = useMemo(() => {
        return wallets.map((wallet) => ({
            label: `${wallet.name} (${wallet.id})`,
            value: wallet.id,
        }))
    }, [wallets])

    const cexAccountOptions = useMemo(() => {
        return cexAccounts.map((account) => ({
            label: account.id,
            value: account.id,
        }))
    }, [cexAccounts])

    const groupOptions = useMemo(() => {
        return groups.map((group) => ({
            label: group.name,
            value: group.id,
        }))
    }, [groups])

    const handleSelectWallets = (values: SelectOption[]) => {
        setSelectedWalletOptions(values)
    }

    const handleSelectGroups = (values: SelectOption[]) => {
        setSelectedGroupOptions(values)
    }

    const handleSearchSelect = (name: string, value: string) => {
        dispatch(commonSlice.actions.setSearchQuery({ [name]: value }))
    }

    const handleSelectCEXAccounts = (values: SelectOption[]) => {
        setSelectedCEXAccountOptions(values)
    }
    const updateFilter = useCallback(
        (filter: Partial<BalanceFilter>) => {
            dispatch(balanceSlice.actions.setFilter(filter))
        },
        [dispatch]
    )

    const upDateFilterAll = (value) => {
        setFilterAll((prev) => ({ ...prev, ...value }))
    }

    const handleClickApply = () => {
        updateFilter({
            ...filterAll,
            groupIDs: selectedGroupOptions.map((option) => option.value),
            walletIDs: selectedWalletOptions.map((option) => option.value),
            cexAccountIDs: selectedCEXAccountOptions.map((option) => option.value),
        })
        onClose()
    }

    const handleClickSelectAllGroups = () => {
        upDateFilterAll({ all_group: !filterAll.all_group })
    }

    const handleClickSelectAllWallets = () => {
        upDateFilterAll({ all_wallet: !filterAll.all_wallet })
    }

    const handleClickSelectAllCEXAccounts = () => {
        upDateFilterAll({ all_account: !filterAll.all_account })
    }

    const handleClickSelectAllEVMWallets = () => {
        upDateFilterAll({ all_evm: !filterAll.all_evm })
    }

    const handleClickSelectAllBTCWallets = () => {
        upDateFilterAll({ all_btc: !filterAll.all_btc })
    }

    const handleClickClear = () => {
        setSelectedGroupOptions(EmptyArray)
        setSelectedWalletOptions(EmptyArray)
        setSelectedCEXAccountOptions(EmptyArray)
        upDateFilterAll(FilterAllDefault)
    }

    const totalFilter =
        filter.groupIDs.length +
        filter.walletIDs.length +
        filter.cexAccountIDs.length +
        Object.keys(FilterAllDefault).reduce((total, key) => total + (Number(filter[key]) || 0), 0)

    return (
        <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen} isLazy>
            <PopoverTrigger>
                <Box flex="0 0 100px">
                    <Button variant="outline" width="100%" borderRadius="4px" size="sm" padding="0 16px">
                        Source
                        <Badge ml="4px">{totalFilter}</Badge>
                    </Button>
                </Box>
            </PopoverTrigger>

            <PopoverContent
                width="480px"
                height="520px"
                overflow="auto"
                boxShadow="lg"
                _focus={{
                    outline: "none",
                }}
            >
                <PopoverHeader fontSize="md">Filter</PopoverHeader>
                <PopoverCloseButton />

                <PopoverBody
                    className={className}
                    height="100%"
                    padding="0"
                    display="flex"
                    flexDirection="column"
                    rowGap="16px"
                    alignItems="space-between"
                >
                    <Flex flex="1 1 100%" padding="16px 16px" flexDirection="column" rowGap="16px">
                        <Flex alignItems="center" gap="8px 8px" flexWrap="wrap">
                            <ButtonSelectAll onClick={handleClickSelectAllGroups} active={filterAll.all_group}>
                                All groups
                            </ButtonSelectAll>
                            <ButtonSelectAll onClick={handleClickSelectAllWallets} active={filterAll.all_wallet}>
                                All wallets
                            </ButtonSelectAll>
                            <ButtonSelectAll onClick={handleClickSelectAllCEXAccounts} active={filterAll.all_account}>
                                All CEX accounts
                            </ButtonSelectAll>
                            <ButtonSelectAll onClick={handleClickSelectAllEVMWallets} active={filterAll.all_evm}>
                                All EVM wallets
                            </ButtonSelectAll>
                            <ButtonSelectAll onClick={handleClickSelectAllBTCWallets} active={filterAll.all_btc}>
                                All BTC wallets
                            </ButtonSelectAll>
                        </Flex>

                        <FormControl>
                            <FormLabel>Groups</FormLabel>
                            <Select
                                maxMenuHeight={240}
                                className="selector"
                                classNamePrefix="selector"
                                value={selectedGroupOptions}
                                onChange={handleSelectGroups}
                                isClearable
                                isSearchable
                                isMulti
                                name="groups"
                                options={groupOptions}
                                placeholder="Select groups"
                                onInputChange={(value) => handleSearchSelect("groups", value)}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel>Wallets</FormLabel>
                            <Select
                                maxMenuHeight={240}
                                className="selector"
                                classNamePrefix="selector"
                                value={selectedWalletOptions}
                                onChange={handleSelectWallets}
                                isClearable
                                isSearchable
                                isMulti
                                name="wallets"
                                options={walletOptions}
                                placeholder="Select wallets"
                                onInputChange={(value) => handleSearchSelect("wallets", value)}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel>CEX Accounts</FormLabel>
                            <Select
                                maxMenuHeight={240}
                                className="selector"
                                classNamePrefix="selector"
                                value={selectedCEXAccountOptions}
                                onChange={handleSelectCEXAccounts}
                                isClearable
                                isSearchable
                                isMulti
                                name="groups"
                                options={cexAccountOptions}
                                placeholder="Select CEX accounts"
                                onInputChange={(value) => handleSearchSelect("exchangeAccounts", value)}
                            />
                        </FormControl>

                        <Text fontSize="sm" fontStyle="italic">
                            Forms above will result in a list of wallets and CEX accounts
                        </Text>
                    </Flex>

                    <Flex
                        flex="0 0"
                        borderTopWidth="1px"
                        bgColor="white"
                        padding="8px 16px"
                        w="100%"
                        justifyContent="space-between"
                        position="sticky"
                        bottom="0"
                        zIndex="1"
                    >
                        <Button
                            borderRadius="4px"
                            size="sm"
                            padding="0 16px"
                            colorScheme="teal"
                            variant="outline"
                            onClick={handleClickClear}
                        >
                            Clear
                        </Button>

                        <Button
                            borderRadius="4px"
                            size="sm"
                            padding="0 16px"
                            colorScheme="teal"
                            onClick={handleClickApply}
                        >
                            Apply
                        </Button>
                    </Flex>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export default styled(WalletSelect)`
    .selector {
        &__menu {
            z-index: 2;
        }
    }
`
