export enum HttpMode {
    WITH_AUTH = "With Auth",
    NO_AUTH = "No Auth",
}

export enum HttpMethod {
    "GET" = "GET",
    "POST" = "POST",
    "PUT" = "PUT",
    "DELETE" = "DELETE",
}

export type RequestAttribute = {
    id: string
    key: string
    value: string
}
