import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { DefaultPageSize } from "components/Paginator/consts"
import Table from "components/Table"
import { TableColumn } from "components/Table/model"
import { RootState } from "core/redux/reducer"
import { Token } from "core/types"
import { TokenParams } from "core/types/token"

import ActionCell from "./ActionCell"
import AddressCell from "./AddressCell"
import AssetCell from "./AssetCell"
import ChainCell from "./ChainCell"
import NativeCell from "./NativeCell"
import tokenSlice from "./slice"

const getColumns = (pageSize: number, pageIndex: number) => {
    const columns: TableColumn<Token & { index: number }>[] = [
        {
            Header: "#",
            accessor: (d) => d.index + pageSize * pageIndex,
            width: pageIndex * pageSize < 100 ? 32 : 60,
        },
        {
            Header: "Name",
            accessor: "name",
            sticky: "left",
        },
        {
            Header: "Symbol",
            accessor: "symbol",
            width: 80,
        },
        {
            Header: "Address",
            accessor: "address",
            Cell: AddressCell,
            width: 150,
        },
        {
            Header: "Native",
            accessor: "is_native",
            Cell: NativeCell,
            width: 70,
        },
        {
            Header: "Asset",
            accessor: (d) => d.asset?.id,
            Cell: AssetCell,
            width: 180,
        },
        {
            Header: "Chain",
            accessor: "chain_id",
            Cell: ChainCell,
        },
        {
            Header: "Decimals",
            accessor: "decimals",
            width: 80,
        },
        {
            Header: "Action",
            accessor: "id",
            Cell: ActionCell,
            width: 80,
            align: "center",
            sticky: "right",
        },
    ]
    return columns
}

const TokenTable: React.FC = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector((state: RootState) => state.tokenSlice.isLoading)
    const {
        tokens = [],
        pagination: { totalItems },
    } = useSelector((state: RootState) => state.tokenSlice.tokenResponse)

    const searchText = useSelector((state: RootState) => state.tokenSlice.searchText)
    const tokenFilter = useSelector((state: RootState) => state.tokenSlice.tokenFilter)

    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(DefaultPageSize)

    const params: TokenParams = useMemo(() => {
        const data: TokenParams = {
            page: pageIndex + 1,
            pageSize,
            query: searchText,
            chain_id: tokenFilter.chain_id.map((e) => parseInt(e)),
        }
        if (tokenFilter.is_native?.length === 1) data.is_native = tokenFilter.is_native[0]
        return data
    }, [pageIndex, pageSize, tokenFilter.chain_id, tokenFilter.is_native, searchText])

    useEffect(() => {
        dispatch(tokenSlice.actions.getTokensRequest(params))
    }, [dispatch, params])

    useEffect(() => {
        setPageIndex(0)
    }, [tokenFilter, searchText])

    const formatToken = useMemo(() => {
        return tokens.map((e, index) => ({ ...e, index: index + 1 }))
    }, [tokens])

    const columns = useMemo(() => {
        return getColumns(pageSize, pageIndex)
    }, [pageIndex, pageSize])

    return (
        <Table
            allowDisplayAllRows
            pageIndex={pageIndex}
            pageSize={pageSize}
            handlePageIndex={setPageIndex}
            handlePageSize={setPageSize}
            isLoading={isLoading}
            total={totalItems}
            columns={columns as TableColumn<{}>[]}
            data={formatToken}
        />
    )
}

export default TokenTable
