import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"

import { CreateOrEditAssetFormValues } from "."

const useEditAssetForm = () => {
    const exchanges = useSelector((state: RootState) => state.asset.exchanges)
    const selectedAsset = useSelector((state: RootState) => {
        const { selectedAssetID: id, assets } = state.asset
        if (!id) {
            return undefined
        }

        return assets.assets.find((asset) => asset.id === id)
    })

    const getDefaultFormValues = useCallback((): CreateOrEditAssetFormValues => {
        const mappings = exchanges.reduce((acc, cex) => {
            const mapping = selectedAsset?.cex_asset_mapping?.find((m) => m.exchange_id === cex.id)
            acc[cex.id] = mapping ? mapping.symbol : ""
            return acc
        }, {} as Record<number, string>)

        return {
            id: selectedAsset?.id,
            name: selectedAsset?.name,
            symbol: selectedAsset?.symbol,
            logo_url: selectedAsset?.logo_url,
            cmc_id: selectedAsset?.cmc_id,
            cmc_slug: selectedAsset?.cmc_slug,
            is_active: selectedAsset?.is_active,
            tokens: selectedAsset?.tokens,
            cex_asset_mapping: mappings,
        }
    }, [selectedAsset, exchanges])

    const methods = useForm<CreateOrEditAssetFormValues>({
        defaultValues: getDefaultFormValues(),
        mode: "all",
    })
    const { reset } = methods

    useEffect(() => {
        reset(getDefaultFormValues())
    }, [getDefaultFormValues, reset])

    return methods
}

export default useEditAssetForm
