import React, { useCallback, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { formatGMT } from "utils/date"

import Table from "components/Table"
import { TableColumn } from "components/Table/model"
import balanceAPI from "core/apis/balance"
import { EmptyArray } from "core/globalConstants"
import useShowToast from "core/hooks/useShowToast"
import { RootState } from "core/redux/reducer"
import { Balance, BalanceResponseV2 } from "core/types/balance"
import { getWalletId } from "pages/Balance/BalanceTable"
import ActionCell from "pages/Balance/BalanceTable/ActionCell"
import ValueCell from "pages/Balance/BalanceTable/ValueCell"
import DetailAssetModal from "pages/Comparison/BalanceTable/DetailAssestModal"

export type DisplayData = Partial<{
    account_type: string
    account_name: string
    date1: string
    date2: string
    total_amount_usd1: number
    total_amount_usd2: number
    diff: number
    type: string
}>

const generateColumns = (
    showDetail: (v: Balance) => void,
    fetchingWallet: string | number
): TableColumn<DisplayData>[] => {
    const columns: TableColumn<DisplayData>[] = [
        {
            Header: "Account Name",
            accessor: "account_name",
            sticky: "left",
            align: "center",
        },
        {
            Header: "Type",
            accessor: "type",
            align: "end",
            width: 80,
        },
        {
            Header: "Total USD date 1",
            accessor: "total_amount_usd1",
            Cell: ValueCell,
            align: "end",
        },
        {
            Header: "Total USD date 2",
            accessor: "total_amount_usd2",
            Cell: ValueCell,
            align: "end",
        },
        {
            Header: "Diff",
            accessor: "diff",
            Cell: (d) => <ValueCell {...d} deltaColor />,
            align: "end",
        },
        {
            Header: "Action",
            Cell: (d) => {
                const balance = d.row.original
                return d.row.index === d.data.length - 1 ? null : (
                    <ActionCell balance={balance} showDetail={showDetail} fetchingWallet={fetchingWallet} />
                )
            },
            align: "center",
            sticky: "right",
            width: 70,
        },
    ]

    return columns
}

export const getDiffBalanceAmount = (amount1: number, amount2: number) => amount1 - amount2 || 0

const BalanceComparisonTable: React.FC = () => {
    const timestamp1 = useSelector((state: RootState) => state.balanceComparison.timestamp1)
    const timestamp2 = useSelector((state: RootState) => state.balanceComparison.timestamp2)
    const balanceResponses = useSelector((state: RootState) => state.balanceComparison.balanceResponses)
    const { isLoading, prevParams } = useSelector((state: RootState) => state.balanceComparison)

    const [showDetail, setShowDetail] = useState(false)
    const [fetchingWallet, setFetchingWallet] = useState<string | number>("")
    const [currentAccount, setCurrentAccount] = useState<BalanceResponseV2[]>([])
    const { showToastError } = useShowToast()

    const onShowDetailAccount = useCallback(
        async (wallet: Balance) => {
            if (fetchingWallet) return
            try {
                const walletId = getWalletId(wallet)
                setFetchingWallet(walletId)
                const [params1, params2] = prevParams

                const accountInfo: BalanceResponseV2[] = await Promise.all([
                    balanceAPI.getBalanceByAccount({
                        ...params1,
                        id: walletId,
                        type: wallet.type,
                    }),
                    balanceAPI.getBalanceByAccount({
                        ...params2,
                        id: walletId,
                        type: wallet.type,
                    }),
                ])
                setCurrentAccount(accountInfo)
                setShowDetail(true)
            } catch (error) {
                showToastError(error.toString())
            }
            setFetchingWallet("")
        },
        [showToastError, prevParams, fetchingWallet]
    )

    const columns = useMemo(() => {
        return generateColumns(onShowDetailAccount, fetchingWallet)
    }, [fetchingWallet, onShowDetailAccount])

    const date1 = formatGMT(new Date(timestamp1 * 1000))
    const date2 = formatGMT(new Date(timestamp2 * 1000))

    const data: DisplayData[] = useMemo(() => {
        const data: DisplayData[] = []
        const [balanceResponse1, balanceResponse2] = balanceResponses

        const getAccountName = (balance1: Balance) => balance1["wallet_name"] || balance1["account_name"]
        const getRowData = (balance1: Balance, balance2: Balance) => {
            const total_amount_usd1 = balance1?.total_amount_usd || null
            const total_amount_usd2 = balance2?.total_amount_usd || null
            const row = {
                ...balance1,
                ...balance2,
                account_name: getAccountName(balance1 || balance2),
                date1,
                date2,
                total_amount_usd1,
                total_amount_usd2,
                diff:
                    total_amount_usd2 === null || total_amount_usd1 === null
                        ? null
                        : getDiffBalanceAmount(total_amount_usd2, total_amount_usd1),
            }
            return row as DisplayData
        }

        balanceResponse1?.balances.forEach((balance1) => {
            const balance2 = balanceResponse2.balances.find((el) => getWalletId(el) === getWalletId(balance1))
            if (!balance2) {
                console.error("Missing balance 2", balance1)
            }
            data.push(getRowData(balance1, balance2))
        })
        balanceResponse2?.balances.forEach((balance2) => {
            if (data.some((el) => getWalletId(el as any) === getWalletId(balance2))) {
                return
            }
            const balance1 = balanceResponse1.balances.find((el) => getWalletId(el) === getWalletId(balance2))
            if (!balance1) {
                console.error("Missing balance 1", balance2)
            }
            data.push(getRowData(balance1, balance2))
        })

        const row = {
            account_name: "Total Value (USD)",
            date1,
            date2,
            total_amount_usd1: balanceResponse1?.total_amount_usd || 0,
            total_amount_usd2: balanceResponse2?.total_amount_usd || 0,
            diff: getDiffBalanceAmount(balanceResponse2?.total_amount_usd, balanceResponse1?.total_amount_usd),
        }
        data.push(row as DisplayData)

        return data
    }, [date1, date2, balanceResponses])

    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(data.length)

    return (
        <>
            <Table
                allowDisplayAllRows
                pageIndex={pageIndex}
                pageSize={pageSize}
                handlePageIndex={setPageIndex}
                handlePageSize={setPageSize}
                isLoading={isLoading}
                total={data.length}
                data={data}
                columns={columns as TableColumn<{}>[]}
                paginationModes={EmptyArray}
            />
            <DetailAssetModal isOpen={showDetail} onClose={() => setShowDetail(false)} account={currentAccount} />
        </>
    )
}

export default BalanceComparisonTable
