import { Badge, Flex, Image, Text } from "@chakra-ui/react"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import getTokenExplorerURLByChainID from "utils/getTokenExplorerURLByChainID"

import { DefaultPageSize } from "components/Paginator/consts"
import Table from "components/Table"
import { TableColumn } from "components/Table/model"
import { NullSymbol } from "core/globalConstants"
import { RootState } from "core/redux/reducer"
import { AssetV2 } from "core/types/asset"
import { Exchange } from "core/types/cex"
import assetSlice from "pages/Asset/slice"
import { getChainById } from "pages/Token/ChainCell"

import ActionCell from "./ActionCell"
import NameCell from "./NameCell"

const generateColumns = (
    exchanges: Array<Exchange>,
    pageIndex: number,
    pageSize: number
): TableColumn<AssetV2 & { index: number }>[] => {
    const columns: TableColumn<AssetV2 & { index: number }>[] = [
        {
            Header: "#",
            accessor: (d) => {
                return d.index + 1 + pageIndex * pageSize
            },
            width: pageIndex * pageSize < 100 ? 32 : 50,
        },
        {
            Header: "Asset",
            accessor: "name",
            Cell: NameCell,
            width: 240,
            sticky: "left",
        },
        {
            Header: "CMC ID",
            accessor: (a: AssetV2) => {
                return a.cmc_id || NullSymbol
            },
            width: 70,
        },
        {
            Header: "CMC Slug",
            accessor: (a: AssetV2) => {
                return a.cmc_slug || NullSymbol
            },
            width: 120,
        },
        {
            Header: "Tokens",
            width: 240,
            accessor: (a: AssetV2) => {
                return (
                    <Flex gap="4px" flexWrap={"wrap"} paddingTop={"4px"} paddingBottom={"4px"}>
                        {a.tokens.map((e) => {
                            const chain = getChainById(e.chain_id)
                            return (
                                <Badge
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "4px",
                                        padding: "2px 4px",
                                    }}
                                    cursor={"pointer"}
                                    colorScheme="teal"
                                    variant={"outline"}
                                    key={e.id}
                                    onClick={() => window.open(getTokenExplorerURLByChainID(e.address, e.chain_id))}
                                >
                                    <Image boxSize="16px" borderRadius="100%" src={chain.logoURL} alt={chain.name} />
                                    <Text>{e.symbol}</Text>
                                </Badge>
                            )
                        })}
                    </Flex>
                )
            },
        },
    ]

    exchanges.forEach((exchange) => {
        columns.push({
            Header: exchange.name,
            accessor: (a: AssetV2) => {
                const mapping = a.cex_asset_mapping?.find((m) => m.exchange_id === exchange.id)
                return mapping ? mapping.symbol : NullSymbol
            },
            align: "center",
            width: 120,
        })
    })

    columns.push({
        Header: "Action",
        accessor: "id",
        Cell: ActionCell,
        width: 80,
        align: "center",
        sticky: "right",
    })

    return columns
}

const AssetTable: React.FC = () => {
    const isLoading = useSelector((state: RootState) => state.asset.isLoading)
    const assets = useSelector((state: RootState) => state.asset.assets?.assets)
    const totalItems = useSelector((state: RootState) => state.asset.assets?.pagination?.totalItems)
    const exchanges = useSelector((state: RootState) => state.asset.exchanges)
    const searchText = useSelector((state: RootState) => state.asset.searchText)
    const allPageSize = useSelector((state: RootState) => state.asset.allPageSize)

    const dispatch = useDispatch()
    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(DefaultPageSize)

    useEffect(() => {
        setPageIndex(0)
    }, [searchText])

    useEffect(() => {
        const params = { page: pageIndex + 1, pageSize, query: searchText }
        dispatch(assetSlice.actions.getAssetsRequest(params))
    }, [dispatch, pageSize, pageIndex, searchText])

    const columns = useMemo(() => {
        return generateColumns(exchanges, pageIndex, pageSize)
    }, [exchanges, pageIndex, pageSize])

    const filteredData = useMemo(() => {
        return (assets || []).map((e, index) => ({ ...e, index }))
    }, [assets])

    return (
        <Table
            allowDisplayAllRows
            pageIndex={pageIndex}
            pageSize={pageSize}
            handlePageIndex={setPageIndex}
            handlePageSize={setPageSize}
            isLoading={isLoading}
            total={totalItems}
            columns={columns as TableColumn<{}>[]}
            data={filteredData}
            allPageSize={allPageSize}
        />
    )
}

export default AssetTable
