import { Box, FormControl, FormLabel, Switch } from "@chakra-ui/react"
import CodeEditor from "@uiw/react-textarea-code-editor"
import { useState } from "react"

const BodyPanel: React.FC = () => {
    const [lang, setLang] = useState("json")
    const [code, setCode] = useState(
        `{
    "compilerOptions": {
        "target": "es5",
        "lib": ["dom", "dom.iterable", "esnext"],
        "allowJs": true,
        "baseUrl": "./src",
        "skipLibCheck": true,
        "strict": false,
        "forceConsistentCasingInFileNames": true,
        "noEmit": true,
        "noFallthroughCasesInSwitch": true,
        "strictPropertyInitialization": false,
        "module": "esnext",
        "moduleResolution": "node",
        "resolveJsonModule": true,
        "isolatedModules": true,
        "jsx": "react-jsx",
        "esModuleInterop": true,
        "allowSyntheticDefaultImports": true,
        "downlevelIteration": true
    },
    "include": ["src"]
}

`
    )

    return (
        <Box
            position="relative"
            _hover={{
                ".jsonSwitch": {
                    display: "flex",
                },
            }}
        >
            <CodeEditor
                value={code}
                language={lang}
                placeholder="Please enter body"
                onChange={(evn) => setCode(evn.target.value)}
                padding={15}
                style={{
                    fontSize: 12,
                    fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
                }}
            />

            <FormControl
                className="jsonSwitch"
                position="absolute"
                top="0.5rem"
                right="0.5rem"
                display="none"
                width="fit-content"
                alignItems="center"
                background="gray.200"
                padding="8px"
                borderRadius="8px"
            >
                <FormLabel mb="0">JSON?</FormLabel>
                <Switch
                    checked={lang === "json"}
                    onChange={(e) => {
                        if (e.target.checked) {
                            setLang("json")
                        } else {
                            setLang("text")
                        }
                    }}
                />
            </FormControl>
        </Box>
    )
}

export default BodyPanel
