import { Box, Input, Text } from "@chakra-ui/react"
import React, { useContext, useEffect, useState } from "react"

import { CONST, clamp } from "./consts"
import PaginationContext from "./context"

interface IProps {
    disabled?: boolean
}

const PageInput: React.FC<IProps> = ({ disabled }) => {
    const { total, pageIndex, pageSize, onPageIndexChange, ...context } = useContext(PaginationContext)
    const [pageCount, setPageCount] = useState(0)
    const [input, setInput] = useState("0")

    const isDisabled = disabled || context.disabled || pageCount <= 1

    const onInputConfirm = () => {
        if (Number(input) === pageIndex + 1) return
        if (!input) {
            setInput(String(pageIndex + 1))
            return
        }

        onPageIndexChange(clamp(0, Number(input) - 1, pageCount - 1))
    }

    const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === CONST.KEY_ENTER) onInputConfirm()
    }

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event
        const { value } = target

        if (!CONST.INPUT_REGEX.test(value) || +value > pageCount) {
            event.preventDefault()
            return
        }

        setInput(value)
    }

    useEffect(() => {
        setInput(String(pageIndex + 1))
    }, [pageIndex])

    useEffect(() => {
        setPageCount(Math.ceil(total / pageSize))
    }, [total, pageSize])

    return (
        <Box
            d="flex"
            alignItems="center"
            color={isDisabled ? "gray.300" : undefined}
            pointerEvents={isDisabled ? "none" : undefined}
        >
            <Text whiteSpace="nowrap" mr="8px" fontSize="sm" color="inherit">
                Go to
            </Text>

            <Input
                borderRadius="4px"
                textAlign="center"
                width="48px"
                size="sm"
                value={input}
                placeholder=""
                disabled={isDisabled}
                onChange={onInputChange}
                onKeyDown={handleKeydown}
                onBlur={onInputConfirm}
            />
        </Box>
    )
}

export default PageInput
