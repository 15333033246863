import http from "core/http"
import { APIMasterData } from "core/http/type"
import { ExchangeAccount } from "core/types/balance"
import { CEXAssetMapping, CEXAssetMappingDeletePayload, CEXAssetMappingPostPayload, Exchange } from "core/types/cex"

const getExchanges = (): Promise<APIMasterData<Exchange[]>> => {
    return http.call({ url: "/v1/exchanges" })
}

const getAccounts = (): Promise<APIMasterData<ExchangeAccount[]>> => {
    return http.callExternal("https://dev-cex-account-info.knstats.com/accounts")
}

const getMappings = (): Promise<APIMasterData<CEXAssetMapping[]>> => {
    return //http.getWithAuth("/api/cex-asset-mappings")
}

const postMapping = (_: CEXAssetMappingPostPayload): Promise<APIMasterData<CEXAssetMapping>> => {
    return //http.postWithAuth("/api/cex-asset-mapping", payload)
}

const deleteMapping = (_: CEXAssetMappingDeletePayload): Promise<APIMasterData<{}>> => {
    return //http.deleteWithAuth("/api/cex-asset-mapping", payload)
}

const cexAPI = {
    getAccounts,
    getExchanges,
    getMappings,
    postMapping,
    deleteMapping,
}

export default cexAPI
