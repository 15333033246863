import { Box, Flex } from "@chakra-ui/react"
import React from "react"
import { useSelector } from "react-redux"

import withPageLayout from "components/withPageLayout"
import { RootState } from "core/redux/reducer"

import EditTokenDrawer from "./EditTokenDrawer"
import TokenTable from "./TokenTable"
import UtilityBar from "./UtilityBar"

const TokenPage: React.FC = () => {
    const tokenStore = useSelector((state: RootState) => {
        const { selectedTokenID, tokenResponse } = state.tokenSlice
        const token = tokenResponse?.tokens.find((t) => t.id === selectedTokenID)
        return token
    })

    return (
        <Flex direction="column" height="100%" width="100%" padding="24px 160px">
            <Box flex="0 0" width="100%" d="flex" justifyContent="space-between" alignItems="center">
                <Box d="flex" flexDir="column">
                    <Box as="h1" fontSize="3xl">
                        Tokens
                    </Box>
                    <Box as="h3" fontSize="sm">
                        Token management
                    </Box>
                </Box>
            </Box>

            {/* <Box width="100%" mt="24px" mb="24px" height="0" borderBottom="1px solid" borderBottomColor="gray.500" /> */}

            <Box flex="0 0" width="100%" mt="24px" mb="24px">
                <UtilityBar />
            </Box>

            <Box overflow="hidden" width="100%" flex="1 1 100%">
                <TokenTable />
            </Box>

            <EditTokenDrawer token={tokenStore} />
        </Flex>
    )
}

export default withPageLayout(TokenPage)
