import { Box, Flex, Text } from "@chakra-ui/react"
import { useEffect, useRef, useState } from "react"
import DayPicker from "react-day-picker"
import "react-day-picker/lib/style.css"
import styled from "styled-components"
import { formatGMT } from "utils/date"

import { ONE_WEEK_IN_MS } from "core/globalConstants"

import CustomTimeInput from "./CustomTimeInput"
import TimestampInput from "./TimestampInput"

interface IProps {
    date?: Date
    className?: string
    title?: string
    onChangeDate: (d: Date) => void
    showSeconds?: boolean
}

const getDateString = (date: Date = new Date()): string => {
    return formatGMT(date, "yyyy-MM-dd")
}

const getTimeString = (date: Date = new Date(), showSeconds: boolean): string => {
    return formatGMT(date, showSeconds ? "HH:mm:ss" : "HH:mm")
}

const generateDate = (dateString: string, timeString: string): Date => {
    return new Date(`${dateString} ${timeString} GMT`)
}

const DateTimePicker: React.FC<IProps> = ({
    className,
    date = new Date(),
    title,
    onChangeDate,
    showSeconds = true,
}) => {
    const [visibleMonth, setVisibleMonth] = useState<Date>(date)

    const dateString = getDateString(date)
    const timeString = getTimeString(date, showSeconds)

    const handleDayClick = (newDate: Date) => {
        if (newDate.getTime() > Date.now() + ONE_WEEK_IN_MS) {
            return
        }

        const newDateString = getDateString(newDate)
        if (dateString === newDateString) {
            return
        }

        const date = generateDate(newDateString, timeString)
        onChangeDate(date)
        setVisibleMonth(date)
    }

    const ref = useRef<HTMLInputElement>(null)
    const onBlur = () => {
        const newTimeString = ref.current?.value || ""
        if (!newTimeString || timeString === newTimeString) {
            return
        }
        onChangeDate(generateDate(dateString, newTimeString))
    }

    useEffect(() => {
        setVisibleMonth(date)
        ref.current.value = getTimeString(date, showSeconds)
    }, [date, showSeconds])

    return (
        <Flex
            className={className}
            margin="none"
            width="284px"
            direction="column"
            height="100%"
            justifyContent="space-between"
        >
            <Flex direction="column">
                {!!title && (
                    <Text fontWeight="bold" fontSize="sm" mb="8px">
                        {title}
                    </Text>
                )}
                <TimestampInput date={date} onChangeDate={onChangeDate} />
                <DayPicker
                    showOutsideDays
                    locale="en-CA"
                    firstDayOfWeek={1}
                    onDayClick={handleDayClick}
                    selectedDays={date}
                    month={visibleMonth}
                    disabledDays={(day: Date) => day.getTime() - Date.now() > ONE_WEEK_IN_MS}
                    onMonthChange={(m: Date) => {
                        setVisibleMonth(m)
                    }}
                />
            </Flex>

            <Box
                w="100%"
                borderWidth="1px"
                borderStyle="solid"
                borderColor="gray.200"
                borderRadius="4px"
                color="gray.600"
            >
                <CustomTimeInput ref={ref} onBlur={onBlur} type={"time"} />
            </Box>
        </Flex>
    )
}

export default styled(DateTimePicker)`
    .DayPicker-Day.DayPicker-Day--selected:not(.DayPicker-Day--outside) {
        border-radius: 4px !important;
        background-color: var(--chakra-colors-teal-400) !important;
        color: #ffffff !important;
    }

    .DayPicker-Day {
        cursor: pointer;

        border-radius: 0 !important;
        padding: 4px !important;
        color: var(--chakra-colors-gray-500);

        cursor: pointer;
        vertical-align: middle;
        text-align: center;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;

        &:not(.DayPicker-Day--selected):not(.DayPicker-Day--disabled) {
            &:hover {
                background-color: var(--chakra-colors-gray-100) !important;
                border-radius: 4px !important;
            }
        }
    }

    .DayPicker-Months {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .DayPicker-Month {
        width: 252px;
    }

    .DayPicker-Day--outside.DayPicker-Day--outside.DayPicker-Day--outside {
        background-color: inherit !important;
        visibility: hidden;
    }

    .DayPicker-Day--disabled {
        color: var(--chakra-colors-gray-500);
        cursor: not-allowed;
        &:hover {
            background-color: initial !important;
        }
    }

    .DayPicker-Day.DayPicker-Day--today {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;

        &:not(.DayPicker-Day--selected) {
            color: var(--chakra-colors-teal-300);
        }
    }

    .DayPicker-Weekday {
        color: var(--chakra-colors-gray-500) !important;
    }
`
