import { ForkEffect, call, put, takeLatest } from "redux-saga/effects"

import assetAPI from "core/apis/asset"
import cexAPI from "core/apis/cex"
import { APIMasterData } from "core/http/type"
import { toast } from "core/redux/toast.slice"
import { Asset } from "core/types/asset"
import { CEXAssetMapping, CEXAssetMappingDeletePayload, CEXAssetMappingPostPayload, Exchange } from "core/types/cex"

import cexMappingSlice from "./slice"

function* getMappingsSaga() {
    const response: APIMasterData<CEXAssetMapping[]> = yield call(cexAPI.getMappings)

    if (response.data) {
        yield put(cexMappingSlice.actions.getMappingsSuccess(response.data))
    }
    if (response.error) {
        yield put(cexMappingSlice.actions.getMappingsFailure())
        yield put(
            toast({
                description: `Error: ${response.error}`,
                status: "error",
            })
        )
    }
}

function* postMappingSaga({ payload }: { payload: CEXAssetMappingPostPayload }) {
    const response: APIMasterData<CEXAssetMapping> = yield call(cexAPI.postMapping, payload)

    if (response.data) {
        yield put(cexMappingSlice.actions.postMappingSuccess(response.data))
        yield put(
            toast({
                description: "Created CEX mapping successfully",
                status: "success",
            })
        )
    }

    if (response.error) {
        yield put(cexMappingSlice.actions.postMappingFailure())
        yield put(
            toast({
                description: `Error: ${response.error}`,
                status: "error",
            })
        )
    }
}

function* deleteMappingSaga({ payload }: { payload: CEXAssetMappingDeletePayload }) {
    const response: APIMasterData<{}> = yield call(cexAPI.deleteMapping, payload)

    if (response.status === 200) {
        yield put(cexMappingSlice.actions.deleteMappingSuccess(payload))
        yield put(
            toast({
                description: "Deleted asset successfully",
                status: "success",
            })
        )
    }

    if (response.error) {
        yield put(cexMappingSlice.actions.deleteMappingFailure())
        yield put(
            toast({
                description: `Error: ${response.error}`,
                status: "error",
            })
        )
    }
}

function* getExchangesSaga() {
    const response: APIMasterData<Exchange[]> = yield call(cexAPI.getExchanges)

    if (response.data) {
        yield put(cexMappingSlice.actions.getExchangesSuccess(response.data))
    }

    if (response.error) {
        yield put(cexMappingSlice.actions.getExchangesFailure())
        yield put(
            toast({
                description: `Error: ${response.error}`,
                status: "error",
            })
        )
    }
}

function* getAssetsSaga() {
    const response: APIMasterData<Asset[]> = yield call(assetAPI.getAssets)

    if (response.data) {
        yield put(cexMappingSlice.actions.getAssetsSuccess(response.data))
    }

    if (response.error) {
        yield put(cexMappingSlice.actions.getAssetsFailure())
        yield put(
            toast({
                description: `Error: ${response.error}`,
                status: "error",
            })
        )
    }
}

export default function* cexMappingSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(cexMappingSlice.actions.getAssetsRequest, getAssetsSaga)
    yield takeLatest(cexMappingSlice.actions.getExchangesRequest, getExchangesSaga)
    yield takeLatest(cexMappingSlice.actions.getMappingsRequest, getMappingsSaga)
    yield takeLatest(cexMappingSlice.actions.postMappingRequest, postMappingSaga)
    yield takeLatest(cexMappingSlice.actions.deleteMappingRequest, deleteMappingSaga)
}
