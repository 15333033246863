import BigNumber from "bignumber.js"

// 1.00010000 => 1.0001
const removeTrailingZero = (num: string) => {
    if (num === undefined || num === null) return ""
    num = String(num)
    /**
     * 15.23000: $1 is 15, $2 is ., $3 is 23000 => '$1$2$3' => 15.23
     */
    return num.replace(/^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[0-9]*?)0*$/, "$1$2$3")
}

export const formatFloatUsd = (str: string | number): string => {
    const num = Number(str)
    let value =
        Math.abs(num) > 0.1
            ? new BigNumber(str).toFormat(2)
            : Math.abs(num) > 0.001
            ? new BigNumber(str).toFormat(3)
            : new BigNumber(str).toFormat(18)
    value = removeTrailingZero(value)
    return value.includes("-") ? `-$${value.replace("-", "")}` : `$${value}`
}

export const formatFloat = (str: string | number): string => {
    return new BigNumber(str).toFormat()
}
