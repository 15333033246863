import { Box, Image, Text } from "@chakra-ui/react"
import { Row } from "react-table"

import { NullCell } from "components/Table/components/NullCell"
import { Token } from "core/types"

interface IProps {
    value: number
    row: Row<Token>
}

const TokenItem = ({ logo_url, name, symbol }: { logo_url?: string; name: string; symbol?: string }) => {
    return (
        <Box d="flex" alignItems="center" whiteSpace="nowrap" columnGap="4px">
            <Box height="100%" flex="0 0 24px" d="flex" alignItems="center" justifyContent="center">
                {logo_url ? (
                    <Image boxSize="24px" src={logo_url} alt={name} />
                ) : (
                    <Box height="24px" flex="0 0 24px" borderRadius="100%" bgColor="blackAlpha.200" />
                )}
            </Box>

            <Text as="span" fontSize="sm">
                {name} {symbol ? `(${symbol})` : null}
            </Text>
        </Box>
    )
}

const AssetCell: React.FC<IProps> = ({ value: id, row }) => {
    const { asset } = row.original
    const logo_url = asset?.logo_url
    const name = asset?.name

    if (!id) {
        return <NullCell />
    }
    return <TokenItem logo_url={logo_url} name={name} />
}

export default AssetCell
