import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    Text,
    useDisclosure,
} from "@chakra-ui/react"
import KyberOauth2 from "@kybernetwork/oauth2"
import { useRef } from "react"
import Avatar from "react-nice-avatar"

import useUserInfo from "core/hooks/useUserInfo"

import UserIcon from "./UserIcon"

export const clearCache = () => {
    Object.keys(localStorage).forEach((key) => {
        if (key.startsWith("persist")) {
            localStorage.removeItem(key)
        }
    })
}

const UserDrawer = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const btnRef = useRef()

    const userInfo = useUserInfo()

    const shouldDisplayAvatar = userInfo

    const handleClose = () => {
        onClose()
    }

    const handleClickAuthenticateGoogle = async () => {
        KyberOauth2.authenticate()
    }

    const renderAvatar = (size = "26px") =>
        shouldDisplayAvatar ? (
            userInfo?.picture ? (
                <img
                    src={userInfo.picture}
                    style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "100%",
                    }}
                />
            ) : (
                <Avatar
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                />
            )
        ) : (
            <Box width={size} height={size} color="gray.500">
                <UserIcon />
            </Box>
        )

    return (
        <>
            <Box
                w="42px"
                h="42px"
                p="2px"
                d="flex"
                justifyContent="center"
                alignItems="center"
                borderRadius="100%"
                border="2px solid"
                borderColor={userInfo ? "teal.500" : "gray.500"}
                onClick={onOpen}
                cursor="pointer"
            >
                {renderAvatar()}
            </Box>
            <Drawer isOpen={isOpen} placement="right" onClose={handleClose} finalFocusRef={btnRef}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>
                        {userInfo ? `${userInfo?.first_name} ${userInfo?.last_name}` : "Authentication"}
                    </DrawerHeader>

                    <DrawerBody display="flex" flexDir="column" rowGap="16px" alignItems="center">
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            width="120px"
                            height="120px"
                            padding="4px"
                            border="4px solid"
                            borderColor={userInfo ? "teal.500" : "gray.500"}
                            borderRadius="100%"
                        >
                            {renderAvatar("100px")}
                        </Flex>

                        {userInfo?.email ? (
                            <Text textAlign="center" width="100%" colorScheme="teal">
                                {userInfo?.email}
                            </Text>
                        ) : (
                            <Button
                                disabled={!!userInfo}
                                loadingText="Checking"
                                width="100%"
                                colorScheme="teal"
                                onClick={handleClickAuthenticateGoogle}
                            >
                                {"Sign-in with Google"}
                            </Button>
                        )}
                        {userInfo && (
                            <Button
                                size={"sm"}
                                width={"100%"}
                                loadingText="Checking"
                                colorScheme="teal"
                                variant="outline"
                                onClick={() => KyberOauth2.logout()}
                            >
                                Logout
                            </Button>
                        )}
                    </DrawerBody>
                    <DrawerFooter>
                        <Flex flexDirection={"column"} sx={{ gap: "4px" }}>
                            <Text style={{ fontSize: "8px" }}>
                                Built at: {new Date(Number(process.env.REACT_APP_BUILT_TIME || 0) * 1000).toString()}
                            </Text>
                            <Button variant={"link"}>
                                <Text
                                    fontSize={"10px"}
                                    as="small"
                                    onClick={() => {
                                        const ok = confirm(
                                            "Do you want to clear cache, it will remove all setting of this site?"
                                        )
                                        if (!ok) return
                                        clearCache()
                                        window.location.reload()
                                    }}
                                >
                                    Clear Cache
                                </Text>
                            </Button>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default UserDrawer
