import { AlertStatus, ToastPositionWithLogical } from "@chakra-ui/react"
import { PayloadAction, createSlice, nanoid } from "@reduxjs/toolkit"

interface IToast {
    id?: string
    title?: string
    description: string
    status: AlertStatus
    position?: ToastPositionWithLogical
    isClosable?: boolean
    duration?: number
}

const initialState: IToast = {
    title: "",
    description: "",
    status: "info",
    position: "top-right",
    isClosable: true,
    duration: 5000,
}

const toastSlice = createSlice({
    name: "toast",
    initialState,
    reducers: {
        clearNotification: () => initialState,
        toast: (state, action: PayloadAction<IToast>) => {
            const {
                id = nanoid(),
                description,
                title,
                position = "top-right",
                isClosable = true,
                status = "info",
                duration = 5000,
            } = action.payload

            state.id = id
            state.description = description
            state.title = title
            state.position = position
            state.isClosable = isClosable
            state.status = status
            state.duration = duration
        },
    },
})

export const { toast } = toastSlice.actions
export default toastSlice
