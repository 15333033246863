import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"

import { WalletFormValues } from "."

const useEditWalletForm = () => {
    const selectedWallet = useSelector((state: RootState) => state.wallet.selectedWalletInfo)

    const getDefaultFormValues = useCallback(() => {
        return {
            id: selectedWallet?.id,
            address: selectedWallet?.address,
            description: selectedWallet?.description,
            name: selectedWallet?.name,
            type: selectedWallet?.type,
            groups:
                selectedWallet?.groups?.map((group) => ({
                    label: group.name,
                    value: group.id,
                })) || [],
        }
    }, [selectedWallet])

    const methods = useForm<WalletFormValues>({
        defaultValues: getDefaultFormValues(),
        mode: "all",
    })
    const { reset } = methods

    useEffect(() => {
        reset(getDefaultFormValues())
    }, [getDefaultFormValues, reset])

    return methods
}

export default useEditWalletForm
