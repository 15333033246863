import { Box } from "@chakra-ui/react"
import routes from "constants/routes"
import { Link, useLocation } from "react-router-dom"

import UserDrawer from "components/UserDrawer"
import KyberIcon from "components/_base/KyberIcon"

import NavItem from "./NavItem"

export const HEIGHT = 64

interface IProps {
    className?: string
}

const PageHeader: React.FC<IProps> = ({ className }) => {
    const location = useLocation()

    return (
        <Box
            as="header"
            className={className}
            position="sticky"
            top={0}
            zIndex={5}
            width="100%"
            height={`${HEIGHT}px`}
            padding="0 1.5rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            boxShadow="sm"
            background="white"
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="blackAlpha.200"
        >
            <Box>
                <Box display="flex" columnGap="16px">
                    <Box height="36px" width="36px">
                        <Link to="/">
                            <KyberIcon />
                        </Link>
                    </Box>

                    <Box display="flex" columnGap="8px">
                        {routes.map((config, index) => (
                            <NavItem
                                key={index}
                                isActive={location.pathname.startsWith(config.path)}
                                title={config.title}
                                href={config.path}
                            />
                        ))}
                    </Box>
                </Box>
            </Box>

            <UserDrawer />
        </Box>
    )
}

export default PageHeader
