import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"

import { WalletType } from "core/types/wallet"

import { WalletFormValues } from "."

const useCreateWalletForm = () => {
    const getDefaultFormValues = useCallback(() => {
        return {
            address: "",
            description: "",
            name: "",
            type: WalletType.EVM,
            groups: [],
        }
    }, [])

    const methods = useForm<WalletFormValues>({
        defaultValues: getDefaultFormValues(),
        mode: "all",
    })
    const { reset } = methods

    useEffect(() => {
        reset(getDefaultFormValues())
    }, [getDefaultFormValues, reset])

    return { ...methods, getDefaultFormValues }
}

export default useCreateWalletForm
