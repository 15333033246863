import { Box, Image, Text } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"
import Select from "react-select"
import styled from "styled-components"

import { chains } from "core/globalConstants"
import { RootState } from "core/redux/reducer"
import { SelectOption } from "core/types/select"
import balanceComparisonSlice from "pages/Comparison/slice"

type Chain = {
    id: string
    name: string
    logoURL: string
}

const ChainSelectItem: React.FC<Chain> = ({ name, logoURL }) => {
    return (
        <Box d="flex" alignItems="center" whiteSpace="nowrap" columnGap="4px">
            <Box height="100%" flex="0 0 24px" d="flex" alignItems="center" justifyContent="center">
                {logoURL ? (
                    <Image boxSize="24px" borderRadius="100%" src={logoURL} alt={name} />
                ) : (
                    <Box height="24px" flex="0 0 24px" borderRadius="100%" bgColor="blackAlpha.200" />
                )}
            </Box>

            <Text as="span" fontSize="sm">
                {name}
            </Text>
        </Box>
    )
}

const ChainOptions: SelectOption[] = chains.map((chain) => ({
    label: <ChainSelectItem {...chain} />,
    value: chain.id,
}))

const ChainSelect: React.FC<{ className?: string }> = ({ className }) => {
    const chainID = useSelector((state: RootState) => state.balanceComparison.chainID)
    const selectedOption = ChainOptions.find((option) => option.value === chainID)
    const dispatch = useDispatch()

    const handleChange = (_opt: SelectOption) => {
        dispatch(balanceComparisonSlice.actions.setChainId(_opt.value))
    }

    return (
        <Box flex="0 0 120px" className={className}>
            <Select
                classNamePrefix="chainSelect"
                className="chainSelect"
                value={selectedOption}
                onChange={handleChange}
                isSearchable={false}
                name="chain"
                options={ChainOptions}
                placeholder="Chain"
            />
        </Box>
    )
}

export default styled(ChainSelect)`
    .chainSelect {
        z-index: 4;
        height: 32px;

        &__control {
            height: 32px;
            max-height: 32px;
            min-height: 32px;
        }

        &__indicators {
            display: none;
        }
    }
`
