import { Select } from "@chakra-ui/react"
import React from "react"

import { HttpMethod } from "./types"

const MethodSelector: React.FC = () => {
    const method = {} as any

    const handleChange = (_: React.ChangeEvent<HTMLSelectElement>) => {
        // dispatch(debuggerSlice.actions.changeMethod(e.target.value as HttpMethod))
    }

    return (
        <Select size="sm" width="120px" value={method} onChange={handleChange}>
            {Object.values(HttpMethod).map((verb) => {
                return (
                    <option key={verb} value={verb}>
                        {verb}
                    </option>
                )
            })}
        </Select>
    )
}

export default MethodSelector
