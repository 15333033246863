import React from "react"
import styled from "styled-components"

import { ReactComponent as EmptyDataSVG } from "assets/im_192empty_listing.svg"
import { ReactComponent as GenericErrorSVG } from "assets/im_192empty_unknown_error.svg"
import { typo } from "theme"

const ERRORS = {
    general: {
        image: <GenericErrorSVG />,
        mess: "Something went wrong",
    },
    empty_data: {
        image: <EmptyDataSVG />,
        mess: "No data found",
    },
}

interface Props {
    className?: string
    type: "general" | "empty_data"
}

const ErrorScreen: React.FC<Props> = ({ className, type }) => {
    const screen = ERRORS[type]
    const { image, mess } = screen

    return (
        <div className={className}>
            {image}
            <span className="errorMessage">{mess}</span>
        </div>
    )
}

export default styled(ErrorScreen)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .errorMessage {
        margin-top: 8px;
        ${typo.T14_M}
    }
`
