import { FormControl, FormErrorMessage, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react"
import { useEffect, useState } from "react"

const TimestampInput: React.FC<{ date: Date; onChangeDate: (d: Date) => void }> = ({ date, onChangeDate }) => {
    const [error, setError] = useState("")
    const [rawTS, setRawTS] = useState(String(date.getTime()))

    const handleCommitTimestamp = () => {
        const ts = parseInt(rawTS, 10)
        if (Number.isNaN(ts)) {
            setError("Timestamp is invalid")
            return
        }

        if (Number.isNaN(new Date(ts).getTime())) {
            setError("Timestamp is invalid")
            return
        }

        onChangeDate(new Date(ts))
    }

    const ts = date.getTime()
    useEffect(() => {
        setRawTS(String(ts))
        setError("")
    }, [ts])

    return (
        <FormControl isInvalid={!!error}>
            <InputGroup>
                <Input
                    colorScheme="teal"
                    color="gray.600"
                    borderRadius="4px"
                    size="sm"
                    value={rawTS}
                    onChange={(e) => setRawTS(e.target.value)}
                    onBlur={handleCommitTimestamp}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            handleCommitTimestamp()
                        }
                    }}
                />
                <InputRightElement height="100%">
                    <Text fontSize="sm">ms</Text>
                </InputRightElement>
            </InputGroup>

            {!!error && <FormErrorMessage>{error}</FormErrorMessage>}
        </FormControl>
    )
}

export default TimestampInput
