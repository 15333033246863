import { Box, Button, Flex } from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"
import SettingsButton from "pages/Balance/UtilityBar/SettingsButton"
import WalletSelect from "pages/Balance/UtilityBar/WalletSelect"

import balanceComparisonSlice from "../slice"
import ChainSelect from "./ChainSelect"
import DateSelect from "./DateSelect"

// import ExportButton from "./ExportButton"

const UtilityBar: React.FC = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector((state: RootState) => state.virtual.isLoading)

    const fetchBalances = () => {
        dispatch(balanceComparisonSlice.actions.getBalanceDiffRequest())
    }

    return (
        <Box width="100%" d="flex" justifyContent="space-between">
            <Flex columnGap="8px">
                <ChainSelect />
                <WalletSelect />
                <DateSelect />
            </Flex>

            <Flex ml="8px" columnGap="8px">
                <SettingsButton />
                {/* <ExportButton /> */}
                <Button
                    width="100px"
                    ml="8px"
                    isLoading={isLoading}
                    borderRadius="4px"
                    size="sm"
                    colorScheme="teal"
                    onClick={fetchBalances}
                >
                    Apply
                </Button>
            </Flex>
        </Box>
    )
}

export default UtilityBar
