import { SizeOption } from "./model"

export const LABEL = {
    NEXT_PAGE: "Next",
    PREVIOUS_PAGE: "Previous",
    GO_TO_PAGE: "Go to page",
    PAGE_SIZE: "Rows per page",
}

export const CONST = {
    ELLIPSIS: "...",
    MAX_LENGTH: 5,
    INPUT_REGEX: /^\d{0,6}$/,
    KEY_ENTER: "Enter",
}

export const SizeOptions: SizeOption[] = [10, 20, 50, 100, 200, 500, 1000].map((el) => ({
    label: el + "",
    value: el,
}))

export const DefaultPageSize = SizeOptions[0].value

export const clamp = (min: number, value: number, max: number): number => {
    if (value < min) return min
    if (value > max) return max
    return value
}
