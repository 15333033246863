import { Badge, Stack } from "@chakra-ui/react"

import { NullCell } from "components/Table/components/NullCell"
import { CompactGroup } from "core/types/group"

interface IProps {
    value?: CompactGroup[]
}

const GroupsCell: React.FC<IProps> = ({ value }) => {
    if (!value || value.length === 0) {
        return <NullCell />
    }

    return (
        <Stack direction="row" isTruncated>
            {value.map((group) => (
                <Badge key={group.id}>{group.name}</Badge>
            ))}
        </Stack>
    )
}

export default GroupsCell
