import { Button } from "@chakra-ui/react"
import axios, { AxiosRequestConfig } from "axios"
import { useDispatch, useSelector } from "react-redux"

import useShowToast from "core/hooks/useShowToast"
import { RootState } from "core/redux/reducer"

import debuggerSlice from "./slice"
import { HttpMethod, HttpMode } from "./types"

export const destruct = (fullURL: string) => {
    try {
        const url = new URL(fullURL)
        const search = url.search
        let baseURL = url.toString()
        const path = new URL(baseURL).pathname
        if (search) {
            baseURL = url.toString().replace(search, "")
        }
        baseURL = baseURL.replace(path, "")

        return {
            baseURL,
            path,
        }
    } catch (e) {
        return {
            baseURL: "",
            path: "",
        }
    }
}

const SendButton = () => {
    const dispatch = useDispatch()
    const { url, method, mode, isLoading, accessToken } = useSelector((state: RootState) => state.debugger)
    const { baseURL } = destruct(url)

    const dispatchResponse = (resp: any) => {
        dispatch(debuggerSlice.actions.makeRequestDone(resp))
    }

    const { showToastError } = useShowToast()
    const handleClick = async () => {
        let resp
        try {
            switch (method) {
                case HttpMethod.GET: {
                    const config: AxiosRequestConfig = {
                        method,
                        url,
                        headers: mode === HttpMode.WITH_AUTH ? { Authorization: `Bearer ${accessToken}` } : {},
                    }
                    resp = await axios(config)
                    dispatchResponse(resp.data)
                    break
                }
                default:
                    showToastError(`Not support method ${method} for now`)
            }
        } catch (error) {
            //
        }
        dispatchResponse(resp?.data)
    }

    return (
        <Button
            flex="0 0 80px"
            size="sm"
            colorScheme="blue"
            onClick={handleClick}
            disabled={!baseURL}
            isLoading={isLoading}
        >
            SEND
        </Button>
    )
}

export default SendButton
