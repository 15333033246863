import { Box, Button, Flex, Tooltip } from "@chakra-ui/react"

import Table from "components/Table"
import { TableColumn } from "components/Table/model"
import { Token } from "core/types"
import CreateAsset from "pages/Asset/CreateAsset"
import { buttonLabelAddAsset, buttonLabelConnect } from "pages/Balance/BalanceTable/DetailAsset"
import { formatFloat } from "pages/Balance/utils"
import AddressCell from "pages/Token/AddressCell"
import ChainCell from "pages/Token/ChainCell"
import EditTokenDrawer from "pages/Token/EditTokenDrawer"
import NativeCell from "pages/Token/NativeCell"

const getColumns = ({
    onSelectToken,
    isCexAccount,
}: {
    isCexAccount: boolean
    onSelectToken: (tok: Token) => void
}) => {
    const columns: TableColumn<Token & { index: number }>[] = [
        {
            Header: "Balance",
            accessor: "amount",
            sticky: "left",
            Cell: (d) => <>{d.value ? formatFloat(d.value) : 0}</>,
        },
        {
            Header: "Symbol",
            accessor: "symbol",
            width: 80,
        },
        isCexAccount
            ? null
            : {
                  Header: "Address",
                  accessor: "address",
                  Cell: AddressCell,
                  width: 150,
              },
        isCexAccount
            ? null
            : {
                  Header: "Native",
                  accessor: "is_native",
                  Cell: NativeCell,
                  width: 70,
              },
        isCexAccount
            ? null
            : {
                  Header: "Chain",
                  accessor: "chain_id",
                  Cell: ChainCell,
                  width: 100,
              },
        {
            Header: "Action",
            Cell: (d) => (
                <Tooltip label={isCexAccount ? "Create a new Asset" : "Connect asset to this token"} placement="top">
                    <Button size={"xs"} onClick={() => onSelectToken(d.row.original)}>
                        {isCexAccount ? buttonLabelAddAsset : buttonLabelConnect}
                    </Button>
                </Tooltip>
            ),
            width: 80,
            align: "center",
        },
    ]
    return columns.filter(Boolean)
}

export default function TokenWithoutAsset({
    isCexAccount,
    tokens_without_asset,
    onSelectToken,
    selectedToken,
    onDismissEditToken,
    onSaveSuccess,
    loading,
}: {
    isCexAccount: boolean
    tokens_without_asset: Token[]
    onSelectToken: (v: Token) => void
    selectedToken: Token | undefined
    onDismissEditToken: () => void
    onSaveSuccess: () => void
    loading: boolean
}) {
    return (
        <>
            <Flex flexDirection={"column"} style={{ minHeight: 200, maxHeight: "80vh", overflowY: "scroll" }}>
                <Box overflow="scroll" width="100%" flex="1 1 100%">
                    <Table
                        isLoading={loading}
                        overlay={false}
                        allowDisplayAllRows
                        total={0}
                        columns={getColumns({ onSelectToken, isCexAccount }) as TableColumn<{}>[]}
                        data={tokens_without_asset}
                    />
                </Box>

                {tokens_without_asset.length !== 0 && (
                    <Flex marginTop={"10px"} fontSize={"12px"}>
                        <u>Note</u>:{" "}
                        {isCexAccount ? (
                            <>
                                These tokens haven&apos;t created asset yet. Please click&nbsp;
                                <b>&quot;{buttonLabelAddAsset}&quot;</b>&nbsp; to create asset for them.
                            </>
                        ) : (
                            <>
                                These tokens don&apos;t connect to any asset. Please click&nbsp;
                                <b>&quot;{buttonLabelConnect}&quot;</b>&nbsp; to connect them to their asset
                            </>
                        )}
                    </Flex>
                )}
            </Flex>

            <CreateAsset
                isOpen={!!selectedToken}
                onDismiss={onDismissEditToken}
                requiredAssetMapping
                onSaveSuccess={onSaveSuccess}
            />
            <EditTokenDrawer token={selectedToken} onDismiss={onDismissEditToken} onSaveSuccess={onSaveSuccess} />
        </>
    )
}
