import { ForkEffect, call, put, select, takeLatest } from "redux-saga/effects"

import assetAPI from "core/apis/asset"
import cexAPI from "core/apis/cex"
import groupAPI from "core/apis/group"
import virtualRecord from "core/apis/virtualRecord"
import virtualAPI from "core/apis/virtualRecord"
import walletAPI from "core/apis/wallet"
import { APIMasterData } from "core/http/type"
import { RootState } from "core/redux/reducer"
import { toast } from "core/redux/toast.slice"
import { Asset } from "core/types/asset"
import { ExchangeAccount } from "core/types/cex"
import { Group } from "core/types/group"
import { VirtualRecord, VirtualRecordPostPayload } from "core/types/virtual"
import { Wallet } from "core/types/wallet"

import virtualSlice from "./virtual.slice"

function* getVirtualRecordsSaga() {
    const state: RootState = yield select((state) => state)
    const {
        groups,
        filter: { fromTimestamp, toTimestamp, groupIDs, walletIDs, cexAccountIDs },
    } = state.virtual

    let uniqueWalletIDs: number[] = [...walletIDs]
    groupIDs.forEach((id) => {
        const group = groups.find((group) => group.id === id)
        if (group) {
            uniqueWalletIDs.push(...group.wallets.map((wallet) => wallet.id).filter(Boolean))
        }
    })
    uniqueWalletIDs = [...new Set(uniqueWalletIDs)]

    let uniqueCEXAccounts: string[] = [...cexAccountIDs]
    groupIDs.forEach((id) => {
        const group = groups.find((group) => group.id === id)
        if (group) {
            uniqueCEXAccounts.push(...group.cex_accounts)
        }
    })
    uniqueCEXAccounts = [...new Set(uniqueCEXAccounts)]

    const response: APIMasterData<VirtualRecord[]> = yield call(
        virtualRecord.getVirtualRecords,
        uniqueWalletIDs,
        uniqueCEXAccounts,
        fromTimestamp,
        toTimestamp
    )

    if (response.data) {
        yield put(virtualSlice.actions.getVirtualRecordsSuccess(response.data))
    }

    if (response.error) {
        yield put(virtualSlice.actions.getVirtualRecordsFailure())
        yield put(
            toast({
                description: `Error: ${response.error}`,
                status: "error",
            })
        )
    }
}

function* postVirtualRecordSaga({ payload }: { payload: VirtualRecordPostPayload }) {
    const response: APIMasterData<VirtualRecord> = yield call(virtualAPI.postVirtualRecord, payload)

    if (response.data) {
        yield getVirtualRecordsSaga()
        yield put(
            toast({
                description: "Created virtual record successfully",
                status: "success",
            })
        )
    }

    if (response.error) {
        yield put(virtualSlice.actions.postVirtualRecordFailure())
        yield put(
            toast({
                description: `Error: ${response.error}`,
                status: "error",
            })
        )
    }
}

function* deleteVirtualRecordSaga({ payload }: { payload: number }) {
    const response: APIMasterData<{}> = yield call(virtualAPI.deleteVirtualRecord, payload)

    if (response.status === 200) {
        yield put(virtualSlice.actions.deleteVirtualRecordSuccess())
        yield getVirtualRecordsSaga()
        yield put(
            toast({
                description: "Deleted virtual record successfully",
                status: "success",
            })
        )
    }

    if (response.error) {
        yield put(virtualSlice.actions.deleteVirtualRecordFailure())
        yield put(
            toast({
                description: `Error: ${response.error}`,
                status: "error",
            })
        )
    }
}

function* _initializeVirtualSliceSaga() {
    let groups: Group[] = []
    let exchangeAccounts: ExchangeAccount[] = []
    let wallets: Wallet[] = []
    let assets: Asset[] = []

    // fetch wallets
    {
        const response: APIMasterData<Wallet[]> = yield call(walletAPI.getWallets)
        if (response.data) {
            wallets = response.data
        }
        if (response.error) {
            yield put(virtualSlice.actions._initializeVirtualSliceFailure())
            yield put(
                toast({
                    description: `Error: ${response.error}`,
                    status: "error",
                })
            )
            return
        }
    }

    // fetch exchange accounts
    {
        const response: APIMasterData<ExchangeAccount[]> = yield call(cexAPI.getAccounts)
        if (response.data) {
            exchangeAccounts = response.data
        }
        if (response.error) {
            yield put(virtualSlice.actions._initializeVirtualSliceFailure())
            yield put(
                toast({
                    description: `Error: ${response.error}`,
                    status: "error",
                })
            )
            return
        }
    }

    // fetch exchange accounts
    {
        const response: APIMasterData<Group[]> = yield call(groupAPI.getGroups)
        if (response.data) {
            groups = response.data
        }
        if (response.error) {
            yield put(virtualSlice.actions._initializeVirtualSliceFailure())
            yield put(
                toast({
                    description: `Error: ${response.error}`,
                    status: "error",
                })
            )
            return
        }
    }

    // fetch assets
    {
        const response: APIMasterData<Asset[]> = yield call(assetAPI.getAssets)
        if (response.data) {
            assets = response.data
        }
        if (response.error) {
            yield put(virtualSlice.actions._initializeVirtualSliceFailure())
            yield put(
                toast({
                    description: `Error: ${response.error}`,
                    status: "error",
                })
            )
            return
        }
    }

    yield put(virtualSlice.actions._initializeVirtualSliceSuccess({ exchangeAccounts, wallets, assets, groups }))
    yield getVirtualRecordsSaga()
}

export default function* virtualSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(virtualSlice.actions.getVirtualRecordsRequest, getVirtualRecordsSaga)
    yield takeLatest(virtualSlice.actions.postVirtualRecordRequest, postVirtualRecordSaga)
    yield takeLatest(virtualSlice.actions.deleteVirtualRecordRequest, deleteVirtualRecordSaga)
    yield takeLatest(virtualSlice.actions._initializeVirtualSliceRequest, _initializeVirtualSliceSaga)
}
