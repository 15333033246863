import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { chains } from "core/globalConstants"
import { Asset } from "core/types/asset"
import { Group } from "core/types/group"
import { TokenTransfer } from "core/types/transfer"
import { Wallet } from "core/types/wallet"

import { AssetMovementFilter, AssetMovementSettings } from "./model"

interface IState {
    wallets: Wallet[]
    groups: Group[]
    assets: Asset[]

    chainID: string
    // this should be named params
    filter: AssetMovementFilter
    fromTimestamp: number
    toTimestamp: number

    isLoading: boolean
    tokenTransfers: TokenTransfer[]

    settings: AssetMovementSettings
}

const getDefaultFromTimestamp = (): number => {
    const week = 7 * 24 * 60 * 60 * 1000
    const time = new Date(Date.now() - week).getTime()
    return Math.floor(time / 1000)
}

const getDefaultToTimestamp = (): number => {
    const time = new Date().getTime()
    return Math.floor(time / 1000)
}

const initialState: IState = {
    assets: [],
    wallets: [],
    groups: [],

    chainID: chains[0].id,
    filter: {
        walletIDs: [],
        groupIDs: [],
    },
    fromTimestamp: getDefaultFromTimestamp(),
    toTimestamp: getDefaultToTimestamp(),

    isLoading: false,
    tokenTransfers: [],

    settings: {
        showInTransfers: true,
        showOutTransfers: true,
        showKnownAssets: true,
        showUnknownAssets: true,
    },
}

const assetMovementSlice = createSlice({
    name: "assetMovement",
    initialState,
    reducers: {
        // get token transfers
        getTokenTransfersRequest: (state) => {
            state.isLoading = true
        },
        getTokenTransfersSuccess: (state, action: PayloadAction<TokenTransfer[]>) => {
            state.isLoading = false
            state.tokenTransfers = action.payload
        },
        getTokenTransfersFailure: (state) => {
            state.isLoading = false
        },

        // get wallets
        getWalletsRequest: (state) => {
            state.isLoading = true
        },
        getWalletsSuccess: (state, action: PayloadAction<Wallet[]>) => {
            state.isLoading = false
            state.wallets = action.payload
        },
        getWalletsFailure: (state) => {
            state.isLoading = false
        },

        setFilter: (state, action: PayloadAction<AssetMovementFilter>) => {
            state.filter = action.payload
        },

        // get groups
        getGroupsRequest: (state) => {
            state.isLoading = true
        },
        getGroupsSuccess: (state, action: PayloadAction<Group[]>) => {
            state.isLoading = false
            state.groups = action.payload
        },
        getGroupsFailure: (state) => {
            state.isLoading = false
        },

        setFromTimestamp: (state, action: PayloadAction<number>) => {
            state.fromTimestamp = action.payload
        },
        setToTimestamp: (state, action: PayloadAction<number>) => {
            state.toTimestamp = action.payload
        },

        setChainID: (state, action: PayloadAction<string>) => {
            state.chainID = action.payload
        },

        setSingleSetting: (state, action: PayloadAction<{ name: keyof AssetMovementSettings; value: boolean }>) => {
            const { name, value } = action.payload
            state.settings[name] = value
        },

        _initializeAssetMovementRequest: (state) => {
            state.isLoading = true
        },
        _initializeAssetMovementSuccess: (
            state,
            action: PayloadAction<{ groups: Group[]; wallets: Wallet[]; assets: Asset[] }>
        ) => {
            state.isLoading = false
            state.groups = action.payload.groups
            state.wallets = action.payload.wallets
            state.assets = action.payload.assets
        },
        _initializeAssetMovementFailure: (state) => {
            state.isLoading = false
        },
    },
})

export default assetMovementSlice
