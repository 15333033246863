import cx from "classnames"
import React from "react"
import styled from "styled-components"

import { animSpeed, color } from "theme"

type ResizingProps = {
    isResizing: boolean
}

type Props = ResizingProps & React.ComponentPropsWithoutRef<"div">

const Resizer: React.FC<Props> = ({ className, isResizing, ...props }) => (
    <div className={cx(className, "resizer")} {...props} />
)

export default styled(Resizer)`
    z-index: 1;
    opacity: 0;
    width: 15px;
    display: inline-block;
    height: calc(100% + 1px);
    transition: opacity ${animSpeed.fast};
    position: absolute;
    right: -8px;
    top: 0;

    &:after {
        content: "";
        width: 3px;
        height: 100%;
        background-color: ${color.primary[500]};
        position: absolute;
        right: 6px;
        top: 0;
    }
`
