import { AddIcon } from "@chakra-ui/icons"
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
} from "@chakra-ui/react"
import { useEffect } from "react"
import { FormProvider } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"
import { WalletPostPayload } from "core/types/wallet"

import CreateOrEditWalletForm from "./CreateOrEditWalletForm"
import useCreateWalletForm from "./CreateOrEditWalletForm/useCreateWalletForm"
import walletSlice from "./slice"

const CreateWallet: React.FC = () => {
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { getDefaultFormValues, ...methods } = useCreateWalletForm()
    const { reset } = methods
    const isSubmitting = useSelector((state: RootState) => state.asset.isSubmitting)
    const selectedWalletInfo = useSelector((state: RootState) => state.wallet.selectedWalletInfo)

    const handleClose = () => {
        onClose()
    }

    const handleClick = () => {
        onOpen()
    }

    const handleClickSubmit = () => {
        const values = methods.getValues()

        const payload: WalletPostPayload = {
            address: values.address,
            type: values.type,
            name: values.name,
            description: values.description,
            group_ids: values.groups.map((option) => option.value as number),
        }
        dispatch(walletSlice.actions.postWalletRequest(payload))
    }

    useEffect(() => {
        if (selectedWalletInfo) {
            onClose()
        }
    }, [selectedWalletInfo, onClose])
    useEffect(() => {
        if (!isOpen) {
            // can't just do reset()
            reset(getDefaultFormValues())
        }
    }, [getDefaultFormValues, isOpen, reset])

    return (
        <>
            <Button borderRadius="4px" size="sm" colorScheme="teal" leftIcon={<AddIcon />} onClick={handleClick}>
                New Wallet
            </Button>

            <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Create new wallet</DrawerHeader>

                    <DrawerBody paddingTop="32px">
                        <FormProvider {...methods}>
                            <CreateOrEditWalletForm isEditing={false} />
                        </FormProvider>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={handleClose} disabled={isSubmitting}>
                            Cancel
                        </Button>
                        <Button
                            isLoading={isSubmitting}
                            loadingText="Submitting"
                            colorScheme="teal"
                            onClick={methods.handleSubmit(handleClickSubmit)}
                        >
                            Submit
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default CreateWallet
