import { theme } from "@chakra-ui/react"

import { formatFloatUsd } from "../utils"

type Props = {
    value: string | number | null
    deltaColor?: boolean
}

const getDeltaColor = (displayValue, deltaColor) =>
    !deltaColor || displayValue === "$0" ? "unset" : displayValue?.includes("-") ? theme.colors.red[500] : "teal"

const ValueCell: React.FC<Props> = ({ value, deltaColor }) => {
    if (value === null) return <b style={{ color: "orange" }}>No data</b>
    const displayValue = Number.isNaN(Number.parseFloat(String(value))) ? String(value) : formatFloatUsd(value)
    return (
        <span
            style={{
                color: getDeltaColor(displayValue, deltaColor),
            }}
        >
            {displayValue}
        </span>
    )
}

export default ValueCell
