import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
} from "@chakra-ui/react"
import { useEffect } from "react"
import { FormProvider } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"
import { WalletPostPayload, WalletType } from "core/types/wallet"

import CreateOrEditWalletForm from "./CreateOrEditWalletForm"
import useEditWalletForm from "./CreateOrEditWalletForm/useEditWalletForm"
import walletSlice from "./slice"

const EditWalletDrawer: React.FC = () => {
    const dispatch = useDispatch()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const selectedWalletInfo = useSelector((state: RootState) => state.wallet.selectedWalletInfo)
    const isSubmitting = useSelector((state: RootState) => state.wallet.isSubmitting)
    const methods = useEditWalletForm()

    const shouldOpen = !!selectedWalletInfo

    const handleClose = () => {
        dispatch(walletSlice.actions.setSelectedWalletInfo())
    }

    const handleClickSubmit = () => {
        const values = methods.getValues()
        const payload: WalletPostPayload = {
            id: values.id,
            name: values.name,
            description: values.description,
            group_ids: values.groups.map((option) => option.value as number),
            type: values.type as WalletType,
        }
        dispatch(walletSlice.actions.postWalletRequest(payload))
    }

    useEffect(() => {
        if (shouldOpen) {
            onOpen()
        } else {
            onClose()
        }
    }, [shouldOpen, onOpen, onClose])

    return (
        <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>Edit wallet</DrawerHeader>

                <DrawerBody paddingTop="32px">
                    <FormProvider {...methods}>
                        <CreateOrEditWalletForm isEditing />
                    </FormProvider>
                </DrawerBody>

                <DrawerFooter>
                    <Button variant="outline" mr={3} onClick={handleClose} disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!methods.formState.isDirty}
                        isLoading={isSubmitting}
                        loadingText="Submitting"
                        colorScheme="teal"
                        onClick={methods.handleSubmit(handleClickSubmit)}
                    >
                        Submit
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default EditWalletDrawer
