// import { clearCache } from "components/UserDrawer"

const getLastReleaseTime = () => {
    const t = localStorage.getItem("lastReleaseTime")
    if (!t) {
        return 0
    }

    return Number(t)
}

const saveReleaseTime = (t: number) => {
    localStorage.setItem("lastReleaseTime", String(t))
}

const checkShouldClearLocalStorageAndReload = () => {
    const currentReleaseTime = Number(process.env.REACT_APP_BUILT_TIME)
    const lastReleaseTime = process.env.NODE_ENV === "development" ? currentReleaseTime : getLastReleaseTime()

    const markReleaseTime = () => {
        // clearCache()
        saveReleaseTime(currentReleaseTime)
    }

    if (currentReleaseTime !== lastReleaseTime) {
        return {
            shouldReload: true,
            markReleaseTime,
        }
    }

    return {
        shouldReload: false,
        markReleaseTime,
    }
}

export default checkShouldClearLocalStorageAndReload
