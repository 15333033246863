import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    FormControl,
    FormLabel,
    Image,
    Text,
} from "@chakra-ui/react"
import { APP_PATHS } from "constants/routes"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import Select from "react-select"
import { usePrevious } from "react-use"

import { EmptyArray } from "core/globalConstants"
import useSearchData, { useIsSearchingData } from "core/hooks/useSugguestionData"
import commonSlice from "core/redux/common.slice"
import { RootState } from "core/redux/reducer"
import { PutTokenPayload, Token } from "core/types"
import { AssetV2 } from "core/types/asset"
import { SelectOption } from "core/types/select"

import tokenSlice from "../slice"

const AssetOption: React.FC<{ asset: AssetV2 }> = ({ asset }) => {
    return (
        <Box d="flex" alignItems="center" whiteSpace="nowrap" columnGap="4px">
            <Box height="100%" flex="0 0 24px" d="flex" alignItems="center" justifyContent="center">
                {asset.logo_url ? (
                    <Image boxSize="24px" src={asset.logo_url} alt={asset.name} />
                ) : (
                    <Box height="24px" flex="0 0 24px" borderRadius="100%" bgColor="blackAlpha.200" />
                )}
            </Box>

            <Text as="span" fontSize="sm">
                {asset.name}
            </Text>
        </Box>
    )
}

const getOption = (asset: AssetV2) => ({ label: <AssetOption asset={asset} />, value: asset.id })

const ConnectAssetToken = ({
    selectedAssetOption,
    setSelectedAssetOption,
}: {
    selectedAssetOption: SelectOption
    setSelectedAssetOption: (v: SelectOption) => void
}) => {
    const dispatch = useDispatch()
    const onSearch = (value: string) => {
        dispatch(commonSlice.actions.setSearchQuery({ assets: value }))
    }
    const { assets = EmptyArray } = useSearchData()
    const isSearching = useIsSearchingData()
    const assetOptions: SelectOption[] = useMemo(() => {
        return assets.map(getOption)
    }, [assets])
    return (
        <FormControl>
            <FormLabel fontSize="sm">Connect to this asset</FormLabel>
            <Select
                className="selector"
                classNamePrefix="selector"
                value={selectedAssetOption}
                onChange={setSelectedAssetOption}
                onInputChange={onSearch}
                isClearable
                isSearchable
                name="hidden-assets"
                options={assetOptions}
                placeholder="Choose an asset"
                closeMenuOnScroll={false}
                noOptionsMessage={() =>
                    isSearching ? (
                        "Searching data..."
                    ) : (
                        <>
                            Not found asset.{" "}
                            <Link to={APP_PATHS.ASSETS}>
                                <Text as="span" color={"teal"}>
                                    Create a new one?
                                </Text>
                            </Link>
                        </>
                    )
                }
                filterOption={(option: SelectOption, inputValue) => {
                    const text = inputValue.toLowerCase()
                    const matchAssetIDs = assets
                        .filter((asset) => {
                            return (
                                asset.name.toLowerCase().includes(text) ||
                                asset.symbol.toLowerCase().includes(text) ||
                                asset.cmc_slug?.toLowerCase().includes(text)
                            )
                        })
                        .map((asset) => asset.id)
                    return matchAssetIDs.includes(option.value)
                }}
            />
        </FormControl>
    )
}

const EditTokenDrawer = ({
    token: selectedToken,
    onDismiss,
    onSaveSuccess,
}: {
    token?: Token
    onDismiss?: () => void
    onSaveSuccess?: () => void
}) => {
    const dispatch = useDispatch()

    const selectedTokenID = selectedToken?.id
    const isOpen = !!selectedTokenID
    const isSubmitting = useSelector((state: RootState) => state.tokenSlice.isSubmitting)
    const { assets = EmptyArray } = useSearchData()

    const assetOptions: SelectOption[] = useMemo(() => {
        return assets.map(getOption)
    }, [assets])

    const [selectedAssetOption, setSelectedAssetOption] = useState<SelectOption>()

    const hasChangeAsset = selectedToken?.asset?.id !== selectedAssetOption?.value

    const prevIsSubmitSuccess = usePrevious(isSubmitting)
    const isSubmitSuccess = prevIsSubmitSuccess && !isSubmitting

    const handleClose = useCallback(() => {
        dispatch(tokenSlice.actions.setSelectedTokenID())
        onDismiss?.()
        setSelectedAssetOption(undefined)
    }, [dispatch, onDismiss])

    const handleSubmitSuccess = useCallback(() => {
        handleClose()
        onSaveSuccess?.()
    }, [onSaveSuccess, handleClose])

    useEffect(() => {
        if (isSubmitSuccess) handleSubmitSuccess()
    }, [isSubmitSuccess, handleSubmitSuccess])

    const handleClickSubmit = () => {
        const payload: PutTokenPayload = {
            id: selectedTokenID,
            asset_id: selectedAssetOption?.value,
        }
        dispatch(tokenSlice.actions.putTokenRequest(payload))
    }

    useEffect(() => {
        const asset = selectedToken?.asset
        if (asset) {
            setSelectedAssetOption(getOption(asset))
        }
    }, [assetOptions, selectedToken])

    return (
        <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader position="sticky" top="0">
                    Edit token
                </DrawerHeader>

                <DrawerBody paddingTop="16px" paddingBottom="32px">
                    <ConnectAssetToken
                        setSelectedAssetOption={setSelectedAssetOption}
                        selectedAssetOption={selectedAssetOption}
                    />
                </DrawerBody>

                <DrawerFooter position="sticky" bottom="0" borderTop="1px" borderColor="gray.200">
                    <Button variant="outline" mr={3} onClick={handleClose} disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!hasChangeAsset}
                        isLoading={isSubmitting}
                        loadingText="Submitting"
                        colorScheme="teal"
                        onClick={handleClickSubmit}
                    >
                        Submit
                    </Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    )
}

export default EditTokenDrawer
