import { Box } from "@chakra-ui/react"

import PageInput from "./PageInput"
import PageSelector from "./PageSelector"
import PageSize from "./PageSize"
import Context, { PaginationState } from "./context"
import { SizeOption, TablePaginationMode } from "./model"

type PaginatorProps = {
    sizeOptions: SizeOption[]
    paginationModes: TablePaginationMode[]
    allPageSize?: number
}

type Props = PaginatorProps & PaginationState

const Paginator: React.FC<Props> = ({
    disabled,
    pageIndex,
    pageSize,
    allPageSize,
    total,
    sizeOptions,
    allowDisplayAllRows,
    paginationModes,
    onPageIndexChange,
    onPageSizeChange,
}) => {
    return (
        <Box
            width="100%"
            d="flex"
            alignItems="center"
            justifyContent="space-between"
            pt="4px"
            pb="4px"
            mt="8px"
            mb="8px"
        >
            <Context.Provider
                value={{
                    disabled,
                    total,
                    pageIndex,
                    pageSize,
                    allowDisplayAllRows,
                    onPageSizeChange,
                    onPageIndexChange,
                }}
            >
                <Box>
                    {paginationModes.includes(TablePaginationMode.PageSize) && (
                        <PageSize sizeOptions={sizeOptions} allPageSize={allPageSize} />
                    )}
                </Box>
                <Box d="flex" alignItems="center" columnGap="16px">
                    {paginationModes.includes(TablePaginationMode.PageSelector) && <PageSelector />}
                    {paginationModes.includes(TablePaginationMode.PageInput) && <PageInput />}
                </Box>
            </Context.Provider>
        </Box>
    )
}

export default Paginator
