import { Button } from "@chakra-ui/react"
import { useState } from "react"
import { useSelector } from "react-redux"

import balanceAPI from "core/apis/balance"
import groupAPI from "core/apis/group"
import walletAPI from "core/apis/wallet"
import useShowToast from "core/hooks/useShowToast"
import { RootState } from "core/redux/reducer"

export enum ExportType {
    BALANCE = "balances",
    WALLET = "wallets",
    GROUP = "groups",
}

type Props = { type: ExportType }
const ExportButton: React.FC<Props> = ({ type }) => {
    const prevParamsBalance = useSelector((state: RootState) => state.balance.prevParams)
    const prevParamsWallet = useSelector((state: RootState) => state.wallet.prevParams)
    const prevParamsGroup = useSelector((state: RootState) => state.group.prevParams)

    const [downloading, setDownloading] = useState(false)
    const { showToastError } = useShowToast()
    const handleClickExportFullData = async () => {
        if (downloading) return
        try {
            setDownloading(true)
            let data: any

            switch (type) {
                case ExportType.BALANCE:
                    if (!prevParamsBalance) throw new Error("Please select Source/Timestamp")
                    data = await balanceAPI.exportCsv(prevParamsBalance)
                    break
                case ExportType.WALLET:
                    data = await walletAPI.exportCsv(prevParamsWallet)
                    break
                case ExportType.GROUP:
                    data = await groupAPI.exportCsv(prevParamsGroup)
                    break
            }
            if (!data) throw new Error("wrong data")
            const href = URL.createObjectURL(data)
            const link = document.createElement("a")
            link.href = href
            link.setAttribute("download", `${type}.csv`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
        } catch (error) {
            console.log(error)
            showToastError(error?.response?.data?.message || error?.toString())
        } finally {
            setDownloading(false)
        }
    }
    return (
        <Button
            disabled={downloading}
            variant="outline"
            colorScheme="teal"
            width="100px"
            borderRadius="4px"
            size="sm"
            padding="0 16px"
            onClick={handleClickExportFullData}
        >
            {downloading ? "Exporting" : "Export"}
        </Button>
    )
}

export default ExportButton
