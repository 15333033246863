import { Box, Flex, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import routes from "constants/routes"
import { Link } from "react-router-dom"

import withPageLayout from "components/withPageLayout"

const StyledLink = styled(Link)`
    font-weight: bold;
    color: var(--chakra-colors-teal-400);
    text-decoration: underline;

    transition: all linear 150ms;

    &:hover,
    &:active {
        color: var(--chakra-colors-teal-700);
    }
`

type Props = {
    route: string
    description: string
}
const RouteInfo: React.FC<Props> = ({ route, description }) => {
    return (
        <Flex gap="8px">
            <StyledLink to={route}>{route}</StyledLink>-<Text as="span">{description}</Text>
        </Flex>
    )
}

const HomePage = () => {
    return (
        <Flex direction="column" height="100%" width="100%" padding="24px 160px">
            <Box flex="0 0" width="100%" d="flex" justifyContent="space-between" alignItems="center">
                <Box d="flex" flexDir="column">
                    <Box as="h1" fontSize="3xl">
                        Asset Overview Service
                    </Box>
                </Box>
            </Box>

            <Flex flexDirection="column" flex="0 0" width="100%" mt="24px" mb="24px" gap="8px">
                {routes.map((el) => (
                    <RouteInfo route={el.path} description={el.desc} key={el.path} />
                ))}
            </Flex>
        </Flex>
    )
}

export default withPageLayout(HomePage)
