import { ForkEffect, call, put, select, takeLatest } from "redux-saga/effects"

import groupAPI, { GroupParams } from "core/apis/group"
import { toast } from "core/redux/toast.slice"
import { GroupPostPayload } from "core/types/group"

import groupSlice from "./slice"

function* getGroupsSaga({ payload }: { payload: GroupParams }) {
    try {
        const response = yield call(groupAPI.getGroupsV2, payload)
        yield put(groupSlice.actions.getGroupsSuccess(response))
    } catch (error) {
        yield put(groupSlice.actions.getGroupsFailure())
        yield put(
            toast({
                description: `Error: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

function* postGroupSaga({ payload }: { payload: GroupPostPayload }) {
    try {
        yield call(groupAPI.postGroup, payload)
        yield put(groupSlice.actions.postGroupRequestSuccess())
        const prevParams = yield select((state) => state.group.prevParams)

        yield getGroupsSaga({ payload: prevParams })
        yield put(
            toast({
                description: payload.id ? "Updated group successfully" : "Added group successfully",
                status: "success",
            })
        )
    } catch (error) {
        yield put(groupSlice.actions.postGroupRequestFailed())
        yield put(
            toast({
                description: `Error: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

function* deleteGroupSaga({ payload }: { payload: number }) {
    const groupID = payload
    try {
        yield call(groupAPI.deleteGroup, groupID)
        yield put(groupSlice.actions.deleteGroupSuccess(groupID))
        yield put(
            toast({
                description: "Deleted group successfully",
                status: "success",
            })
        )
        const prevParams = yield select((state) => state.group.prevParams)
        yield getGroupsSaga({ payload: prevParams })
    } catch (error) {
        yield put(groupSlice.actions.deleteGroupFailure())
        yield put(
            toast({
                description: `Error: ${error?.toString()}`,
                status: "error",
            })
        )
    }
}

export default function* groupSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(groupSlice.actions.getGroupsRequest, getGroupsSaga)
    yield takeLatest(groupSlice.actions.postGroupRequest, postGroupSaga)
    yield takeLatest(groupSlice.actions.deleteGroupRequest, deleteGroupSaga)
}
