import { EditIcon } from "@chakra-ui/icons"
import { Flex, IconButton } from "@chakra-ui/react"
import { useDispatch } from "react-redux"

import { Token } from "core/types"

import tokenSlice from "./slice"

interface Props {
    value: number
    row: {
        original: Token
    }
}

const ActionCell: React.FC<Props> = ({ value }) => {
    const dispatch = useDispatch()

    const handleClickEditButton = () => {
        dispatch(tokenSlice.actions.setSelectedTokenID(value))
    }

    return (
        <Flex columnGap="4px">
            <IconButton
                minWidth="32px"
                width="32px"
                height="32px"
                _hover={{
                    bgColor: "gray.200",
                }}
                _focus={{
                    outline: "none",
                }}
                variant="ghost"
                onClick={handleClickEditButton}
                aria-label="Edit token"
                icon={<EditIcon />}
            />
        </Flex>
    )
}

export default ActionCell
