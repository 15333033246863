import { useToast } from "@chakra-ui/react"
import { useCallback } from "react"

const useShowToast = () => {
    const toast = useToast()
    const showToast = useCallback(
        (msg: string, type: "error" | "success") => {
            toast({
                description: msg,
                position: "top-right",
                isClosable: true,
                status: type,
                duration: 5000,
            })
        },
        [toast]
    )

    const showToastError = useCallback(
        (msg: string) => {
            showToast(msg, "error")
        },
        [showToast]
    )
    return {
        showToastError,
    }
}
export default useShowToast
