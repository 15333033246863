import { ReactNode } from "react"
import { Column } from "react-table"

export enum TableSortDirection {
    ASC = "ASC",
    DESC = "DESC",
}

export interface TableSortInfo {
    direction?: TableSortDirection
    column?: string
}

export interface TableColumnExtra {
    textAlign?: "left" | "center" | "right"
    align?: "start" | "center" | "end"
    sticky?: "left" | "right"
    headerTooltip?: string
    sortable?: boolean | TableSortDirection

    Cell?: ReactNode
    width?: number
    minWidth?: number
    maxWidth?: number
    disableResizing?: boolean

    Header: string | ReactNode
}

console.warn("Make TableColumn's accessor generic")
// export interface TableColumn<T extends object> extends TableColumnExtra {
//     accessor: (string & keyof T) | ((origin: T) => any)
//     Header: string | ReactNode
//     Footer?: string | ReactNode
//     Cell?: ReactNode
//     width?: number
//     minWidth?: number
//     maxWidth?: number
//     disableResizing?: boolean
// }

export type TableColumn<T extends object> = Column<T> & TableColumnExtra
