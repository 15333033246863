import { APIMasterData } from "core/http/type"
import { TokenTransfer } from "core/types/transfer"

const getTokenTransfer = (
    chainID: string,
    walletIDs: number[],
    fromTimestamp: number,
    toTimestamp: number
): Promise<APIMasterData<TokenTransfer[]>> => {
    return
    // return http.getWithAuth("/api/asset-movements", {
    //     chain_id: chainID,
    //     wallet_id: walletIDs,
    //     from_timestamp: fromTimestamp,
    //     to_timestamp: toTimestamp,
    // })
}

const tokenTransferAPI = {
    getTokenTransfer,
}

export default tokenTransferAPI
