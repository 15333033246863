import { useToast } from "@chakra-ui/react"
import { ReactNode, useEffect } from "react"
import { useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"

const ToastContainer = ({ children }: { children: ReactNode }) => {
    const toast = useToast()

    const { id, title, description, position, duration, status, isClosable } = useSelector(
        (state: RootState) => state.toast
    )

    useEffect(() => {
        if (id) {
            toast({ id, title, description, position, duration, status, isClosable })
        }
    }, [toast, id, title, description, position, duration, status, isClosable])

    return children as JSX.Element
}

export default ToastContainer
