import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"

import { CreateOrEditGroupFormValues } from "."

const useEditGroupForm = () => {
    const selectedGroup = useSelector((state: RootState) => {
        const { selectedGroupID: id, groupsResponse } = state.group
        if (!id) {
            return undefined
        }

        return groupsResponse?.groups.find((group) => group.id === id)
    })

    const getDefaultFormValues = useCallback((): CreateOrEditGroupFormValues => {
        return {
            id: selectedGroup?.id,
            name: selectedGroup?.name,
            note: selectedGroup?.note,
            wallet_ids: selectedGroup
                ? selectedGroup.wallets.map((wallet) => ({
                      label: wallet.name,
                      value: wallet.id,
                  }))
                : [],
            cex_accounts: selectedGroup
                ? selectedGroup.cex_accounts.map((account) => ({
                      label: account,
                      value: account,
                  }))
                : [],
        }
    }, [selectedGroup])

    const methods = useForm<CreateOrEditGroupFormValues>({
        defaultValues: getDefaultFormValues(),
        mode: "all",
    })
    const { reset } = methods

    useEffect(() => {
        reset(getDefaultFormValues())
    }, [getDefaultFormValues, reset])

    return methods
}

export default useEditGroupForm
