import { Badge, Box, Flex, Image, Text } from "@chakra-ui/react"
import { Row } from "react-table"

import { Asset } from "core/types/asset"

interface IProps {
    value?: string
    row: Row<Asset>
}

const NameCell: React.FC<IProps> = ({ value: name, row }) => {
    const { symbol, logo_url } = row.original

    return (
        <Box
            d="flex"
            alignItems="center"
            whiteSpace="nowrap"
            columnGap="4px"
            width={"100%"}
            maxWidth={"100%"}
            justifyContent={"space-between"}
        >
            <Flex columnGap={"4px"}>
                <Box height="100%" flex="0 0 24px" d="flex" alignItems="center" justifyContent="center">
                    {logo_url ? (
                        <Image boxSize="24px" src={logo_url} alt={name} />
                    ) : (
                        <Box height="24px" flex="0 0 24px" borderRadius="100%" bgColor="blackAlpha.200" />
                    )}
                </Box>
                <Text
                    as="span"
                    fontSize="sm"
                    style={{ textOverflow: "ellipsis", overflow: "hidden", minWidth: "80px" }}
                >
                    {name}
                </Text>
            </Flex>

            <Box flex="0 0" d="flex" alignItems="center" justifyContent="center">
                <Badge colorScheme="teal" variant="outline">
                    {symbol}
                </Badge>
            </Box>
        </Box>
    )
}

export default NameCell
