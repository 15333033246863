import http from "core/http"
import { APIMasterData } from "core/http/type"
import { Wallet, WalletPostPayload } from "core/types/wallet"

const getWallets = (): Promise<APIMasterData<Wallet[]>> => {
    return
}

export type WalletParams = {
    query?: string
    page?: number
    pageSize: number
}

export type WalletResponse = { wallets: Wallet[]; pagination: { totalItems: number } }

const getWalletsV2 = (params: WalletParams): Promise<WalletResponse> => {
    return http.get("/v1/wallets", params)
}

const putWallet = (payload: WalletPostPayload): Promise<APIMasterData<Wallet[]>> => {
    return http.call({ url: "/v1/wallets", data: payload, method: "POST" })
}

const exportCsv = async (params: WalletParams) => {
    return http.exportCsv({ url: `/v1/wallets/export-csv`, params })
}

const walletAPI = {
    getWallets,
    getWalletsV2,
    putWallet,
    exportCsv,
}

export default walletAPI
