export type SizeOption = {
    label: string
    value: number
}

export enum TablePaginationMode {
    PageSize = "PageSize",
    PageSelector = "PageSelector",
    PageInput = "PageInput",
}
