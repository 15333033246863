import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { Asset } from "core/types/asset"
import { CEXAssetMapping, CEXAssetMappingDeletePayload, CEXAssetMappingPostPayload, Exchange } from "core/types/cex"

interface IState {
    mappings: CEXAssetMapping[]
    selectedID?: number
    isLoading: boolean
    isSubmitting: boolean

    exchanges: Exchange[]
    assets: Asset[]
}

const initialState: IState = {
    mappings: [],
    exchanges: [],
    assets: [],

    isLoading: false,
    isSubmitting: false,
}

const cexMappingSlice = createSlice({
    name: "cexMapping",
    initialState,
    reducers: {
        getMappingsRequest: (state) => {
            state.isLoading = true
        },
        getMappingsSuccess: (state, action: PayloadAction<CEXAssetMapping[]>) => {
            state.mappings = action.payload
            state.isLoading = false
        },
        getMappingsFailure: (state) => {
            state.isLoading = false
        },

        getExchangesRequest: (state) => {
            state.isLoading = true
        },
        getExchangesSuccess: (state, action: PayloadAction<Exchange[]>) => {
            state.exchanges = action.payload
            state.isLoading = false
        },
        getExchangesFailure: (state) => {
            state.isLoading = false
        },

        getAssetsRequest: (state) => {
            state.isLoading = true
        },
        getAssetsSuccess: (state, action: PayloadAction<Asset[]>) => {
            state.assets = action.payload
            state.isLoading = false
        },
        getAssetsFailure: (state) => {
            state.isLoading = false
        },

        postMappingRequest: (state, _action: PayloadAction<CEXAssetMappingPostPayload>) => {
            state.isSubmitting = true
        },
        postMappingSuccess: (state, action: PayloadAction<CEXAssetMapping>) => {
            const { payload } = action
            state.isSubmitting = false
            state.mappings.push(payload)
        },
        postMappingFailure: (state) => {
            state.isSubmitting = false
        },

        deleteMappingRequest: (state, _action: PayloadAction<CEXAssetMappingDeletePayload>) => {
            state.isSubmitting = true
        },
        deleteMappingSuccess: (state, action: PayloadAction<CEXAssetMappingDeletePayload>) => {
            const { id } = action.payload
            state.isSubmitting = false
            const mIndex = state.mappings.findIndex((group) => group.id === id)
            if (mIndex === -1) {
                console.error(`Can't find mapping with ID: ${id} after updating`)
            } else {
                state.mappings.splice(mIndex, 1)
            }
        },
        deleteMappingFailure: (state) => {
            state.isSubmitting = false
        },

        setSelectedID: (state, action: PayloadAction<number | undefined>) => {
            state.selectedID = action.payload
        },
    },
})

export default cexMappingSlice
