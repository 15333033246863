import { PayloadAction, createSlice } from "@reduxjs/toolkit"

import { BalanceParams } from "core/apis/balance"
import { Asset } from "core/types/asset"
import { BalanceResponseV2 } from "core/types/balance"
import { getTimestampFromDateBalance } from "pages/Balance/UtilityBar/DateSelect"

interface IState {
    isLoading: boolean

    timestamp1: number
    timestamp2: number
    chainID: string

    prevParams: BalanceParams[]
    balanceResponses: BalanceResponseV2[]
    visibleAssets: Asset[]

    settings: {
        visibleAssetIDs: number[]
        hiddenAssetIDs: number[]
        hideZeroDiffAssets: boolean
    }
}

const initialState: IState = {
    isLoading: false,

    timestamp1: getTimestampFromDateBalance(new Date()),
    timestamp2: getTimestampFromDateBalance(new Date()),
    chainID: "0x1",

    prevParams: [],
    balanceResponses: [],
    visibleAssets: [],

    settings: {
        visibleAssetIDs: [],
        hiddenAssetIDs: [],
        hideZeroDiffAssets: false,
    },
}

const balanceComparisonSlice = createSlice({
    name: "balanceComparison",
    initialState,
    reducers: {
        setPrevParams: (state, action: PayloadAction<BalanceParams[]>) => {
            state.prevParams = action.payload
        },
        // get balances
        getBalanceDiffRequest: (state) => {
            state.isLoading = true
        },
        getBalanceDiffSuccess: (state, action: PayloadAction<{ balanceResponses: BalanceResponseV2[] }>) => {
            state.isLoading = false
            state.balanceResponses = action.payload.balanceResponses
        },
        getBalanceDiffFailure: (state) => {
            state.isLoading = false
        },

        setTimestamp1: (state, action: PayloadAction<number>) => {
            state.timestamp1 = action.payload
        },
        setTimestamp2: (state, action: PayloadAction<number>) => {
            state.timestamp2 = action.payload
        },
        setChainId: (state, action: PayloadAction<string>) => {
            state.chainID = action.payload
        },

        setHiddenAssetIDs: (state, action: PayloadAction<number[]>) => {
            state.settings.hiddenAssetIDs = action.payload
        },
        setVisibleAssetIDs: (state, action: PayloadAction<number[]>) => {
            state.settings.visibleAssetIDs = action.payload
        },
        setHideZeroDiffAssets: (state, action: PayloadAction<boolean>) => {
            state.settings.hideZeroDiffAssets = action.payload
        },
    },
})

export default balanceComparisonSlice
