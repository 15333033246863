import styled from "styled-components"

interface IProps {
    className?: string
}

const KyberIcon: React.FC<IProps> = ({ className }) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 360 360"
            xmlSpace="preserve"
            className={className}
        >
            <g>
                <path
                    fill="currentColor"
                    d="M161.4,179.9L296.1,257c1.1,0.6,2.3,0.9,3.5,0.9c1.2,0,2.5-0.3,3.5-0.9c1.1-0.6,2-1.5,2.6-2.6
		c0.6-1.1,1-2.3,1-3.5l0-142c0-1.2-0.4-2.4-1-3.5c-0.6-1.1-1.5-2-2.6-2.6c-1.1-0.6-2.3-0.9-3.5-0.9c-1.2,0-2.5,0.3-3.5,0.9
		L161.4,179.9z"
                />
                <path
                    fill="currentColor"
                    d="M288.3,69.6L198.9,1.4c-1-0.7-2.1-1.2-3.3-1.4c-1.2-0.2-2.4,0-3.5,0.4s-2.1,1.1-2.9,2c-0.8,0.9-1.3,2-1.6,3.1
		l-32.7,151.9l132.7-76c1-0.6,1.9-1.4,2.5-2.4c0.6-1,1-2.1,1.1-3.3c0.1-1.2-0.2-2.3-0.7-3.4C290,71.3,289.2,70.3,288.3,69.6
		L288.3,69.6z"
                />
                <path
                    fill="currentColor"
                    d="M198.8,358.5l89.7-68.3c0.9-0.7,1.7-1.6,2.2-2.7c0.5-1.1,0.7-2.2,0.6-3.4c-0.1-1.2-0.4-2.3-1-3.3
		c-0.6-1-1.5-1.8-2.5-2.4l-132.9-76l32.8,151.9c0.2,1.2,0.8,2.2,1.5,3.2c0.8,0.9,1.8,1.6,2.9,2c1.1,0.4,2.3,0.5,3.5,0.4
		C196.7,359.7,197.9,359.3,198.8,358.5L198.8,358.5z"
                />
                <path
                    fill="currentColor"
                    d="M126.8,179.9l35.3-163.6c0.3-1.4,0.2-2.9-0.4-4.2c-0.6-1.3-1.5-2.5-2.8-3.2c-1.2-0.8-2.7-1.1-4.1-1.1
		c-1.5,0.1-2.8,0.6-4,1.5L60.6,78.1c-2.3,1.7-4.1,4-5.4,6.6c-1.3,2.6-1.9,5.4-1.9,8.3l0,174.3c0,2.9,0.7,5.7,1.9,8.3
		s3.1,4.8,5.4,6.6l90.3,68.7c1.1,0.9,2.5,1.4,4,1.4c1.4,0.1,2.9-0.3,4.1-1.1c1.2-0.8,2.2-1.9,2.8-3.2c0.6-1.3,0.7-2.8,0.5-4.2
		L126.8,179.9z"
                />
            </g>
        </svg>
    )
}

export default styled(KyberIcon)`
    color: #31cb9e;
    width: auto;
    height: 100%;
`
