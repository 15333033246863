import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { DefaultPageSize } from "components/Paginator/consts"
import Table from "components/Table"
import { TableColumn } from "components/Table/model"
import { RootState } from "core/redux/reducer"
import { Wallet } from "core/types/wallet"
import AddressCell from "pages/Token/AddressCell"

import ActionCell from "./ActionCell"
import GroupsCell from "./GroupsCell"
import TypeCell from "./TypeCell"
import walletSlice from "./slice"

const columns: TableColumn<Wallet>[] = [
    {
        Header: "ID",
        accessor: "id",
        width: 32,
    },
    {
        Header: "Name",
        accessor: "name",
        width: 180,
    },
    {
        Header: "Address",
        accessor: "address",
        Cell: AddressCell,
        width: 150,
    },
    {
        Header: "Description",
        accessor: "description",
    },
    {
        Header: "Type",
        accessor: "type",
        Cell: TypeCell,
        width: 50,
    },
    {
        Header: "Groups",
        accessor: "groups",
        Cell: GroupsCell,
    },
    {
        Header: "Action",
        accessor: (w: Wallet) => w,
        Cell: ActionCell,
        sticky: "right",
        width: 80,
        align: "center",
    },
]

const WalletTable: React.FC = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector((state: RootState) => state.wallet.isLoading)
    const wallets = useSelector((state: RootState) => state.wallet.walletResponse?.wallets)
    const totalWallet = useSelector((state: RootState) => state.wallet.walletResponse?.pagination?.totalItems)
    const allPageSize = useSelector((state: RootState) => state.wallet.allPageSize)
    const searchText = useSelector((state: RootState) => state.wallet.searchText)

    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(DefaultPageSize)

    const filteredWallets = useMemo(() => {
        return wallets || []
    }, [wallets])

    const params = useMemo(() => {
        return { query: searchText, page: pageIndex + 1, pageSize }
    }, [searchText, pageIndex, pageSize])

    useEffect(() => {
        setPageIndex(0)
    }, [searchText])

    useEffect(() => {
        dispatch(walletSlice.actions.getWalletsRequest(params))
    }, [dispatch, params])

    return (
        <Table
            allowDisplayAllRows
            pageIndex={pageIndex}
            pageSize={pageSize}
            handlePageIndex={setPageIndex}
            handlePageSize={setPageSize}
            isLoading={isLoading}
            total={totalWallet}
            columns={columns as TableColumn<{}>[]}
            data={filteredWallets}
            allPageSize={allPageSize}
        />
    )
}

export default WalletTable
