import { Box, Select, Text } from "@chakra-ui/react"
import React, { ChangeEvent, useContext, useMemo } from "react"

import { SizeOptions } from "./consts"
import PaginationContext from "./context"
import { SizeOption } from "./model"

interface IProps {
    className?: string
    sizeOptions?: SizeOption[]
    disabled?: boolean
    allPageSize?: number
}

const PageSize: React.FC<IProps> = ({ disabled, sizeOptions = SizeOptions, allPageSize }) => {
    const {
        pageSize,
        onPageIndexChange,
        onPageSizeChange,
        total,
        pageIndex,
        allowDisplayAllRows,
        disabled: disabledContext,
    } = useContext(PaginationContext)
    const isDisabled = disabled || disabledContext

    const options = useMemo(() => {
        if (!allowDisplayAllRows || total > 1000) {
            return sizeOptions
        }

        return [...sizeOptions, { label: "All", value: allPageSize || total }]
    }, [allowDisplayAllRows, sizeOptions, total, allPageSize])

    const selectedOption = options.find((option) => option.value === pageSize)

    const handleChangeSize = (e: ChangeEvent<HTMLSelectElement>) => {
        const value = parseInt(e.target.value, 10)
        const option = options.find((option) => option.value === value)

        if (!option) {
            return
        }

        if (onPageSizeChange) onPageSizeChange(option.value)
        if (onPageIndexChange) onPageIndexChange(0)
    }

    const helperText = `${pageSize * pageIndex + 1} - ${Math.min(pageSize * (pageIndex + 1), total)} of ${total}`

    return (
        <Box
            d="flex"
            alignItems="center"
            columnGap="8px"
            color={isDisabled ? "gray.300" : undefined}
            pointerEvents={isDisabled ? "none" : undefined}
        >
            <Text fontSize="sm" color="inherit">
                Rows per page
            </Text>

            <Select
                borderRadius="4px"
                boxShadow="none"
                width="84px"
                size="sm"
                value={selectedOption?.value}
                onChange={handleChangeSize}
                color="inherit"
            >
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>

            <Text mr="8px" fontSize="sm" color="inherit">
                {helperText}
            </Text>
        </Box>
    )
}

export default PageSize
