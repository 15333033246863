import { ArrowDownIcon, InfoIcon } from "@chakra-ui/icons"
import { Spinner, Tooltip } from "@chakra-ui/react"
import cx from "classnames"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { useColumnOrder, useExpanded, useFlexLayout, useResizeColumns, useRowSelect, useTable } from "react-table"
import styled from "styled-components"

import Paginator from "components/Paginator"
import { SizeOptions } from "components/Paginator/consts"
import { SizeOption, TablePaginationMode } from "components/Paginator/model"
import { EmptyArray } from "core/globalConstants"
import { animSpeed, color, typo } from "theme"

import ErrorScreen from "./components/ErrorScreen"
import Resizer from "./components/Resizer"
import { TableColumn, TableSortDirection, TableSortInfo } from "./model"
import prepareColumns from "./prepareColumns"

interface TableProps<T extends object> {
    className?: string
    data: T[]
    columns: TableColumn<T>[]
    isExpandable?: boolean
    isSelectable?: boolean
    isLoading?: boolean
    isError?: boolean
    isEmpty?: boolean
    handleRowSelect?: (selected: object[]) => void
    handleColumnSort?: (sort: TableSortInfo) => void
    isResizable?: boolean
    hiddenColumns?: string[]
    columnOrder?: string[]
    rowSize?: "m" | "l"

    total?: number
    pageIndex?: number
    pageSize?: number
    sizeOptions?: SizeOption[]
    allowDisplayAllRows?: boolean
    handlePageIndex?: (index: number) => void
    handlePageSize?: (size: number) => void
    paginationModes?: TablePaginationMode[]
    allPageSize?: number
    overlay?: boolean
}

const DEFAULT_PAGINATION_MODES = Object.values(TablePaginationMode)

const Table = <T extends object>({
    className,
    data,
    columns,
    isExpandable,
    isSelectable,
    isLoading,
    isError,
    isEmpty,
    handleRowSelect,
    handleColumnSort,
    isResizable = true,
    hiddenColumns = EmptyArray,
    columnOrder = EmptyArray,
    rowSize = "l",

    total,
    pageIndex = 1,
    pageSize = SizeOptions[0].value,
    sizeOptions = SizeOptions,
    allowDisplayAllRows,
    handlePageSize,
    handlePageIndex,
    paginationModes = DEFAULT_PAGINATION_MODES,
    allPageSize,
    overlay = true,
}: TableProps<T>) => {
    const doneFirstRenderRef = useRef(false)
    const tableScrollRef = useRef<HTMLDivElement>(null)
    const sortableColRef = useRef<Record<string, HTMLDivElement>>({})

    const preparedColumns = useMemo(() => prepareColumns({ columns, isExpandable, isSelectable }), [
        columns,
        isExpandable,
        isSelectable,
    ])

    const defaultColumn = useMemo(
        () => ({
            width: 150,
            minWidth: 40,
            disableResizing: !isResizable,
        }),
        [isResizable]
    )

    const {
        rows,
        prepareRow,
        headerGroups,
        selectedFlatRows,
        getTableBodyProps,
        getTableProps,
        setColumnOrder,
        setHiddenColumns,
    } = useTable(
        {
            data,
            defaultColumn,
            columns: preparedColumns,
        },
        useExpanded,
        useResizeColumns,
        useColumnOrder,
        useFlexLayout,
        useRowSelect
    )

    const setupSortColRef = (column: string) => (ref: HTMLDivElement) => {
        sortableColRef.current[column] = ref
    }

    const handleTableSort = (column: string) => () => {
        console.warn("Sort is not yet fully implemented")
        if (!handleColumnSort) return

        Object.entries(sortableColRef.current).forEach(([key, ref]) => {
            if (key !== column) {
                ref.setAttribute("data-sort-dir", "false")
                return
            }

            const sortDir = ref.getAttribute("data-sort-dir") as TableSortDirection
            switch (sortDir) {
                case TableSortDirection.ASC: {
                    ref.setAttribute("data-sort-dir", "false")
                    handleColumnSort({})
                    break
                }
                case TableSortDirection.DESC: {
                    ref.setAttribute("data-sort-dir", TableSortDirection.ASC)
                    handleColumnSort({ column, direction: TableSortDirection.ASC })
                    break
                }
                default: {
                    ref.setAttribute("data-sort-dir", TableSortDirection.DESC)
                    handleColumnSort({ column, direction: TableSortDirection.DESC })
                    break
                }
            }
        })
    }

    const renderOverlay = () => {
        if (isLoading) {
            return (
                <div className="loadingOverlay">
                    <Spinner size="md" colorScheme="teal" />
                </div>
            )
        }

        if (isError) {
            return <ErrorScreen type="general" />
        }

        if (!overlay) return null
        if (isEmpty || !data.length) {
            return <ErrorScreen type="empty_data" />
        }

        return null
    }

    const handleScroll = useCallback(() => {
        const tableScroll = tableScrollRef.current
        if (!tableScroll) return

        const { scrollLeft, scrollWidth, clientWidth } = tableScroll

        tableScroll.setAttribute("data-scroll-left", String(scrollLeft > 0))
        tableScroll.setAttribute("data-scroll-right", String(scrollLeft < scrollWidth - clientWidth))
    }, [])

    const hiddenColumnsDep = hiddenColumns.join()
    useEffect(() => {
        setHiddenColumns(hiddenColumns)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setHiddenColumns, hiddenColumnsDep])

    const columnOrderDep = columnOrder.join()
    useEffect(() => {
        setColumnOrder(columnOrder)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setColumnOrder, columnOrderDep])

    const selectedRowOriginal = selectedFlatRows.map((row) => row.original)
    const selectedRowOriginalDep = JSON.stringify(selectedRowOriginal)
    useEffect(() => {
        // Add doneFirstRenderRef.current to prevent handleRowSelect from being called in the first render
        // When there're no rows selected
        if (!isSelectable || !doneFirstRenderRef.current) return

        handleRowSelect && handleRowSelect(selectedRowOriginal)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectable, selectedRowOriginalDep])

    useEffect(() => {
        if (columns) {
            // Need to call this when columns is updated
            // Initially, columns is [] and the table is not overflowing
            handleScroll()
        }
    }, [columns, handleScroll])

    useEffect(() => {
        doneFirstRenderRef.current = true
    }, [])

    return (
        <div className={className}>
            <div className="tableWrapper">
                <div className="tableScroll" ref={tableScrollRef} onScroll={handleScroll}>
                    <div
                        className={cx("tableContent", {
                            rowSizeM: rowSize === "m",
                            rowSizeL: rowSize === "l",
                        })}
                        {...getTableProps()}
                    >
                        <div className="header">
                            {headerGroups.map(({ getHeaderGroupProps, headers }, index) => (
                                <div key={`header-${index}`} className="row" {...getHeaderGroupProps()}>
                                    {headers.map(
                                        ({
                                            id,
                                            align,
                                            sticky,
                                            render,
                                            sortable,
                                            canResize,
                                            isResizing,
                                            headerTooltip,
                                            getHeaderProps,
                                            getResizerProps,
                                        }) => (
                                            <div
                                                key={id}
                                                className={cx("cell", {
                                                    sortable: !!sortable,
                                                    isResizing: !!isResizing,

                                                    alignStart: align === "start",
                                                    alignCenter: align === "center",
                                                    alignEnd: align === "end",

                                                    sticky: !!sticky,
                                                    stickyLeft: sticky === "left",
                                                    stickyRight: sticky === "right",
                                                })}
                                                {...getHeaderProps()}
                                                {...(sortable && { onClick: handleTableSort(id) })}
                                            >
                                                <div className="textTruncate">{render("Header")}</div>

                                                {headerTooltip ? (
                                                    <Tooltip label={headerTooltip} placement="top">
                                                        <InfoIcon color="gray.400" width="3" ml="4px" />
                                                    </Tooltip>
                                                ) : null}

                                                {sortable ? (
                                                    <div className="sortArrow" ref={setupSortColRef(id)}>
                                                        <ArrowDownIcon />
                                                    </div>
                                                ) : null}

                                                {isResizable && canResize ? (
                                                    <Resizer isResizing={isResizing} {...getResizerProps()} />
                                                ) : null}
                                            </div>
                                        )
                                    )}
                                </div>
                            ))}
                        </div>
                        <div className="body" {...getTableBodyProps()}>
                            {rows.map((row, index) => {
                                prepareRow(row)

                                return (
                                    <div
                                        key={`row-${index}`}
                                        className={cx("row", { active: row.isSelected })}
                                        {...row.getRowProps()}
                                    >
                                        {row.cells.map(({ column, getCellProps, render }) => {
                                            const { id, align, sticky, isResizing } = column
                                            return (
                                                <div
                                                    className={cx("cell", {
                                                        isResizing: !!isResizing,

                                                        alignStart: align === "start",
                                                        alignCenter: align === "center",
                                                        alignEnd: align === "end",

                                                        sticky: !!sticky,
                                                        stickyLeft: sticky === "left",
                                                        stickyRight: sticky === "right",
                                                    })}
                                                    key={id}
                                                    {...getCellProps()}
                                                >
                                                    {render("Cell")}
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>

                {renderOverlay()}
            </div>

            {Boolean(total && data?.length) && (
                <Paginator
                    disabled={isLoading}
                    total={total}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    sizeOptions={sizeOptions}
                    allowDisplayAllRows={allowDisplayAllRows}
                    paginationModes={paginationModes}
                    onPageIndexChange={handlePageIndex}
                    onPageSizeChange={handlePageSize}
                    allPageSize={allPageSize}
                />
            )}
        </div>
    )
}

export default styled(Table)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .tableWrapper {
        width: 100%;
        flex: 1 1 100%;
        position: relative;
        color: ${color.ink[500]};
        overflow: hidden;
    }

    .loadingOverlay {
        width: 100%;
        height: 100%;
        background-color: ${color.white[400]};
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 4px;
        border: 1px solid ${color.ink[200]};
    }

    .tableScroll {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        color: ${color.ink[500]};
        border-radius: 4px;
        border: 1px solid ${color.ink[200]};
        overflow: auto;

        /* Shadow when scrolling */
        &[data-scroll-left="true"] {
            .cell.stickyLeft::before {
                content: "";
                width: 6px;
                height: calc(100% + 1px);
                background: linear-gradient(to left, transparent, rgba(34, 49, 63, 0.12));
                position: absolute;
                right: -7px;
                top: 0;
            }
        }

        /* Shadow when scrolling */
        &[data-scroll-right="true"] {
            .cell.stickyRight::before {
                content: "";
                width: 6px;
                height: calc(100% + 1px);
                background: linear-gradient(to right, transparent, rgba(34, 49, 63, 0.12));
                position: absolute;
                left: -7px;
                top: 0;
            }
        }
    }

    .tableContent {
        display: table;

        &.rowSizeM {
            .body .row {
                min-height: 40px;
            }
        }

        &.rowSizeL {
            .body .row {
                min-height: 48px;
            }
        }
    }

    .header {
        position: sticky;
        z-index: 3;
        top: 0;

        .row {
            height: 40px;

            .cell:not(.sticky) + .cell:not(.sticky) {
                border-left: 1px solid ${color.ink[200]};
            }

            .cell {
                font-weight: 500;
                user-select: none;
                background-color: ${color.backgroundDark};
                box-shadow: 0px 1px 0 0px ${color.ink[200]};
                ${typo.T14_M}

                &:hover {
                    .resizer {
                        opacity: 1;
                    }
                }

                &.isResizing {
                    .resizer {
                        opacity: 1;
                    }
                }

                &:last-child {
                    .resizer {
                        right: 0px;
                    }
                }

                &.sortable {
                    transition: background-color ${animSpeed.fast};
                    position: relative;

                    &:hover {
                        background-color: ${color.ink[200]};
                        cursor: pointer;

                        .sortArrow:not([data-sort-dir="DESC"]):not([data-sort-dir="ASC"]) {
                            color: ${color.ink[400]};
                        }
                    }

                    .sortArrow {
                        transition-property: color, transform;
                        transition-duration: ${animSpeed.fast};
                        color: ${color.ink[300]};
                        margin-left: 4px;
                        line-height: 0;

                        &[data-sort-dir="DESC"] {
                            transform: rotate(0deg);
                            color: ${color.primary[500]};
                        }

                        &[data-sort-dir="ASC"] {
                            transform: rotate(-180deg);
                            color: ${color.primary[500]};
                        }
                    }
                }
            }
        }
    }

    .body {
        position: relative;
    }

    /* Style of row when active or hovered */
    .row {
        &.active {
            .cell {
                background-color: ${color.primary[100]};
            }
        }

        &:hover {
            .cell {
                background-color: ${color.backgroundLight};
            }
        }
    }

    /* Style of both Header and Body cells */
    .row .cell,
    .row .cell {
        position: relative;

        display: flex;
        align-items: center;

        padding: 0 8px;
        background-color: ${color.white[500]};
        border-bottom: 1px solid ${color.ink[200]};

        transition: background-color ${animSpeed.extraFast};

        white-space: nowrap;
        ${typo.T14_R}

        &.isResizing:before {
            content: "";
            width: 1px;
            height: calc(100% + 1px);
            background-color: ${color.primary[300]};
            position: absolute;
            z-index: 2;
            right: -1px;
            top: -1px;
        }

        &:last-child {
            &.isResizing:before {
                right: 7px;
            }
        }

        &.alignStart {
            justify-content: flex-start;
        }

        &.alignCenter {
            justify-content: center;
        }

        &.alignEnd {
            justify-content: flex-end;
        }

        &.sticky {
            position: sticky !important;
            z-index: 2;

            &.stickyLeft {
                left: 0;
                border-right: 1px solid ${color.ink[200]};
            }

            &.stickyRight {
                right: 0;
                border-left: 1px solid ${color.ink[200]};
            }
        }
    }

    /* Style of Paginator */
    .paginator {
        width: 100%;
        display: flex;
        margin-top: 8px;
        padding-top: 4px;
        padding-bottom: 4px;
        flex: 0 0 auto;
    }
`
