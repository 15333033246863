import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { DefaultPageSize } from "components/Paginator/consts"
import Table from "components/Table"
import { TableColumn } from "components/Table/model"
import { RootState } from "core/redux/reducer"
import { Group } from "core/types/group"
import groupSlice from "pages/Group/slice"

import ActionCell from "./ActionCell"
import ChildCEXAccountsCell from "./ChildCEXAccountsCell"
import ChildWalletsCell from "./ChildWalletsCell"

const columns: TableColumn<Group>[] = [
    {
        Header: "ID",
        accessor: "id",
        width: 32,
    },
    {
        Header: "Name",
        accessor: "name",
        width: 200,
    },
    {
        Header: "Note",
        accessor: "note",
        width: 150,
    },
    {
        Header: "Wallets",
        accessor: "wallets",
        Cell: ChildWalletsCell,
        width: 500,
    },
    {
        Header: "CEX Accounts",
        accessor: "cex_accounts",
        Cell: ChildCEXAccountsCell,
        width: 500,
    },
    {
        Header: "Action",
        accessor: (g: Group) => g.id,
        Cell: ActionCell,
        sticky: "right",
        width: 80,
        maxWidth: 80,
        align: "center",
    },
]

const GroupTable: React.FC = () => {
    const dispatch = useDispatch()

    const isLoading = useSelector((state: RootState) => state.group.isLoading)
    const groups = useSelector((state: RootState) => state.group.groupsResponse?.groups)
    const totalItems = useSelector((state: RootState) => state.group.groupsResponse?.pagination.totalItems)
    const searchText = useSelector((state: RootState) => state.group.searchText)
    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(DefaultPageSize)
    const allPageSize = useSelector((state: RootState) => state.group.allPageSize)

    const params = useMemo(() => {
        return { query: searchText, page: pageIndex + 1, pageSize }
    }, [searchText, pageIndex, pageSize])

    useEffect(() => {
        setPageIndex(0)
    }, [searchText])

    useEffect(() => {
        dispatch(groupSlice.actions.getGroupsRequest(params))
    }, [dispatch, params])

    const filteredGroups = useMemo(() => {
        return groups || []
    }, [groups])

    return (
        <Table
            allowDisplayAllRows
            pageIndex={pageIndex}
            pageSize={pageSize}
            handlePageIndex={setPageIndex}
            handlePageSize={setPageSize}
            isLoading={isLoading}
            total={totalItems}
            columns={columns as TableColumn<{}>[]}
            data={filteredGroups}
            allPageSize={allPageSize}
        />
    )
}

export default GroupTable
