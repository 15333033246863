import { AddIcon } from "@chakra-ui/icons"
import {
    Button,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    useDisclosure,
} from "@chakra-ui/react"
import { useEffect } from "react"
import { FormProvider } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"
import { GroupPostPayload } from "core/types/group"

import CreateOrEditGroupForm from "./CreateOrEditGroupForm"
import useCreateGroupForm from "./CreateOrEditGroupForm/useCreateGroupForm"
import groupSlice from "./slice"

const AddGroup: React.FC = () => {
    const dispatch = useDispatch()
    const groups = useSelector((state: RootState) => state.group.groupsResponse?.groups)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const methods = useCreateGroupForm()
    const { reset } = methods

    const isSubmitting = false

    const handleClose = () => {
        onClose()
    }

    const handleClick = () => {
        onOpen()
    }

    const handleClickSubmit = () => {
        const values = methods.getValues()

        let uniqueWalletIDs: number[] = [...values.wallet_ids.map((option) => option.value as number)]
        values.group_ids
            .map((option) => option.value as number)
            .forEach((id) => {
                const group = groups.find((group) => group.id === id)
                if (group) {
                    uniqueWalletIDs.push(...group.wallets.map((wallet) => wallet.id).filter(Boolean))
                }
            })
        uniqueWalletIDs = [...new Set(uniqueWalletIDs)]

        const payload: GroupPostPayload = {
            name: values.name,
            note: values.note,
            wallet_ids: uniqueWalletIDs,
            cex_accounts: values.cex_accounts.map((option) => option.value as string),
        }

        dispatch(groupSlice.actions.postGroupRequest(payload))
    }

    useEffect(() => {
        if (!isOpen) {
            reset()
        }
    }, [isOpen, reset])

    return (
        <>
            <Button borderRadius="4px" size="sm" colorScheme="teal" leftIcon={<AddIcon />} onClick={handleClick}>
                Add group
            </Button>

            <Drawer size="md" isOpen={isOpen} placement="right" onClose={handleClose}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Create new group</DrawerHeader>

                    <DrawerBody paddingTop="16px">
                        <FormProvider {...methods}>
                            <CreateOrEditGroupForm />
                        </FormProvider>
                    </DrawerBody>

                    <DrawerFooter>
                        <Button variant="outline" mr={3} onClick={handleClose} disabled={isSubmitting}>
                            Cancel
                        </Button>
                        <Button
                            isLoading={isSubmitting}
                            loadingText="Submitting"
                            colorScheme="teal"
                            onClick={methods.handleSubmit(handleClickSubmit)}
                        >
                            Submit
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default AddGroup
