import { CalendarIcon } from "@chakra-ui/icons"
import {
    Box,
    Button,
    Flex,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverFooter,
    PopoverTrigger,
    Text,
} from "@chakra-ui/react"
import { formatGMT } from "utils/date"

import DateTimePicker from "components/_base/DateTimePicker"

interface IProps {
    className?: string
    title: string
    minDate?: Date
    maxDate?: Date
    date: Date
    showSeconds?: boolean
    dateFormatter?: (d: Date) => string
    onChangeDate: (date: Date) => void
}

const InlineDateTimePicker: React.FC<IProps> = ({
    className,
    title,
    date,
    onChangeDate,
    dateFormatter,
    showSeconds = true,
}) => {
    return (
        <Popover autoFocus isLazy>
            <PopoverTrigger>
                <Box className={className} flex="0 0 100px">
                    <Button
                        variant="outline"
                        width="100%"
                        borderRadius="4px"
                        size="sm"
                        padding="0 16px"
                        rightIcon={
                            <CalendarIcon
                                transitionProperty="color"
                                transitionDuration="150ms"
                                transitionTimingFunction="ease-in-out"
                                color="gray.600"
                            />
                        }
                        fontWeight="normal"
                    >
                        {title}:
                        <Text ml="4px" fontWeight="semibold">
                            {dateFormatter ? dateFormatter(date) : date.toString()}
                        </Text>
                    </Button>
                </Box>
            </PopoverTrigger>
            <PopoverContent
                boxShadow="lg"
                width="min-content"
                _focus={{
                    outline: "none",
                }}
            >
                <PopoverArrow />
                <PopoverBody padding="12px" width="min-content">
                    <DateTimePicker date={date} onChangeDate={onChangeDate} showSeconds={showSeconds} />
                </PopoverBody>

                <PopoverFooter
                    border="0"
                    borderTop="1px solid"
                    borderTopColor="gray.200"
                    d="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    p="3"
                >
                    <Flex flexDirection="column" fontSize="sm" color="gray.600" fontWeight="semibold">
                        <Text as="span">{formatGMT(date)}</Text>
                    </Flex>

                    <Button
                        size="sm"
                        variant="outline"
                        onClick={() => {
                            onChangeDate(new Date())
                        }}
                    >
                        Now
                    </Button>
                </PopoverFooter>
            </PopoverContent>
        </Popover>
    )
}

export default InlineDateTimePicker
