import { Text } from "@chakra-ui/react"
import { Cell } from "react-table"

import { NullSymbol } from "core/globalConstants"

import { NullCell } from "./NullCell"

const GenericCell: React.FC<Cell<any>> = ({ value }) => {
    if (value === NullSymbol || value === undefined || value === null) {
        return <NullCell />
    }

    return <Text isTruncated>{value}</Text>
}

export default GenericCell
