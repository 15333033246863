import formatInTimeZone from "date-fns-tz/formatInTimeZone"

import { DEFAULT_FORMAT_DATE_STRING } from "core/globalConstants"

export const formatGMT = (date: Date, pattern = DEFAULT_FORMAT_DATE_STRING) => {
    return formatInTimeZone(date, "GMT", pattern)
}

export const formatCompactISO = (date: Date) => {
    // '2023-03-07T06:46:00.123Z' => '2023-03-07T06:46:00Z'
    return date.toISOString().slice(0, 19) + "Z"
}
