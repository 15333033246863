import http from "core/http"
import { APIMasterData } from "core/http/type"
import { Group, GroupPostPayload } from "core/types/group"

const getGroups = (): Promise<APIMasterData<Group[]>> => {
    return //http.getWithAuth("/api/groups")
}

export type GroupsResponse = { groups: Group[]; pagination: { totalItems: number } }
export type GroupParams = {
    query?: string
    page?: number
    pageSize: number
}

const getGroupsV2 = (params: GroupParams): Promise<GroupsResponse> => {
    return http.get(`/v1/groups`, params)
}

const postGroup = (payload: GroupPostPayload): Promise<APIMasterData<Group>> => {
    return http.call({ url: "/v1/groups", data: payload, method: "POST" })
}

const deleteGroup = (groupID: number): Promise<APIMasterData<{}>> => {
    return http.call({ url: `/v1/groups/${groupID}`, method: "DELETE" })
}

const exportCsv = async (params: GroupParams) => {
    return http.exportCsv({ url: `/v1/groups/export-csv`, params })
}

const groupAPI = {
    getGroups,
    getGroupsV2,
    postGroup,
    deleteGroup,
    exportCsv,
}

export default groupAPI
