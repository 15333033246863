import { Flex, Spinner, Tooltip } from "@chakra-ui/react"
import { Eye, Info } from "react-feather"

import { Balance } from "core/types/balance"
import { getWalletId } from "pages/Balance/BalanceTable"
import { TabTypes } from "pages/Balance/BalanceTable/DetailAsset"

export default function ActionCell({
    showDetail,
    fetchingWallet,
    balance,
    showWarning,
}: {
    showDetail: (v: Balance, tab?: TabTypes) => void
    fetchingWallet: string | number
    balance: Balance
    showWarning?: boolean
}) {
    const { tokens_without_asset = [] } = balance
    const walletId = getWalletId(balance)
    const showTokenWarning = showWarning && tokens_without_asset.length > 0
    if (walletId === fetchingWallet) return <Spinner />
    return (
        <Flex alignItems={"center"} sx={{ gap: "10px", justifyContent: "space-between" }}>
            {showTokenWarning && (
                <Tooltip
                    label={`There are ${tokens_without_asset.length} tokens without assets, click to see detail`}
                    placement="top"
                >
                    <div>
                        <Info
                            color={"orange"}
                            style={{ cursor: "pointer", top: -10 }}
                            size={18}
                            onClick={() => showDetail(balance, TabTypes.TOKENS)}
                        />
                    </div>
                </Tooltip>
            )}
            <Tooltip label={"See detail"} placement="top">
                <div>
                    <Eye
                        style={{ cursor: "pointer" }}
                        size={18}
                        onClick={() => showDetail(balance, TabTypes.BALANCE)}
                    />
                </div>
            </Tooltip>
        </Flex>
    )
}
