import { DeleteIcon, PlusSquareIcon } from "@chakra-ui/icons"
import { Flex, Grid, IconButton, Input, Text } from "@chakra-ui/react"

import { RequestAttribute } from "./types"

const templateColumns = "160px 1fr 40px"

type Props = {
    className?: string
    attributes: RequestAttribute[]
    onClickAdd: () => void
    onClickRemove: (id: string) => void
    onUpdateKey: (id: string, key: string) => void
    onUpdateValue: (id: string, value: string) => void
}

const AttributePanel: React.FC<Props> = ({
    className,
    attributes,
    onClickAdd,
    onClickRemove,
    onUpdateKey,
    onUpdateValue,
}) => {
    return (
        <Flex height="100%" width="100%" flexDirection="column" className={className} overflow="hidden">
            <Grid
                alignItems="center"
                gridTemplateColumns={templateColumns}
                borderColor="gray.200"
                borderBottomWidth="1px"
                gap="8px"
                flex="0"
                paddingBottom="4px"
            >
                <Text fontSize="sm" paddingLeft="12px" as="span" fontWeight="semibold">
                    KEY
                </Text>
                <Text fontSize="sm" paddingLeft="12px" as="span" fontWeight="semibold">
                    VALUE
                </Text>
                <IconButton
                    minWidth="32px"
                    width="32px"
                    height="32px"
                    colorScheme="blue"
                    _focus={{
                        border: "none",
                    }}
                    variant="ghost"
                    aria-label="remove this key value"
                    icon={
                        <PlusSquareIcon
                            transitionProperty="color"
                            transitionDuration="50ms"
                            transitionTimingFunction="ease-in-out"
                            color="inherit"
                            width="24px"
                            height="24px"
                        />
                    }
                    onClick={onClickAdd}
                />
            </Grid>

            <Flex flexDirection="column" flex="1" overflow="auto">
                {attributes?.map((attr) => {
                    return (
                        <Grid
                            key={attr.id}
                            alignItems="center"
                            gridTemplateColumns={templateColumns}
                            borderColor="gray.200"
                            borderBottomWidth="1px"
                            gap="8px"
                        >
                            <Flex p="4px">
                                <Input
                                    paddingInline="8px"
                                    height="28px"
                                    border="none"
                                    value={attr.key}
                                    onChange={(e) => {
                                        onUpdateKey(attr.id, e.target.value)
                                    }}
                                />
                            </Flex>

                            <Flex p="4px">
                                <Input
                                    paddingInline="8px"
                                    height="28px"
                                    border="none"
                                    value={attr.value}
                                    onChange={(e) => {
                                        onUpdateValue(attr.id, e.target.value)
                                    }}
                                />
                            </Flex>

                            <IconButton
                                minWidth="32px"
                                width="32px"
                                height="32px"
                                background="transparent"
                                color="gray.400"
                                _hover={{
                                    bgColor: "transparent",
                                    color: "gray.600",
                                }}
                                _focus={{
                                    bgColor: "transparent",
                                    outline: "none",
                                }}
                                _active={{
                                    bgColor: "transparent",
                                    outline: "none",
                                }}
                                variant="ghost"
                                aria-label="remove this key value"
                                icon={
                                    <DeleteIcon
                                        transitionProperty="color"
                                        transitionDuration="50ms"
                                        transitionTimingFunction="ease-in-out"
                                        color="inherit"
                                    />
                                }
                                onClick={() => onClickRemove(attr.id)}
                            />
                        </Grid>
                    )
                })}
            </Flex>
        </Flex>
    )
}

export default AttributePanel
