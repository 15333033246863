import CheckboxColumn from "./components/CheckboxColumn"
import GenericCell from "./components/GenericCell"
import { TableColumn } from "./model"

interface PrepareColumnsProps<T extends object> {
    columns: TableColumn<T>[]
    isExpandable?: boolean
    isSelectable?: boolean
}

const prepareColumns = <T extends object>({
    columns,
    isSelectable,
    isExpandable,
}: PrepareColumnsProps<T>): TableColumn<T>[] => {
    if (!columns?.length) return []

    let preparedCols = columns.map(({ Cell, ...col }) => ({ ...col, Cell: Cell || GenericCell } as TableColumn<T>))

    console.warn("Fix generic issue")

    // @ts-ignore
    if (isSelectable) preparedCols = [CheckboxColumn, ...preparedCols]

    if (isExpandable) {
        console.warn("ExpandableCell is not yet supported")
    }

    return preparedCols
}

export default prepareColumns
