import http from "core/http"
import { APIMasterData } from "core/http/type"
import { PutTokenPayload, Token } from "core/types"
import { TokenParams } from "core/types/token"

export type TokenResponse = {
    tokens: Token[]
    pagination: { totalItems: number }
}

const getTokens = (params: TokenParams): Promise<TokenResponse> => {
    return http.call({ url: "/v1/tokens", params })
}

const putToken = (payload: PutTokenPayload): Promise<APIMasterData<Token>> => {
    return http.call({ url: "/v1/tokens", data: payload, method: "POST" })
}

export default {
    getTokens,
    putToken,
}
