import { APIMasterData } from "core/http/type"
import { VirtualRecord, VirtualRecordPostPayload } from "core/types/virtual"

const getVirtualRecords = (
    wallet_id: number[],
    cex_account_id: string[],
    from_timestamp: number,
    to_timestamp: number
): Promise<APIMasterData<VirtualRecord[]>> => {
    return
    // return http.getWithAuth("/api/virtual", {
    //     from_timestamp,
    //     to_timestamp,
    //     wallet_id,
    //     cex_account_id,
    // })
}

const getVirtualRecordsAtTimestamp = (
    wallet_id: number[],
    cex_account_id: string[],
    at_timestamp: number
): Promise<APIMasterData<VirtualRecord[]>> => {
    return
    // return http.getWithAuth("/api/virtual", {
    //     wallet_id,
    //     cex_account_id,
    //     at_timestamp,
    // })
}

const postVirtualRecord = (payload: VirtualRecordPostPayload): Promise<APIMasterData<VirtualRecord>> => {
    return //http.postWithAuth("/api/virtual", payload)
}

const deleteVirtualRecord = (id: number): Promise<APIMasterData<{}>> => {
    return //http.deleteWithAuth("/api/virtual", { id })
}

const virtualAPI = {
    getVirtualRecords,
    postVirtualRecord,
    deleteVirtualRecord,
    getVirtualRecordsAtTimestamp,
}

export default virtualAPI
