import {
    Badge,
    Box,
    Button,
    Checkbox,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    useDisclosure,
} from "@chakra-ui/react"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"

import balanceSlice from "../slice"

const BalanceSelect = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()
    const includeVirtual = false // useSelector((state: RootState) => state.balance.settings.includeVirtual)
    const includeRegularBalances = useSelector((state: RootState) => state.balance.settings.includeRegularBalances)
    const includeLPBalances = useSelector((state: RootState) => state.balance.settings.includeLPBalances)
    const includeFarmBalancesDeposited = useSelector(
        (state: RootState) => state.balance.settings.includeFarmBalancesDeposited
    )
    const includeFarmBalancesJoined = useSelector(
        (state: RootState) => state.balance.settings.includeFarmBalancesJoined
    )

    const badgeNumber = [
        // includeVirtual,
        includeRegularBalances,
        includeLPBalances,
        includeFarmBalancesDeposited,
        // includeFarmBalancesJoined,
    ].filter(Boolean).length

    const handleClickIncludeRegularBalances = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(balanceSlice.actions.setIncludeRegularBalances(e.target.checked))
    }

    const handleClickIncludeLPBalances = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(balanceSlice.actions.setIncludeLPBalances(e.target.checked))
    }

    const handleClickIncludeFarmBalancesDeposited = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(balanceSlice.actions.setIncludeFarmBalancesDeposited(e.target.checked))
    }

    const handleClickIncludeFarmBalancesJoined = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(balanceSlice.actions.setIncludeFarmBalancesJoined(e.target.checked))
    }

    return (
        <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen} placement="bottom-start" isLazy>
            <PopoverTrigger>
                <Box flex="0 0 100px">
                    <Button variant="outline" width="100%" borderRadius="4px" size="sm" padding="0 16px">
                        Balance
                        <Badge ml="4px">{badgeNumber}</Badge>
                    </Button>
                </Box>
            </PopoverTrigger>
            <PopoverContent
                width="max-content"
                boxShadow="lg"
                _focus={{
                    outline: "none",
                }}
            >
                <PopoverBody
                    height="100%"
                    padding="8px 16px"
                    display="flex"
                    flexDirection="column"
                    rowGap="16px"
                    alignItems="space-between"
                >
                    <Checkbox
                        colorScheme="teal"
                        disabled
                        isChecked={includeVirtual}
                        onChange={() => {
                            return
                            dispatch(balanceSlice.actions.toggleIncludeVirtual())
                        }}
                    >
                        <Text as="span" fontSize="sm">
                            Virtual
                        </Text>
                    </Checkbox>

                    <Checkbox
                        colorScheme="teal"
                        isChecked={includeRegularBalances}
                        onChange={handleClickIncludeRegularBalances}
                    >
                        <Text fontSize="sm">Include regular balances (wallet/account balance)</Text>
                    </Checkbox>

                    <Checkbox colorScheme="teal" isChecked={includeLPBalances} onChange={handleClickIncludeLPBalances}>
                        <Text fontSize="sm">Include Pool balances</Text>
                    </Checkbox>

                    <Checkbox
                        colorScheme="teal"
                        isChecked={includeFarmBalancesDeposited}
                        onChange={handleClickIncludeFarmBalancesDeposited}
                    >
                        <Text fontSize="sm">Include Farm balances </Text>
                    </Checkbox>

                    {/* <Checkbox
                        colorScheme="teal"
                        isChecked={includeFarmBalancesJoined}
                        onChange={handleClickIncludeFarmBalancesJoined}
                    >
                        <Text fontSize="sm">Include Farm balances (joined)</Text>
                    </Checkbox> */}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export default BalanceSelect
