import { ForkEffect, all, call, put, select, takeLatest } from "redux-saga/effects"

import balanceAPI from "core/apis/balance"
import { RootState } from "core/redux/reducer"
import { toast } from "core/redux/toast.slice"
import { BalanceResponseV2 } from "core/types/balance"
import { getFilterBalance } from "pages/Balance/saga"

import balanceComparisonSlice from "./slice"

function* getBalancesSaga() {
    const state: RootState = yield select((state) => state)
    const {
        chainID,
        // assets: allAssets,
        timestamp1,
        timestamp2,
    } = state.balanceComparison
    const {
        filter,
        settings: { sortAssetsByUSDValue, token_min_amount_usd = 0 },
    } = state.balance

    const { wallet_id, group_id, cex_account } = yield getFilterBalance()

    let balanceResponses: BalanceResponseV2[] = []

    try {
        const params = {
            wallet_id,
            cex_account,
            group_id,
            chain_id: [chainID].map((e) => parseInt(e) + ""),
            token_min_amount_usd,
            descending: sortAssetsByUSDValue,
        }
        Object.keys(filter).forEach((key) => {
            if (filter[key] && key.startsWith("all_")) {
                // filter all
                params[key] = filter[key]
            }
        })
        const params1 = {
            ...params,
            balance_timestamp: timestamp1,
            rate_timestamp: timestamp1,
        }
        const params2 = {
            ...params,
            balance_timestamp: timestamp2,
            rate_timestamp: timestamp2,
        }
        balanceResponses = yield all([call(balanceAPI.getBalancesV2, params1), call(balanceAPI.getBalancesV2, params2)])
        yield put(balanceComparisonSlice.actions.setPrevParams([params1, params2]))
    } catch (error) {
        yield put(balanceComparisonSlice.actions.getBalanceDiffFailure())
        yield put(
            toast({
                description: `Compare: ${error?.toString()}`,
                status: "error",
            })
        )
        return
    }

    yield put(
        balanceComparisonSlice.actions.getBalanceDiffSuccess({
            balanceResponses,
        })
    )
}

export default function* balanceComparisonSaga(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(balanceComparisonSlice.actions.getBalanceDiffRequest, getBalancesSaga)
}
