export const API_V2_URL = process.env.REACT_APP_API_V2_URL

const WHITELIST_EMAILS_DEV = [
    "danh.nguyen@kyber.network",
    "matthew@kyber.network",
    "duc.le@kyber.network",
    "spyros@kyber.network",
    "tung.le@kyber.network",
    "windy@kyber.network",
    "thu@kyber.network",
]

const WHITELIST_EMAILS_STG = ["danh.nguyen@kyber.network", "matthew@kyber.network", "duc.le@kyber.network"]

const WHITELIST_EMAILS_PROD = [
    "victor@kyber.network",
    "myra@kyber.network",
    "spyros@kyber.network",
    "keewill@kyber.network",
    "matthew@kyber.network",
    "vivian@kyber.network",
]

export const WHITELIST_EMAILS = [
    ...WHITELIST_EMAILS_PROD,
    ...(process.env.REACT_APP_ENV === "development" ? WHITELIST_EMAILS_DEV : []),
    ...(process.env.REACT_APP_ENV === "staging" ? WHITELIST_EMAILS_STG : []),
]
