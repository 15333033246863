import { DeleteIcon } from "@chakra-ui/icons"
import { Box, Flex, IconButton, Text } from "@chakra-ui/react"
import styled from "@emotion/styled"
import ReactJson from "react-json-view"
import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"

import debuggerSlice from "./slice"

type Props = {
    className?: string
}
const ResponsePanel: React.FC<Props> = ({ className }) => {
    const dispatch = useDispatch()
    const response = useSelector((state: RootState) => state.debugger.resp)
    const clearResponse = () => {
        dispatch(debuggerSlice.actions.clearResponse())
    }

    return (
        <Flex
            flex="1"
            overflow="hidden"
            flexDirection="column"
            width="100%"
            className={className}
            position="relative"
            _hover={{
                ".eraseButton": {
                    display: "block",
                },
            }}
            gap="4px"
        >
            <Text as="span" fontSize="sm" flex="0">
                Response{" "}
                {!response && (
                    <Text as="span" color={"red"}>
                        Failed
                    </Text>
                )}
            </Text>
            <Box overflow="auto" flex="1" width="100%" borderWidth="1px" borderRadius="8px" borderColor="gray.300">
                <ReactJson
                    src={response}
                    name={false}
                    collapseStringsAfterLength={120}
                    indentWidth={4}
                    displayDataTypes={false}
                />
            </Box>

            <Box display="none" className="eraseButton" position="absolute" top="2rem" right="0.5rem">
                <IconButton
                    minWidth="32px"
                    width="32px"
                    height="32px"
                    colorScheme="blue"
                    variant="outline"
                    aria-label="remove this key value"
                    icon={
                        <DeleteIcon
                            transitionProperty="color"
                            transitionDuration="50ms"
                            transitionTimingFunction="ease-in-out"
                            color="inherit"
                        />
                    }
                    onClick={clearResponse}
                />
            </Box>
        </Flex>
    )
}

export default styled(ResponsePanel)`
    .react-json-view {
        width: 100%;
        height: 100%;
        border-radius: 8px;
    }

    .pretty-json-container.object-container {
        padding: 12px;
    }
`
