import { Token } from "core/types"

export type BalanceResponse = {
    cex_timestamp: number
    balances: CexAccountBalance[]
}

export type Balance = DefiWalletBalance | CexAccountBalance

export type BalanceResponseV2 = {
    balance_cex_timestamp: number
    rate_cex_timestamp: number
    total_amount_usd: number
    balances: Balance[]
}

export type AssetBalance = {
    id: number
    amount: number
    amount_usd: number
    name: string
    symbol: string
    logo_url: string
    price_usd: number
    no_price: boolean
}

export enum AccountType {
    WALLET = "wallet",
    CEX_ACCOUNT = "cex-account",
}

export type CexAccountBalance = {
    account_id: string
    account_name: string
    type: AccountType
    assets: AssetBalance[]
    total_amount_usd: number
    tokens_without_asset: Token[]
}

export type DefiWalletBalance = {
    wallet_id: number
    wallet_name: string
    wallet_address: string
    type: AccountType
    assets: AssetBalance[]
    total_amount_usd: number // Sum total amountUSD by wallet
    total_asset: number
    tokens_without_asset: Token[]
}

export type ExchangeAccount = {
    id: string
    description: string
    exchange: string
}
