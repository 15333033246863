import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"

import { CreateOrEditGroupFormValues } from "."

const useCreateGroupForm = () => {
    const getDefaultFormValues = useCallback((): CreateOrEditGroupFormValues => {
        return {
            name: "",
            note: "",
            group_ids: [],
            wallet_ids: [],
            cex_accounts: [],
        }
    }, [])

    const methods = useForm<CreateOrEditGroupFormValues>({
        defaultValues: getDefaultFormValues(),
        mode: "all",
    })
    const { reset } = methods

    useEffect(() => {
        reset(getDefaultFormValues())
    }, [getDefaultFormValues, reset])

    return methods
}

export default useCreateGroupForm
