import { Flex, FormControl, FormErrorMessage, FormLabel, Input, Select as RawSelect } from "@chakra-ui/react"
import { useMemo } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useDispatch } from "react-redux"
import Select from "react-select"
import { isAddress } from "utils/address"

import useSearchData from "core/hooks/useSugguestionData"
import commonSlice from "core/redux/common.slice"
import { SelectOption } from "core/types/select"
import { WalletType } from "core/types/wallet"

export type WalletFormValues = {
    id?: number
    address: string
    description: string
    name: string
    type: string
    groups: SelectOption[]
}

const CreateOrEditWalletForm: React.FC<{ isEditing: boolean }> = ({ isEditing }) => {
    const { groups = [] } = useSearchData()
    const groupOptions: SelectOption[] = useMemo(() => {
        return groups.map((group) => ({
            label: group.name,
            value: group.id,
        }))
    }, [groups])

    const dispatch = useDispatch()
    const onSearchGroup = (value: string) => {
        dispatch(commonSlice.actions.setSearchQuery({ groups: value }))
    }

    const { control } = useFormContext()

    return (
        <Flex direction="column" width="100%" height="100%" rowGap="24px">
            {isEditing && (
                <Controller
                    control={control}
                    name="id"
                    render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                        <FormControl isInvalid={invalid} isRequired>
                            <FormLabel fontSize="sm">Wallet ID</FormLabel>
                            <Input
                                ref={ref}
                                onBlur={onBlur}
                                name={name}
                                placeholder="Wallet ID"
                                onChange={onChange}
                                autoComplete="off"
                                value={value || ""}
                                readOnly
                                disabled
                            />
                        </FormControl>
                    )}
                />
            )}

            <Controller
                control={control}
                name="address"
                rules={{
                    required: "Address is required",
                    validate: (val: string) => (isAddress(val) ? true : "Invalid address"),
                }}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
                    <FormControl isInvalid={invalid} isRequired>
                        <FormLabel fontSize="sm">Address</FormLabel>
                        <Input
                            ref={ref}
                            onBlur={onBlur}
                            name={name}
                            placeholder="Wallet address"
                            onChange={onChange}
                            autoComplete="off"
                            value={value || ""}
                            readOnly={isEditing}
                            disabled={isEditing}
                        />
                        {invalid ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                    </FormControl>
                )}
            />

            {isEditing ? (
                <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => {
                        return (
                            <FormControl isInvalid={invalid} isRequired>
                                <FormLabel fontSize="sm">Type</FormLabel>
                                <Input
                                    ref={ref}
                                    onBlur={onBlur}
                                    name={name}
                                    placeholder="type of wallet"
                                    onChange={onChange}
                                    autoComplete="off"
                                    value={value}
                                    readOnly
                                    disabled={isEditing}
                                />
                            </FormControl>
                        )
                    }}
                />
            ) : (
                <Controller
                    control={control}
                    name="type"
                    render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => {
                        return (
                            <FormControl isInvalid={invalid} isRequired>
                                <FormLabel fontSize="sm">Type</FormLabel>
                                <RawSelect
                                    size="sm"
                                    borderRadius="md"
                                    value={value}
                                    onChange={onChange}
                                    name={name}
                                    onBlur={onBlur}
                                    ref={ref}
                                >
                                    <option value={WalletType.EVM}>EVM</option>
                                    <option value={WalletType.BTC}>BTC</option>
                                </RawSelect>
                            </FormControl>
                        )
                    }}
                />
            )}

            <Controller
                control={control}
                name="name"
                rules={{
                    required: "Name is required",
                }}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid, error } }) => (
                    <FormControl isInvalid={invalid} isRequired>
                        <FormLabel fontSize="sm">Name</FormLabel>
                        <Input
                            ref={ref}
                            onBlur={onBlur}
                            name={name}
                            placeholder="Wallet name"
                            onChange={onChange}
                            autoComplete="off"
                            value={value || ""}
                        />
                        {invalid ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="description"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => (
                    <FormControl isInvalid={invalid}>
                        <FormLabel fontSize="sm">Description</FormLabel>
                        <Input
                            ref={ref}
                            onBlur={onBlur}
                            name={name}
                            placeholder="Wallet description"
                            onChange={onChange}
                            autoComplete="off"
                            value={value || ""}
                        />
                    </FormControl>
                )}
            />

            <Controller
                control={control}
                name="groups"
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { invalid } }) => {
                    return (
                        <FormControl isInvalid={invalid}>
                            <FormLabel fontSize="sm">Groups</FormLabel>
                            <Select
                                onInputChange={onSearchGroup}
                                value={value}
                                onChange={onChange}
                                isClearable
                                isSearchable
                                isMulti
                                name={name}
                                options={groupOptions}
                                onBlur={onBlur}
                                ref={ref}
                                placeholder="Groups"
                            />
                        </FormControl>
                    )
                }}
            />
        </Flex>
    )
}

export default CreateOrEditWalletForm
