import { Chain } from "./types/chain"

const NullSymbol = Symbol()
const EmptyFunction = () => {}
const EmptyObject = {}
const EmptyArray = []

const locale = navigator.language || "en-CA"
const zone = new Date().toLocaleDateString(undefined, { day: "2-digit", timeZoneName: "long" }).substring(4)
const gmt = new Date().toLocaleTimeString(locale, { timeZoneName: "short" }).split(" ")[2]
const DEFAULT_FORMAT_DATE_STRING = "yyyy-MM-dd HH:mm:ss zzz"

const ONE_WEEK_IN_MS = 7 * 24 * 60 * 60 * 1000

const chains: Chain[] = [
    {
        id: "0x1",
        name: "Ethereum",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    },
    {
        id: "0x89",
        name: "Polygon",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    },
    {
        id: "0x38",
        name: "BSC",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
    },
    {
        id: "0xA86A",
        name: "Avalanche",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
    },
    {
        id: "0xA4B1",
        name: "Arbitrum",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png",
    },
    {
        id: "0xFA",
        name: "Fantom",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
    },
    {
        id: "0xA",
        name: "Optimism",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png",
    },
]

const allChains: Chain[] = [
    {
        id: "0x1",
        name: "Ethereum",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    },
    {
        id: "0x89",
        name: "Polygon",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    },
    {
        id: "0x38",
        name: "BSC",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
    },
    {
        id: "0xA86A",
        name: "Avalanche",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
    },
    {
        id: "0xA4B1",
        name: "Arbitrum",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png",
    },
    {
        id: "0xFA",
        name: "Fantom",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
    },
    {
        id: "0xA",
        name: "Optimism",
        logoURL: "https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png",
    },
]

export {
    NullSymbol,
    EmptyFunction,
    EmptyArray,
    EmptyObject,
    locale,
    zone,
    gmt,
    chains,
    allChains,
    ONE_WEEK_IN_MS,
    DEFAULT_FORMAT_DATE_STRING,
}
