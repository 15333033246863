import { useDispatch, useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"

import AttributePanel from "./AttributePanel"
import debuggerSlice from "./slice"

const ParamsPanel: React.FC = () => {
    const params = useSelector((state: RootState) => state.debugger.params)
    const dispatch = useDispatch()

    const onClickAdd = () => {
        dispatch(debuggerSlice.actions.addParam())
    }

    const onClickRemove = (id: string) => {
        dispatch(debuggerSlice.actions.removeParam(id))
    }

    const onUpdateKey = (id: string, key: string) => {
        dispatch(
            debuggerSlice.actions.updateParamKey({
                id,
                key,
            })
        )
    }

    const onUpdateValue = (id: string, value: string) => {
        dispatch(
            debuggerSlice.actions.updateParamValue({
                id,
                value,
            })
        )
    }

    return (
        <AttributePanel
            attributes={params}
            onClickAdd={onClickAdd}
            onClickRemove={onClickRemove}
            onUpdateKey={onUpdateKey}
            onUpdateValue={onUpdateValue}
        />
    )
}

export default ParamsPanel
