import { Badge } from "@chakra-ui/react"

interface Props {
    value: boolean
}

const NativeCell: React.FC<Props> = ({ value }) => {
    if (!value) {
        return null
    }

    return (
        <Badge colorScheme="teal" variant="outline">
            Native
        </Badge>
    )
}

export default NativeCell
