export type BalanceFilter = {
    walletIDs: number[]
    groupIDs: number[]
    cexAccountIDs: string[]
} & FilterAllWallet

export type FilterAllWallet = {
    all_group?: boolean
    all_wallet?: boolean
    all_account?: boolean
    all_evm?: boolean
    all_btc?: boolean
}

export const FilterAllDefault = {
    all_btc: false,
    all_evm: false,
    all_account: false,
    all_wallet: false,
    all_group: false,
}
