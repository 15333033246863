import { Box, Image, Text } from "@chakra-ui/react"

import { NullCell } from "components/Table/components/NullCell"
import { allChains } from "core/globalConstants"

interface IProps {
    value: string
}
export const getChainById = (chainID: string) =>
    allChains.find((chain) => chain.id === chainID || parseInt(chain.id) === parseInt(chainID))

const ChainCell: React.FC<IProps> = ({ value: chainID }) => {
    const chain = getChainById(chainID)
    if (!chain) {
        return <NullCell />
    }

    return (
        <Box d="flex" alignItems="center" whiteSpace="nowrap" columnGap="4px">
            <Box height="100%" flex="0 0 24px" d="flex" alignItems="center" justifyContent="center">
                <Image boxSize="24px" borderRadius="100%" src={chain.logoURL} alt={chain.name} />
            </Box>

            <Text as="span" fontSize="sm">
                {chain.name}
            </Text>
        </Box>
    )
}

export default ChainCell
