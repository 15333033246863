import { Box, Flex } from "@chakra-ui/react"
import React from "react"

import withPageLayout from "components/withPageLayout"

import BalanceTable from "./BalanceTable"
import UtilityBar from "./UtilityBar"

const BalanceComparisonPage: React.FC = () => {
    return (
        <Flex direction="column" height="100%" width="100%" padding="24px 160px">
            <Box flex="0 0" width="100%" d="flex" justifyContent="space-between" alignItems="center">
                <Box d="flex" flexDir="column">
                    <Box as="h1" fontSize="3xl">
                        Compare balance
                    </Box>
                    <Box as="h3" fontSize="sm">
                        Balance comparison (Regular balance)
                    </Box>
                </Box>
            </Box>

            <Box flex="0 0" width="100%" mt="24px" mb="24px">
                <UtilityBar />
            </Box>

            <Box overflow="hidden" width="100%" flex="1 1 100%">
                <BalanceTable />
            </Box>
        </Flex>
    )
}

export default withPageLayout(BalanceComparisonPage)
