import {
    Badge,
    Box,
    Button,
    Checkbox,
    Image,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Text,
    useDisclosure,
} from "@chakra-ui/react"
import { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import { EmptyArray, allChains, chains } from "core/globalConstants"
import { RootState } from "core/redux/reducer"
import { Chain } from "core/types/chain"

import balanceSlice from "../slice"

const ChainItem: React.FC<Chain> = ({ name, logoURL }) => {
    return (
        <Box d="flex" alignItems="center" whiteSpace="nowrap" columnGap="4px">
            <Box height="100%" flex="0 0 24px" d="flex" alignItems="center" justifyContent="center">
                {logoURL ? (
                    <Image boxSize="24px" src={logoURL} alt={name} borderRadius="100%" />
                ) : (
                    <Box height="24px" flex="0 0 24px" borderRadius="100%" bgColor="blackAlpha.200" />
                )}
            </Box>

            <Text as="span" fontSize="sm">
                {name}
            </Text>
        </Box>
    )
}

const ChainSelect = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()
    const chainIDs = useSelector((state: RootState) => state.balance.chainIDs)
    const { includeFarmBalancesDeposited, includeLPBalances } = useSelector(
        (state: RootState) => state.balance.settings
    )
    const selectedChainIDsRef = useRef(chainIDs)
    selectedChainIDsRef.current = chainIDs

    const notReadyChains = useMemo(() => {
        return allChains.filter((chain) => {
            // hard code chain id = 10 optimism for now:
            // If pool is selected || farm is selected, then 7 chains
            // else 6 chains
            return (
                (includeLPBalances || includeFarmBalancesDeposited ? false : parseInt(chain.id, 16) === 10) ||
                !chains.some((c) => c.id === chain.id)
            )
        })
    }, [includeFarmBalancesDeposited, includeLPBalances])

    const badgeNumber = chainIDs.filter((e) => !notReadyChains.some((el) => el.id === e)).length
    const shouldShowAllChains = useSelector((state: RootState) => {
        const {
            includeRegularBalances,
            includeVirtual,
            includeLPBalances,
            includeFarmBalancesDeposited,
            includeFarmBalancesJoined,
        } = state.balance.settings

        return (
            (includeLPBalances || includeFarmBalancesDeposited || includeFarmBalancesJoined) &&
            !includeRegularBalances &&
            !includeVirtual
        )
    })

    const listChains = shouldShowAllChains ? allChains : chains

    useEffect(() => {
        if (!shouldShowAllChains) {
            dispatch(
                balanceSlice.actions.selectChainIDs(
                    selectedChainIDsRef.current.filter((chainId) => chains.some((chain) => chain.id === chainId))
                )
            )
        }
    }, [dispatch, shouldShowAllChains])

    return (
        <Popover isOpen={isOpen} onClose={onClose} onOpen={onOpen} placement="bottom-start" isLazy>
            <PopoverTrigger>
                <Box flex="0 0 100px">
                    <Button variant="outline" width="100%" borderRadius="4px" size="sm" padding="0 16px">
                        Chain
                        <Badge ml="4px">{badgeNumber}</Badge>
                    </Button>
                </Box>
            </PopoverTrigger>
            <PopoverContent
                width="max-content"
                boxShadow="lg"
                _focus={{
                    outline: "none",
                }}
            >
                <PopoverBody
                    height="100%"
                    padding="8px 16px"
                    display="flex"
                    flexDirection="column"
                    rowGap="16px"
                    alignItems="space-between"
                >
                    <Checkbox
                        isIndeterminate={chainIDs.length !== 0 && chainIDs.length !== listChains.length}
                        isChecked={chainIDs.length === listChains.length}
                        onChange={() => {
                            if (chainIDs.length === listChains.length) {
                                dispatch(balanceSlice.actions.selectChainIDs(EmptyArray))
                            } else {
                                dispatch(balanceSlice.actions.selectChainIDs(listChains.map((chain) => chain.id)))
                            }
                        }}
                    >
                        All
                    </Checkbox>

                    <Box height="0px" width="100%" borderTop="1px" borderColor="gray.200" />

                    {chains.map((chain) => {
                        const disabled = notReadyChains.some((e) => e.id === chain.id)
                        return (
                            <Checkbox
                                key={chain.id}
                                style={{ opacity: disabled ? 0.5 : 1 }}
                                isChecked={!disabled && chainIDs.includes(chain.id)}
                                onChange={() => {
                                    if (disabled) return
                                    dispatch(balanceSlice.actions.toggleSelectChainID(chain.id))
                                }}
                                disabled={disabled}
                            >
                                <ChainItem {...chain} />
                            </Checkbox>
                        )
                    })}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    )
}

export default ChainSelect
