import React, { useCallback, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { formatGMT } from "utils/date"

import Table from "components/Table"
import { TableColumn } from "components/Table/model"
import balanceAPI from "core/apis/balance"
import { EmptyArray } from "core/globalConstants"
import useShowToast from "core/hooks/useShowToast"
import { RootState } from "core/redux/reducer"
import { Balance, BalanceResponseV2, CexAccountBalance, DefiWalletBalance } from "core/types/balance"
import ActionCell from "pages/Balance/BalanceTable/ActionCell"
import DetailAssetModal, { TabTypes } from "pages/Balance/BalanceTable/DetailAsset"
import balanceSlice from "pages/Balance/slice"

import ValueCell from "./ValueCell"

type DisplayData = Record<string, string>

export const getWalletId = (wallet: Balance) =>
    (wallet as DefiWalletBalance).wallet_id || (wallet as CexAccountBalance).account_id

const generateColumns = (
    showDetail: (v: Balance, tab?: TabTypes) => void,
    fetchingWallet: string | number
): TableColumn<DisplayData>[] => {
    const columns: TableColumn<DisplayData>[] = [
        {
            Header: "Account Name",
            accessor: (d: DisplayData) => {
                return d["wallet_name"] || d["account_name"]
            },
            sticky: "left",
            align: "center",
        },
        {
            Header: "Address/ID",
            accessor: (d: DisplayData) => {
                return d["wallet_address"] || d["account_id"]
            },
        },
        {
            Header: "Type",
            accessor: "type",
            width: 80,
            align: "center",
        },
        {
            Header: "Balance Date",
            accessor: "balance_date",
            width: 180,
        },
        {
            Header: "Rate Date",
            accessor: "rate_date",
            width: 180,
        },
        {
            Header: "Total USD",
            accessor: (d: DisplayData) => {
                return d["total_amount_usd"]
            },
            Cell: ValueCell,
            align: "end",
        },
        {
            Header: "Action",
            Cell: (d) => {
                const balance = d.row.original
                return d.row.index === d.data.length - 1 ? null : (
                    <ActionCell balance={balance} showDetail={showDetail} fetchingWallet={fetchingWallet} showWarning />
                )
            },
            align: "center",
            sticky: "right",
            width: 70,
        },
    ]

    return columns
}

const BalanceTable: React.FC = () => {
    const [showDetail, setShowDetail] = useState(false)
    const [currentAccount, setCurrentAccount] = useState<BalanceResponseV2>()

    const accountBalances = useSelector((state: RootState) => state.balance.balanceResponse?.balances)
    const total_amount_usd = useSelector((state: RootState) => state.balance.balanceResponse?.total_amount_usd)

    const returnedBalanceTimestamp = useSelector(
        (state: RootState) => state.balance.balanceResponse.balance_cex_timestamp
    )
    const returnedRateTimestamp = useSelector((state: RootState) => state.balance.balanceResponse.rate_cex_timestamp)
    const { isLoading, prevParams } = useSelector((state: RootState) => state.balance)
    const { showToastError } = useShowToast()
    const [fetchingWallet, setFetchingWallet] = useState<string | number>("")
    const currentWallet = useRef<Balance>()
    const [requestTab, setRequestTab] = useState(TabTypes.BALANCE)

    const onShowDetailAccount = useCallback(
        async (wallet: Balance, tab?: TabTypes) => {
            if (fetchingWallet) return
            if (tab !== undefined) setRequestTab(tab)
            try {
                currentWallet.current = wallet
                const walletId = getWalletId(wallet)
                setFetchingWallet(walletId)
                const accountInfo: BalanceResponseV2 = await balanceAPI.getBalanceByAccount({
                    ...prevParams,
                    id: walletId,
                    type: wallet.type,
                })
                setCurrentAccount(accountInfo)
                setShowDetail(true)
            } catch (error) {
                showToastError(error.toString())
            }
            setFetchingWallet("")
        },
        [showToastError, prevParams, fetchingWallet]
    )

    const data: DisplayData[] = useMemo(() => {
        const result = []
        accountBalances.forEach((accountBalance) => {
            result.push({
                ...accountBalance,
                balance_date: formatGMT(new Date(returnedBalanceTimestamp * 1000)),
                rate_date: formatGMT(new Date(returnedRateTimestamp * 1000)),
            })
        })

        result.push({
            wallet_name: "Total Value (USD)",
            total_amount_usd,
        })

        return result
    }, [accountBalances, returnedBalanceTimestamp, returnedRateTimestamp, total_amount_usd])

    const columns = useMemo(() => {
        return generateColumns(onShowDetailAccount, fetchingWallet)
    }, [onShowDetailAccount, fetchingWallet])

    const [pageIndex, setPageIndex] = useState(0)
    const [pageSize, setPageSize] = useState(data.length)

    const dispatch = useDispatch()
    const refreshBalance = useCallback(() => {
        onShowDetailAccount(currentWallet.current)
        dispatch(balanceSlice.actions.getBalancesAndRatesRequest())
    }, [dispatch, onShowDetailAccount])

    return (
        <>
            <Table
                allowDisplayAllRows
                pageIndex={pageIndex}
                pageSize={pageSize}
                handlePageIndex={setPageIndex}
                handlePageSize={setPageSize}
                isLoading={isLoading}
                total={data.length}
                data={data}
                columns={columns as TableColumn<{}>[]}
                paginationModes={EmptyArray}
            />
            <DetailAssetModal
                loading={!!fetchingWallet}
                isOpen={showDetail}
                onClose={() => setShowDetail(false)}
                account={currentAccount}
                refreshBalance={refreshBalance}
                defaultTab={requestTab}
            />
        </>
    )
}

export default BalanceTable
