import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import commonSlice from "core/redux/common.slice"
import { RootState } from "core/redux/reducer"
import assetSlice from "pages/Asset/slice"

export default function Updater() {
    const searchQuery = useSelector((state: RootState) => state.common.searchQuery)
    const fetchFirstTimeRef = useRef(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!fetchFirstTimeRef.current) dispatch(commonSlice.actions._initializeRequest())
        fetchFirstTimeRef.current = true
    }, [dispatch])

    useEffect(() => {
        dispatch(commonSlice.actions.searchRequest())
    }, [searchQuery, dispatch])

    useEffect(() => {
        dispatch(assetSlice.actions.getExchangesRequest())
    }, [dispatch])

    return null
}
