import { useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"

import { RootState } from "core/redux/reducer"

import { CreateOrEditAssetFormValues } from "."

const useCreateAssetForm = () => {
    const exchanges = useSelector((state: RootState) => state.asset.exchanges)
    const getDefaultFormValues = useCallback((): CreateOrEditAssetFormValues => {
        const mappings = exchanges.reduce((acc, cex) => {
            acc[cex.id] = ""
            return acc
        }, {} as Record<number, string>)
        return {
            name: "",
            symbol: "",
            is_active: true,
            cex_asset_mapping: mappings,
            tokens: [],
        }
    }, [exchanges])

    const methods = useForm<CreateOrEditAssetFormValues>({
        defaultValues: getDefaultFormValues(),
        mode: "all",
    })
    const { reset } = methods

    useEffect(() => {
        reset(getDefaultFormValues())
    }, [getDefaultFormValues, reset])

    return { ...methods, getDefaultFormValues }
}

export default useCreateAssetForm
